@import 'account-flagship_tasting_room.scss';
@import 'account-wineclub.scss';

.your-shipment .shipment-card .shipment-type{
    background: #C9B794;
    font-family: $font-alt;
    color: $color-primary;
    &.confirm{
        background-color: #f9f8f8;
        color: #737171;
    }
}
.b-tab_list-tab.m-active{
    border-bottom: 3px solid $color-secondary-accent;
}

// customize shipment page

.shipment-details .saveShipment-cta{
    background-color: $color-secondary;
    color: $color-primary;
}

//add a member cta->wineclub

.addwcmembership-cta{
    letter-spacing: 0.9px;
}

.b-account_card {
    &-spaceaddress {
		@include media(md-up) {
			width: 33%;
		}
	}
}

.b-user_greeting-message {
    font-size: 36px;
    letter-spacing: 0.8px;
    line-height: 48px;
}

.b-user_greeting-signout {
    color: $color-secondary-accent;
    font-family: $font-main;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;

}

.b-account_nav-item {
	font-family: $font-alt !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	letter-spacing: 0.6px !important;
	line-height: 24px !important;
    color: $color-account_nav-item__active-text !important;
    text-transform: capitalize;
}

.b-customer_service_info {
    &.m-small {
        .b-customer_service_info {
        &-title {
            color: $color-account_nav-item__active-text;
            font-family: $font-main;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.7px;
            line-height: 32px;
            }
        }
    }
}
