/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS, which puts the properties and values you are changing right next
to each other.

That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in another different file.

## Configuration

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1200px)',
	lg-down: 'screen and (max-width: 1440px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
@import url("https://use.typekit.net/zck8nxr.css");
/*md
@no-stat

# Rh (Indents rhythm)

Initial assumption is that Designs should follow the agreed Rhythm.

The main puprose of Rhythm function is to soothe inconsistencies of design for vertical and horizontal indents if such appear.

If we have agreed on Rhythm = 5 and we meet padding or margin 22px in design - we should round it to rh(4)=20px

Rhythm function can be avoided if some measurements are specific, i.e.:
	-icon size, blocks width/height are specific values
	-form elements paddings/margins/sizes are usually specific values
	-positioning of elements might have nothing to do with parent paddings

## Usage

```scss
.component {
	@include rh(2); // => 10px
	@include rh(2 4 0); // => 10px 20px 0
}
```
*/
/* stylelint-disable */
/* stylelint-enable */
/*md
@no-stat

# Z-indexes

This is map of z-indexes used on project. To easily work with z-indexes and avoid many issues related to this,
you need to not use z-index directly, but instead use z() function to get z-index value from this map.

## Usage

### Global components

```scss
// settings.scss
$z-indexes: (
	content: (),
	popup-menu: ()
);

// component.scss
.b-pop_up-menu {
	//...
	z-index: z(popup-menu);
}
```

### Inside a component

```scss
// settings.scss
$z-indexes: (
	components: (
		component_name: (),
		checkbox: (
			before: (),
			after: (),
			icon: (),
		)
	),
	content: (),
	popup-menu: ()
);

// component.scss
.component_name {
	position: relative;
	z-index: z(components, component_name);
}

// checkbox.scss
.checkbox {
	&-icon {
		z-index: z(components, checkbox, icon);
	}
}
```

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 56.25%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

Second parameter is for the `!important` keyword.

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
/*md
@no-stat

# Lines clamp

This mixin is useful for adding ... for text with more than 1 line

## Usage

```scss
.component {
	@include line-clamp;
}
```
*/
/*md
@no-stat

# Lines clamp with lines reserve

This mixin is useful for adding ... for text with more than 1 line and reserve space

## Usage

```scss
.component {
	@include lines-clamp-reserve;
}
```
*/
/* stylelint-disable
	property-no-vendor-prefix,
	property-no-unknown
*/
/*md
@no-stat

# Force repaint

This mixin is useful when repaint needs to be triggered. (sometimes on iOS)

## Usage

```scss
.component {
	@include force_repaint;
}
```
*/
@import url("https://use.typekit.net/zck8nxr.css");
@import url("https://use.typekit.net/zck8nxr.css");
@keyframes repaint {
  from {
    padding-bottom: 0.01px; }
  to {
    padding-bottom: 0; } }

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behaviour of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# Snap scroll

CSS snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: `.b-carousel`, `.b-product_gallery`, `.b-product_slider` etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

```scss_example
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container. It could be used not only for standalone blocks
but for page layouts also.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# Headings components

```scss_example
.b-title {
	@include t-heading_1;
}
```

*/
/*md

# Core component for all links

All core and common links styling
stores in this mixin

Be careful changing styles in this mixin!

```scss_example
@mixin t-link_6 {
	@include t-link;
}
```

*/
/*md

# Underlined link component

It extends and modifies `t-link` component
and is a core component for underlined links

```scss_example
@mixin t-link_1 {
	@include t-link_underlined;

	...
}
```
*/
@keyframes link-underline {
  0% {
    background-size: 0 1px; }
  100% {
    background-size: 100% 1px; } }

/*md

# Link type 1

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_1;
}
```
*/
/*md

# Link type 2

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component
```scss_example
.b-some_link {
	@include t-link_2;
}
```
*/
/*md

# Link type 3

Link followed with the arrow.
It extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_3;
}
```
*/
@keyframes link-arrow {
  0% {
    width: 5px; }
  100% {
    width: 50px; } }

/*md

# Link type 4

Link with an icon.
It inherits and extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_4;
}
```

Mixin accepts following parameters:
- `$icon` (icon name)
- `$icon-height` (1em by default)
- `$icon-width` (1em by default)

*/
/*md

# Link type 5

Another variation of an underlined link

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_5;
}
```
*/
/*md

# Paragraph components

```scss_example
.b-cart_empty {
	// ...

	&-paragraph {
		@include t-paragraph_1;
	}
}
```

*/
/*md

# List component

Component for styling ordered or unordered lists

List items are also styled.

```scss_example
.b-tips {
	@include t-list;
}

.b-tips {
	@include t-list(ordered);
}
```

*/
/*md

# g-promo*

Mixin for promotion.

```scss_example
.b-cart_promo {
	@include g-promo();

}
```

*/
/*md

# g-button_reset*

Mixin for reset button style.

```scss_example
.b-button {
	@include g-button_reset;
}

```

*/
/*md

# g-button_*

# Mixin for button global styles.

```scss_example
.b-button {
	@include g-button_global($_mod: default);
}
```

*/
/*md

# g-button_main*

# Mixin for main button.

```scss_example
.b-button {
	@include g-button_main($_mod: default);
}
```
*/
/*md

# g-button_alt*

# Mixin for alt button.

```scss_example
.b-button {
	@include g-button_alt;
}
```
*/
/*md

# f-checkbox

Default checkbox element

## Default

```scss_example
	.b-input_checkbox {
		@include f-checkbox($el: root, $mod: default);
	}
```
*/
/*
# Input - type date

Default input element

```scss_example
	.b-some_context_component {
		@include f-input_date($style: default);
	}
```
*/
/*md

# f-input_password

Default input element

## Default input

```scss_example
	.f-input_password {
		@include f-input_password($style: default);
	}
```
*/
/*md

# f-input_reset

Default reset input element

## Default

```scss_example
	.b-input {
		@include f-input_reset;
	}
```
*/
/*md

# f-input_text

Default input element

## Default input

```scss_example
	.f-input_text {
		@include f-input_text($style: default);
	}
```

##Alt input

```scss_example
	.f-input_text {
		@include f-input_text($_mod: alt);
	}
```
*/
/*md

# f-label

Default label element

## Default label

```scss_example
	.f-input_text-label {
		@include f-label($style: default);
	}
```
*/
/*md

# f-radio

Default radio element

## Default

```scss_example
	.f-input_radio {
		@include f-radio;
	}
```
*/
/*md

# f-select

Default label element

## Default

```scss_example
	.f-input_select {
		@include f-select;
	}
```
*/
/*md

# f-textarea

Default textarea element

## Default

```scss_example
	.f-input_textarea {
		@include f-textarea;
	}
```
*/
/*md
# Carousel component
This carousel component is based on a simple built-in scroll functionality.

All layout, sizing, positioning is also controlled in CSS or with the help of HTML attributes.

## Carousel layout options - Number of slides per view

There are two types of configuration:
- static number of slides
- static slide's width

### Static number of slides

To define number of slides per view you can use a CSS custom properties or HTML attributes

#### CSS custom properties
`--carousel-slides_number` and `--carousel-number_of_gaps_per_view`.

We need `--carousel-number_of_gaps_per_view` for accurate calculations of slide's width.

Formulas for these calculations are defined in `g-carousel` mixin.

Default values for these properies are:

```
--carousel-slides_number: 1;
--carousel-number_of_gaps_per_view: 0;
```

```scss_example
.b-custom_carousel {
	@include g-carousel;

	--carousel-slides_number: 3;
	--carousel-number_of_gaps_per_view: 2;
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-number` where you
can define viewport names which have static slides number configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure exact number of slides for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-number_of_gaps_per_view-sm` and `--carousel-slides_number-sm` for Small
- `--carousel-number_of_gaps_per_view-md` and `--carousel-slides_number-md` for Medium
- `--carousel-number_of_gaps_per_view-lg` and `--carousel-slides_number-lg` for Large
- `--carousel-number_of_gaps_per_view-xl` and `--carousel-slides_number-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-number="sm lg"
	style="
		--carousel-number_of_gaps_per_view-sm: 1; --carousel-slides_number-sm: 2;
		--carousel-number_of_gaps_per_view-lg: 3; --carousel-slides_number-lg: 3.5;
	"
>...</section>
```

### Dynamic number of slides

The opposite to the static number of slides is a dynamic number of slides.

You should use it when you don't need the exact number of slides per view
but rather slides width. Visually it will behave this way: when you stretch
viewport size number of slides will increase but the slide's width won't change.

To define slides width you can use CSS custom property or HTML attributes

#### CSS custom properties

There's just one CSS custom property you need: `--carousel-slides-width`

```scss_example
.b-custom_slider {
	--carousel-slides-width: 300px;

	@include media(sm) {
		--carousel-slides-width: 150px;
	}
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-width` where you
can define viewport names which have static slides width configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure a slides width for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-slides_width-sm` for Small
- `--carousel-slides_width-md` for Medium
- `--carousel-slides_width-lg` for Large
- `--carousel-slides_width-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-width="md xl"
	style="
		--carousel-slides_width-md: 250px;
		--carousel-slides_width-xl: 350px;
	"
>...</section>
```

## Carousel layout options - Gutter value between slides

Gap between slides is controlled via CSS custom
property: `--carousel-gutter`

It has the default value: `--carousel-gutter: 0`

## Carousel layout options - Number of rows

Number of rows is controlled via CSS custom
property: `--carousel-rows_number`

It has the default value: `--carousel-rows_number: 1`

*/
/*md

# g-separator

Separator

```scss_example
	b-form {
		@include g-separator;

		// ...
	}
}
```
*/
/*md

# g-accordion

Accordion styles that used in several component.

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss_example
.g-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(item);
	}

	&-title {
		@include g-accordion(control);
	}

	&-content {
		@include g-accordion(content);

		&[aria-hidden='false'] {
			@include g-accordion(content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(content_inner);
	}
}
```

*/
/*md

# g-message*

Mixin for message tile.

```scss_example
.b-message {
	@include g-message;
}
```

*/
.l-account {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1308px;
  margin-bottom: 110px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .l-account {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .l-account {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .l-account {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .l-account {
      margin-bottom: 50px; } }
  @media screen and (min-width: 1200px) {
    .l-account-content {
      display: grid;
      gap: var(--grid-gutter);
      grid-template-columns: repeat(10, 1fr); } }
  .l-account-aside {
    margin: 0 0 30px; }
    @media screen and (min-width: 1200px) {
      .l-account-aside {
        grid-area: 1 / 1 / 2 / 4;
        margin: 0 40px 0 0; } }
  @media screen and (min-width: 1200px) {
    .l-account-main {
      grid-area: 1 / 4 / 2 / -1; } }
  .l-account-form {
    margin: 0 auto;
    max-width: 450px;
    width: 70%; }
    @media screen and (max-width: 767px) {
      .l-account-form {
        width: 100%; } }
    .l-account-form .b-login_helpers {
      margin: 25px 0 35px 0; }
    .l-account-form .b-social_login {
      margin: 60px 0 30px 0; }
      @media screen and (max-width: 767px) {
        .l-account-form .b-social_login {
          margin: 50px 0 0 0; } }
    .l-account-form .b-tab_list {
      margin-bottom: 25px; }
    .l-account-form .b-account_benefits {
      margin-bottom: 30px; }
  .l-account-breadcrumbs {
    margin: 20px 0 30px 0; }
    @media screen and (min-width: 1200px) {
      .l-account-breadcrumbs {
        margin: 35px 0; } }
  .l-account.m-top_margin {
    margin-top: 50px; }
  .l-account .b-back_button {
    margin-top: 20px; }
  .l-account .b-account_title {
    margin-bottom: 45px; }
    @media screen and (max-width: 767px) {
      .l-account .b-account_title {
        margin-bottom: 30px; } }
  .l-account .b-payment_option {
    margin-bottom: 25px; }
  .l-account .b-account_card {
    margin-bottom: 30px; }
  .l-account .b-account_nav {
    margin-bottom: 55px; }
  .l-account .b-user_greeting {
    margin-bottom: 35px; }
  .l-account-navigation_cta {
    border: 2px solid #202025;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    justify-content: flex-start;
    line-height: 50px;
    padding: 0;
    text-transform: uppercase;
    width: 100%; }
    @media screen and (min-width: 1200px) {
      .l-account-navigation_cta {
        display: none; } }
    .l-account-navigation_cta svg {
      border-right: 2px solid #202025;
      box-sizing: content-box;
      height: 100%;
      padding: 0 15px; }
  .l-account-navigation_cta_text {
    margin: 0 auto; }
  .l-account-info_msg {
    font-size: 16px;
    margin-bottom: 25px; }
    @media screen and (max-width: 767px) {
      .l-account-info_msg {
        text-align: center; } }
  .l-account .b-address_selector,
  .l-account .b-address_selector-back {
    margin-bottom: 20px; }
  .l-account nav.b-account_nav.fav-height {
    margin-bottom: 25px !important; }

.b-account_title {
  font: 600 40px/56px "Proxima Nova", sans-serif;
  letter-spacing: 0.6px; }
  @media screen and (max-width: 1199px) {
    .b-account_title {
      font-size: 40px;
      letter-spacing: 0.5px;
      line-height: 48px; } }
  @media screen and (max-width: 767px) {
    .b-account_title {
      text-align: center; } }
  .b-account_title.m-center {
    text-align: center; }

.b-reset_password_success {
  text-align: center; }
  .b-reset_password_success-title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0 30px; }
  .b-reset_password_success-copy {
    margin: 10px 0 30px; }

.b-code_field-caption {
  margin: 10px 0 30px; }

.b-code_field-link {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: normal;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 0; }
  .b-code_field-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-code_field-link:hover {
    text-decoration: none; }
  .b-code_field-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .b-code_field-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-code_field-link:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-code_field-link:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-code_field-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }

.b-code_field .f-field {
  margin-bottom: 0; }

/*md

# Global messages

## Info message

```html_example
<div class="b-message">
	Your wish list is only available on this device and expire after 7 days.
	Sign in or Create account to keep your items always at hand.
</div>
```

## Promo message

```html_example
<div class="b-message m-promo">
	Today only! Buy 6 bottles and get 20% OFF
</div>
```

## Error message

```html_example
<div class="b-message m-error">
	Oops! It looks like some of the items below are no longer available.
	Please update your shopping cart to continue
</div>
```

*/
.b-message {
  align-items: center;
  background: #fff;
  border: 2px solid #D2D2CE;
  border-radius: 3px;
  color: #202025;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  line-height: 24px;
  padding: 15px 35px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .b-message {
      padding: 20px; } }
  .b-message.m-promo {
    background: rgba(246, 246, 245, 0.2);
    border-color: #F6F6F5;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase; }
  .b-message.m-error {
    background: #FAEDED;
    border: none;
    color: #CA1E36; }
  .b-message-accent_text {
    font-weight: 700; }
  .b-message-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: normal;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-message-link:hover {
      text-decoration: none; }
    .b-message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-message-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-message-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

/*md

# b-option_switch

This component allows user to choose one option for ex. shipping method, saved address etc.

```html_example
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-001" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="001" data-value="001" data-ref="field" data-event-change="update">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-001">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">Ground</span>
				Free
			</div>
			<span class="b-option_switch-description">
				7-10 Business Days
			</span>
		</label>
	</div>
</div>
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-002" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="002" data-value="002" data-ref="field" data-event-change="update" checked="">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-002">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">2-Day Express</span>
				$9.99
			</div>
			<span class="b-option_switch-description">
				2 Business Days
			</span>
		</label>
	</div>
</div>
```

*/
.b-option_switch {
  padding: 25px;
  user-select: none; }
  .b-option_switch.m-disabled {
    background-color: #252525;
    pointer-events: none; }
  .b-option_switch ~ .b-option_switch {
    margin-top: 10px; }
  .b-option_switch-inner {
    display: flex;
    position: relative; }
  .b-option_switch-label {
    cursor: pointer;
    display: block;
    width: 100%; }
    .b-option_switch-label::before {
      border: 1px solid #D2D2CE;
      bottom: -25px;
      content: '';
      left: -25px;
      position: absolute;
      right: -25px;
      top: -25px; }
      .b-option_switch-input:checked ~ .b-option_switch-label::before {
        border-color: #202025; }
  .b-option_switch-label_surface {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
  .b-option_switch-edit_button {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    align-items: center;
    display: inline-flex;
    letter-spacing: 2.1px;
    text-decoration: none;
    text-transform: uppercase;
    position: relative; }
    .b-option_switch-edit_button:hover {
      text-decoration: none; }
    .b-option_switch-edit_button:visited {
      color: var(--color-link-visited, #584C44); }
    .b-option_switch-edit_button::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 1em;
      mask: url("./svg-icons/sprite.svg#edit");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 1em;
      display: inline-block;
      margin-right: 5px; }
    .b-option_switch-edit_button svg {
      display: inline-block;
      height: var(--link-icon_height, 8px);
      margin-right: 5px;
      width: var(--link-icon_width, 8px); }
  .b-option_switch-label_edit {
    margin-left: 30px; }
  .b-option_switch-input {
    border: 0 ;
    clip: rect(1px, 1px, 1px, 1px) ;
    margin: 0 ;
    max-height: 1px ;
    max-width: 1px ;
    overflow: hidden ;
    padding: 0 ;
    position: absolute ; }
  .b-option_switch-icon {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    background: var(--icon-color, #BA8D36);
    display: block;
    height: 20px;
    mask: url("./svg-icons/sprite.svg#radio-unchecked"), url("./svg-icons/sprite.svg#radio-checked");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%, 0;
    width: 20px;
    background: #202025;
    cursor: pointer;
    height: 20px;
    margin-right: 10px;
    width: 20px; }
    .b-option_switch-icon::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .b-option_switch-icon:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
  .b-option_switch-input:checked + .b-option_switch-icon {
    mask-size: 0, 100%; }
  .b-option_switch-name {
    color: #A7A8A8;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase; }
  .b-option_switch-input:checked ~ .b-option_switch-label .b-option_switch-name {
    color: inherit; }
  .b-option_switch-description {
    display: block;
    margin-top: 10px; }
  .b-option_switch-promotion {
    display: inline-block;
    margin-top: 10px; }
  .b-option_switch .b-product_promo,
  .b-option_switch .b-product_price {
    font-size: inherit; }

.b-address_selector {
  margin: 0; }
  .b-address_selector-actions {
    display: flex;
    justify-content: space-between;
    margin: 20px 0; }
  .b-address_selector-button {
    cursor: pointer;
    font-size: 16px; }
    .b-address_selector-button::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 12px;
      mask: url("./svg-icons/sprite.svg#plus");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 12px;
      display: inline-block;
      margin-right: 5px; }

.b-login_helpers {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .b-login_helpers-forgot_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    font-size: 16px; }
    .b-login_helpers-forgot_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-login_helpers-forgot_link:hover {
      text-decoration: none; }
    .b-login_helpers-forgot_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-login_helpers-forgot_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-login_helpers-forgot_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-login_helpers-forgot_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-login_helpers-forgot_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-login_helpers .f-field {
    margin-bottom: 0; }

.b-cards_grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 767px) {
    .b-cards_grid {
      grid-template-columns: 1fr; } }

.b-user_greeting {
  align-items: center;
  display: flex; }
  .b-user_greeting-message {
    color: #202025;
    font: normal 32px/40px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  .b-user_greeting-signout {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
    transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s; }
    .b-user_greeting-signout:visited {
      color: var(--color-link-visited, #584C44); }
    .b-user_greeting-signout:hover {
      text-decoration: none; }
    .b-user_greeting-signout.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-user_greeting-signout:visited {
      color: var(--color-link-visited, #202025); }
    .b-user_greeting-signout:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-user_greeting-signout:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-user_greeting-signout.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-user_greeting-signout:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-user_greeting-signout:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }

.b-account_nav-item {
  border-top: 1px solid #D2D2CE;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2.2px;
  padding: 0;
  text-transform: uppercase;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s; }
  .b-account_nav-item:last-child {
    border-bottom: 1px solid #D2D2CE; }
  .b-account_nav-item.m-active {
    background: #F6F6F5;
    color: #584C44;
    font-weight: 700; }

.b-account_nav-item_link {
  display: block;
  padding: 25px 20px;
  position: relative;
  text-align: left; }
  .b-account_nav-item_link::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 11px;
    mask: url("./svg-icons/sprite.svg#arrow-right-thin");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em;
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
  .b-account_nav-item_link:hover {
    text-decoration: none; }
  .b-account_nav-item_link:hover::after {
    opacity: 1; }

.b-account_nav-item.m-club .b-account_nav-item_text::after {
  background: var(--icon-color, #BA8D36);
  content: '';
  display: block;
  height: 2em;
  mask: url("./svg-icons/sprite.svg#grape");
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 1em;
  color: #bfb9a1;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle; }

.b-account_nav-item.m-active .b-account_nav-item_link::after {
  opacity: 1; }

.b-navigation_panel {
  padding: 0; }
  @media screen and (min-width: 1200px) {
    .b-navigation_panel {
      display: block;
      position: static;
      visibility: visible;
      z-index: initial; } }
  @media screen and (max-width: 1199px) {
    .b-navigation_panel.m-opened {
      overflow-y: hidden; } }
  @media screen and (min-width: 1200px) {
    .b-navigation_panel::after {
      display: none; } }
  @media screen and (max-width: 1199px) {
    .b-navigation_panel-inner {
      background-color: #fff;
      bottom: 0;
      height: 100%;
      left: 0;
      max-width: 375px;
      overflow-y: auto;
      padding: 30px 20px 70px;
      position: fixed;
      top: 0;
      transform: translateX(-100%);
      transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
      transition-property: visibility, transform;
      visibility: hidden;
      width: 100%;
      z-index: 11; }
      .b-navigation_panel-inner.m-active {
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
        transform: translateX(0);
        visibility: visible; } }
  .b-navigation_panel-title {
    font: 400 28px/36px "Proxima Nova", sans-serif;
    letter-spacing: 0.35px;
    display: block;
    margin-bottom: 40px; }
    @media screen and (max-width: 1199px) {
      .b-navigation_panel-title {
        font-size: 24px;
        letter-spacing: 0.3px; } }
    @media screen and (max-width: 1199px) {
      .b-navigation_panel-title {
        display: none; } }
  @media screen and (min-width: 1200px) {
    .b-navigation_panel .b-dialog-header {
      display: none; } }

.b-back_button {
  cursor: pointer;
  display: block;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase; }
  .b-back_button::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 12px;
    mask: url("./svg-icons/sprite.svg#line-arrow");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 51px;
    display: inline-block;
    margin-right: 5px;
    transform: rotate(180deg);
    vertical-align: middle; }

.b-load_more {
  text-align: center; }
  .b-load_more-button {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0;
    padding: 0 30px;
    width: 100%; }
    .b-load_more-button:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-load_more-button:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-load_more-button:disabled:not(.m-processing), .b-load_more-button.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-load_more-button:disabled:not(.m-processing), .b-load_more-button.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-load_more-button.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-load_more-button.m-width_s {
      width: var(--button-s); }
    .b-load_more-button.m-width_m {
      width: var(--button-m); }
    .b-load_more-button.m-width_l {
      width: var(--button-l); }
    .b-load_more-button.m-processing {
      cursor: wait; }

/*md

# Load progress

```html_example
<div class="b-load_progress">
	<span class="b-load_progress-description">
		You've viewed 8 of 16 products
	</span>
	<progress class="b-load_progress-indicator" max="100" value="50"></progress>
</div>
```

*/
.b-load_progress {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* WEBKIT */
  /* MOZILLA FIREFOX */ }
  .b-load_progress-description {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
    text-transform: none; }
  .b-load_progress-indicator {
    appearance: none;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    color: #BA8D36;
    height: 5px;
    width: 100%; }
  .b-load_progress-indicator::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 2px; }
  .b-load_progress-indicator::-webkit-progress-value {
    background-color: #BA8D36;
    border-radius: 2px; }
  .b-load_progress-indicator::-moz-progress-bar {
    background-color: #fff;
    border-radius: 2px; }
  .b-load_progress-indicator::-ms-fill {
    background-color: #BA8D36;
    border-radius: 2px; }

.b-payment_option {
  --payment_option-side-offset: 25px; }
  @media screen and (max-width: 767px) {
    .b-payment_option {
      --payment_option-side-offset: 15px; } }
  .b-payment_option-item:not(.m-zero_payment) {
    border: 1px solid #D2D2CE;
    margin-bottom: 25px; }
  .b-payment_option-title {
    border-bottom: 1px solid #D2D2CE;
    padding-bottom: 30px;
    padding: 15px var(--payment_option-side-offset); }
  .b-payment_option-head {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }
  .b-payment_option-label {
    font-weight: bold;
    text-transform: uppercase; }
  .b-payment_option-img {
    margin: 5px 0; }
  .b-payment_option-content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    transition-property: height, opacity;
    visibility: hidden; }
    .b-payment_option-content.m-opened {
      height: auto;
      opacity: 1;
      visibility: visible;
      height: auto !important; }
  .b-payment_option .apple-pay-container {
    margin: 30px; }
  .b-payment_option .apple-pay-message {
    margin-bottom: 20px; }
  .b-payment_option-content_inner.m-zero_payment {
    align-items: center;
    background: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 3px;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    padding: 15px 35px;
    text-align: center;
    background: rgba(246, 246, 245, 0.2);
    border-color: #F6F6F5;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      .b-payment_option-content_inner.m-zero_payment {
        padding: 20px; } }
  .b-payment_option .b-form-set,
  .b-payment_option .b-payment_options_group {
    margin: 30px var(--payment_option-side-offset) 10px; }
  .b-payment_option .b-form-line_exp_cvv {
    display: grid;
    gap: 0 15px;
    grid-template-columns: 1fr 1fr; }
    .b-payment_option .b-form-line_exp_cvv .f-field {
      margin-right: 0; }
    .b-payment_option .b-form-line_exp_cvv .f-field-caption {
      grid-column: 1/-1;
      margin: -20px 0 var(--payment_option-side-offset); }
    .b-payment_option .b-form-line_exp_cvv .f-input_password-toggle_visibility {
      display: none; }
  @media screen and (max-width: 767px) {
    .b-payment_option-head .b-payment_methods {
      margin-top: 10px;
      width: 100%; } }
  .b-payment_option .b-payment_icon {
    height: 20px;
    width: 28px; }
  .b-payment_option .b-form-back {
    margin-bottom: 25px; }
  .b-payment_option .b-message {
    margin-bottom: 20px; }

.b-expired_badge {
  background-color: #D2D2CE;
  border-radius: 2px;
  color: #CA1E36;
  font-size: 10px;
  font-weight: bold;
  height: 22px;
  line-height: 14px;
  padding: 4px 5px; }

.b-payment_methods {
  column-gap: 7px;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column; }
  .b-payment_methods .b-payment_icon {
    display: block; }

.b-social_login-header {
  font-size: 16px;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .b-social_login-header {
      margin-bottom: 25px; } }

.b-social_login-btns {
  display: flex; }

.b-social_login-btn {
  box-shadow: none;
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: box-shadow; }
  @media screen and (max-width: 767px) {
    .b-social_login-btn {
      padding: 15px 10px; } }
  .b-social_login-btn:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 5px rgba(0, 0, 0, 0.11); }
  .b-social_login-btn svg {
    margin-right: 10px; }

.b-social_login-btn.m-facebook {
  background-color: #4267b2;
  color: #fff;
  margin-right: 15px; }

.b-social_login-btn.m-google {
  background: #fff;
  border: 1px solid #202025; }

.b-social_login-text {
  color: #181818; }

.b-account_benefits-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px; }

.b-account_benefits-list {
  list-style: disc inside;
  margin-left: 10px; }
  .b-account_benefits-list li {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px; }

.b-account_benefits.m-with_icons .b-account_benefits-title {
  font: 700 22px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.3px; }

.b-account_benefits.m-with_icons .b-account_benefits-list {
  list-style-type: none;
  margin-left: 0; }

.b-account_benefits.m-with_icons .b-account_benefits-item {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative; }
  .b-account_benefits.m-with_icons .b-account_benefits-item::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 13px;
    mask: url("./svg-icons/sprite.svg#checkmark");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 13px;
    left: 0;
    position: absolute;
    top: 5px; }

.b-account_card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px; }
  .b-account_card-header {
    margin-bottom: 15px; }
  .b-account_card-title {
    font: 400 28px/36px "Proxima Nova", sans-serif;
    letter-spacing: 0.35px;
    border-bottom: 1px solid #D2D2CE;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 500;
    letter-spacing: 0.35px;
    padding-bottom: 20px;
    text-align: left; }
    @media screen and (max-width: 1199px) {
      .b-account_card-title {
        font-size: 24px;
        letter-spacing: 0.3px; } }
  .b-account_card-title.m-club::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 1em;
    mask: url("./svg-icons/sprite.svg#grape");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em;
    color: #bfb9a1;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle; }
  .b-account_card-body {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px;
    margin: 10px 0 30px; }
    .b-account_card-body.m-delete_card {
      display: flex;
      justify-content: space-between; }
    .b-account_card-body .b-payment_methods {
      margin-left: 10px; }
  .b-account_card-button {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0;
    width: 100%;
    margin-bottom: 25px; }
    .b-account_card-button:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-account_card-button:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-account_card-button:disabled:not(.m-processing), .b-account_card-button.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-account_card-button:disabled:not(.m-processing), .b-account_card-button.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-account_card-button.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-account_card-button.m-width_s {
      width: var(--button-s); }
    .b-account_card-button.m-width_m {
      width: var(--button-m); }
    .b-account_card-button.m-width_l {
      width: var(--button-l); }
    .b-account_card-button.m-processing {
      cursor: wait; }
    @media screen and (min-width: 768px) {
      .b-account_card-button {
        margin-bottom: 0;
        margin-right: 25px;
        width: 315px; } }
    .b-account_card-button:last-child {
      margin-bottom: 0;
      margin-right: 0; }
  .b-account_card-list_item {
    margin-bottom: 10px; }
  .b-account_card-list_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-account_card-list_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_card-list_link:hover {
      text-decoration: none; }
    .b-account_card-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_card-list_link:visited {
      color: var(--color-link-visited, #202025); }
    .b-account_card-list_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_card-list_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_card-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-account_card-list_link.m-uppercase {
    text-transform: uppercase; }
  .b-account_card-message {
    font-weight: 300;
    margin-bottom: 5px; }
  .b-account_card-thumbnails {
    display: flex;
    margin-top: 25px; }
  .b-account_card-thumbnails_item {
    margin-right: 10px;
    max-width: 100%;
    width: 115px; }
    @media screen and (max-width: 767px) {
      .b-account_card-thumbnails_item {
        max-width: 62px;
        width: 16.67%; } }
    .b-account_card-thumbnails_item:last-child {
      margin-right: 0; }
    .b-account_card-thumbnails_item.m-additional {
      align-items: center;
      border: 2px solid #202025;
      border-radius: 50%;
      color: #202025;
      display: flex;
      flex-shrink: 0;
      font-size: 16px;
      font-weight: 700;
      height: 48px;
      justify-content: center;
      margin: auto 15px;
      transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
      transition-property: background-color, color;
      width: 48px; }
      .b-account_card-thumbnails_item.m-additional:hover {
        background-color: #202025;
        color: #fff;
        text-decoration: none; }
  .b-account_card-thumbnails_picture {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
  .b-account_card-thumbnails_img {
    bottom: 0;
    color: #252525;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%; }
  .b-account_card-footer {
    align-items: center;
    display: flex; }
    @media screen and (max-width: 767px) {
      .b-account_card-footer {
        flex-direction: column; } }
  .b-account_card-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 700;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    align-self: center;
    font-size: 16px; }
    .b-account_card-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_card-link:hover {
      text-decoration: none; }
    .b-account_card-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_card-link:visited {
      color: var(--color-link-visited, #202025); }
    .b-account_card-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_card-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_card-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-track_order {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 18px;
  letter-spacing: 0.2px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-track_order {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-track_order {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-track_order {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-track_order-inner {
    margin: 30px auto 50px auto;
    max-width: 680px; }
    @media screen and (min-width: 1200px) {
      .b-track_order-inner {
        margin: 90px auto 120px auto; } }
  .b-track_order-form {
    margin: 0 auto;
    max-width: 450px; }
    .b-track_order-form .b-form-btn_main {
      margin-top: 10px; }
  .b-track_order-note {
    text-align: center; }
  .b-track_order-sign_in {
    align-items: center;
    display: inline-flex;
    margin: 30px 0; }
  .b-track_order-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px; }
    .b-track_order-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-track_order-link:hover {
      text-decoration: none; }
    .b-track_order-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-track_order-link:visited {
      color: var(--color-link-visited, #202025); }
    .b-track_order-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-track_order-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-track_order-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-track_order-link:hover:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-track_order-link:hover:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-track_order .b-account_title {
    margin-bottom: 30px; }

.b-track_notification {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1308px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-track_notification {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-track_notification {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-track_notification {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-track_notification-inner {
    align-items: center;
    background: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 3px;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    padding: 15px 35px;
    text-align: center;
    background: rgba(246, 246, 245, 0.2);
    border-color: #F6F6F5;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin: 60px auto 30px; }
    @media screen and (max-width: 767px) {
      .b-track_notification-inner {
        padding: 20px; } }
    @media screen and (max-width: 767px) {
      .b-track_notification-inner {
        flex-direction: column;
        margin: 30px 0 20px;
        padding: 15px 20px; } }
  .b-track_notification-title {
    margin-right: 20px;
    padding-right: 20px;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap; }
    @media screen and (max-width: 767px) {
      .b-track_notification-title {
        margin: 5px 0 10px 0;
        padding-right: 0; } }
    .b-track_notification-title::after {
      background: #D2D2CE;
      content: '';
      height: 12px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px; }
      @media screen and (max-width: 767px) {
        .b-track_notification-title::after {
          display: none; } }
  .b-track_notification-message {
    font-weight: 400;
    letter-spacing: 0.2px;
    text-transform: none; }
  .b-track_notification-image {
    display: inline-block;
    padding: 0 10px; }
  .b-track_notification-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 700;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-track_notification-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-track_notification-link:hover {
      text-decoration: none; }
    .b-track_notification-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-track_notification-link:visited {
      color: var(--color-link-visited, #202025); }
    .b-track_notification-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-track_notification-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-track_notification-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-account_info_tile {
  border: 1px solid #D2D2CE;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
  min-height: 190px;
  padding: 25px; }
  @media screen and (max-width: 767px) {
    .b-account_info_tile {
      min-height: 145px; } }
  .b-account_info_tile-add_new {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    margin-top: 20px;
    text-transform: uppercase; }
    .b-account_info_tile-add_new:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-add_new:hover {
      text-decoration: none; }
    .b-account_info_tile-add_new.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-add_new:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-add_new:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_info_tile-add_new:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_info_tile-add_new.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    @media screen and (max-width: 767px) {
      .b-account_info_tile-add_new {
        margin-left: 20px;
        margin-top: 0; } }
  .b-account_info_tile-body {
    margin-bottom: 30px; }
  .b-account_info_tile-footer {
    align-items: baseline;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    order: 1; }
  .b-account_info_tile-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: auto; }
    .b-account_info_tile-title::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 20px;
      mask: url("./svg-icons/sprite.svg#checkmark");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 20px;
      display: inline-block;
      margin-right: 2px;
      vertical-align: text-top; }
  .b-account_info_tile-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    cursor: pointer;
    font-weight: 600; }
    .b-account_info_tile-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-link:hover {
      text-decoration: none; }
    .b-account_info_tile-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_info_tile-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_info_tile-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-link:visited {
      color: var(--color-link, inherit);
      cursor: pointer;
      display: inline;
      font-weight: 600;
      letter-spacing: 0.16px;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 90%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      padding-bottom: 7px; }
      .b-account_info_tile-link:visited:visited {
        color: var(--color-link-visited, #584C44); }
      .b-account_info_tile-link:visited:hover {
        text-decoration: none; }
      .b-account_info_tile-link:visited.m-disabled {
        color: var(--color-link-disabled, #D2D2CE);
        pointer-events: none; }
  .b-account_info_tile-link.m-edit {
    margin-right: 10px; }
  .b-account_info_tile-link.m-set_default {
    margin-right: auto; }
  .b-account_info_tile-action_text {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    font-size: 16px;
    margin-top: 15px; }
    .b-account_info_tile-action_text:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-action_text:hover {
      text-decoration: none; }
    .b-account_info_tile-action_text.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-action_text:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-action_text:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_info_tile-action_text:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_info_tile-action_text.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-account_info_tile-info {
    font-size: 16px;
    line-height: 24px; }
  .b-account_info_tile-info_row {
    align-items: center;
    display: flex; }
  .b-account_info_tile.m-ghost {
    align-items: center;
    background: linear-gradient(#fff, #fff) padding-box, repeating-linear-gradient(-45deg, #D2D2CE 0, #D2D2CE 25%, #fff 0, #fff 50%) 0 / 20px 20px;
    border: 1px solid transparent;
    color: #202025;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: background cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s; }
    .b-account_info_tile.m-ghost:hover {
      background: linear-gradient(#fff, #fff) padding-box, repeating-linear-gradient(-45deg, #F6F6F5 0, #F6F6F5 25%, #fff 0, #fff 50%) 0 / 20px 20px;
      text-decoration: none; }
  .b-account_info_tile.m-default {
    background: #F6F6F5;
    border: none; }
  .b-account_info_tile .b-payment_icon {
    margin: 0 5px 0 15px; }

.b-order_short_summary {
  display: flex; }
  @media screen and (max-width: 767px) {
    .b-order_short_summary {
      display: block; } }
  .b-order_short_summary-status {
    font-weight: 700; }
  .b-order_short_summary-list {
    flex: 1; }
  .b-order_short_summary-list + .b-order_short_summary-list {
    margin-left: 10px; }
    @media screen and (max-width: 767px) {
      .b-order_short_summary-list + .b-order_short_summary-list {
        margin: 10px 0 0 0; } }
  .b-order_short_summary-list_item {
    margin-bottom: 10px; }
  .b-order_short_summary-list_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-order_short_summary-list_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-order_short_summary-list_link:hover {
      text-decoration: none; }
    .b-order_short_summary-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-order_short_summary-list_link:visited {
      color: var(--color-link-visited, #202025); }
    .b-order_short_summary-list_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-order_short_summary-list_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-order_short_summary-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-order_info_card-header {
  border-bottom: 1px solid #D2D2CE;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 20px; }

.b-order_info_card-title {
  font: 400 28px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.35px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 500;
  letter-spacing: 0.35px; }
  @media screen and (max-width: 1199px) {
    .b-order_info_card-title {
      font-size: 24px;
      letter-spacing: 0.3px; } }

.b-order_history-find_order {
  margin-bottom: 40px; }

.b-order_history-filter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .b-order_history-filter {
      flex-wrap: wrap; } }

.b-order_history-total {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.1px;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .b-order_history-total {
      margin-bottom: 30px;
      text-align: center;
      width: 100%; } }

.b-order_history-recent {
  border-bottom: solid 1px;
  line-height: 32px;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600; }

@media screen and (max-width: 767px) {
  .b-order_history-main {
    margin-top: 30px; } }

.b-order_history-select_wrap {
  align-items: center;
  display: flex; }
  .b-order_history-select_wrap .f-input_select {
    position: relative; }
    .b-order_history-select_wrap .f-input_select::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 8px;
      mask: url("./svg-icons/sprite.svg#arrow-down");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 15px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%); }
    .b-order_history-select_wrap .f-input_select-field {
      appearance: none;
      background: transparent;
      border: none;
      border-radius: 0;
      min-width: 0;
      vertical-align: baseline;
      width: 100%;
      border: 1px solid #A7A8A8;
      border-radius: 0;
      color: #202025;
      font: 700 16px/22px "Proxima Nova", sans-serif;
      height: 46px;
      letter-spacing: 2.1px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      width: 100%;
      border-color: #A7A8A8;
      border-width: 0 0 1px;
      padding: 12px 15px 12px 0; }
      .b-order_history-select_wrap .f-input_select-field::placeholder {
        color: transparent;
        text-transform: capitalize; }
      .b-order_history-select_wrap .f-input_select-field:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fff inset;
        -webkit-text-fill-color: inherit;
        transition-delay: 9999s;
        transition-property: all; }
      @media screen and (max-width: 767px) {
        .b-order_history-select_wrap .f-input_select-field {
          height: 56px; } }
      .b-order_history-select_wrap .f-input_select-field > option {
        background: #fff;
        color: #202025; }
    .b-order_history-select_wrap .f-input_select-field:disabled {
      border-color: #D2D2CE;
      color: #D2D2CE; }
      .b-order_history-select_wrap .f-input_select-field:disabled::before {
        color: #D2D2CE; }
  @media screen and (max-width: 767px) {
    .b-order_history-select_wrap {
      display: block;
      flex: 1; } }
  .b-order_history-select_wrap .f-input_select {
    flex-basis: 100%;
    min-width: 220px; }

.b-order_history-select_label {
  color: #202025;
  font-size: 16px;
  margin-right: 15px; }
  @media screen and (max-width: 767px) {
    .b-order_history-select_label {
      display: none; } }

.b-order_history-list {
  margin-bottom: 70px; }
  @media screen and (max-width: 767px) {
    .b-order_history-list {
      margin-bottom: 40px; } }

.b-order_history-footer {
  margin: 30px auto;
  max-width: 400px; }
  @media screen and (max-width: 767px) {
    .b-order_history-footer {
      margin-top: 50px; } }
  .b-order_history-footer .b-load_progress {
    margin-bottom: 20px; }

.b-order_history .b-account_card {
  border-bottom: 1px solid #D2D2CE;
  padding-bottom: 60px; }
  @media screen and (max-width: 767px) {
    .b-order_history .b-account_card {
      margin-bottom: 50px;
      padding-bottom: 50px; } }

.b-order_details {
  display: grid;
  grid-column-gap: 30px;
  grid-template: 'title' 'short-details' 'aside' 'product-summary ' / 1fr; }
  .b-order_details-top {
    grid-area: short-details; }
    .b-order_details-top .b-account_card {
      margin-bottom: 0;
      padding-bottom: 0; }
  .b-order_details-caption {
    font: 600 40px/56px "Proxima Nova", sans-serif;
    letter-spacing: 0.6px;
    grid-area: title;
    padding-bottom: 45px; }
    @media screen and (max-width: 1199px) {
      .b-order_details-caption {
        font-size: 40px;
        letter-spacing: 0.5px;
        line-height: 48px; } }
  .b-order_details-main {
    grid-area: product-summary;
    margin-bottom: 70px; }
    @media screen and (max-width: 767px) {
      .b-order_details-main {
        margin-bottom: 0; } }
  .b-order_details-aside {
    grid-area: aside; }
    .b-order_details-aside .b-order_info_card {
      margin: 30px 0 40px 0; }
  .b-order_details-track_order {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .b-order_details-track_order {
        margin-top: 30px; } }
  .b-order_details-track_order_btn {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0; }
    .b-order_details-track_order_btn:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-order_details-track_order_btn:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-order_details-track_order_btn:disabled:not(.m-processing), .b-order_details-track_order_btn.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-order_details-track_order_btn:disabled:not(.m-processing), .b-order_details-track_order_btn.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-order_details-track_order_btn.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-order_details-track_order_btn.m-width_s {
      width: var(--button-s); }
    .b-order_details-track_order_btn.m-width_m {
      width: var(--button-m); }
    .b-order_details-track_order_btn.m-width_l {
      width: var(--button-l); }
    .b-order_details-track_order_btn.m-processing {
      cursor: wait; }
    @media screen and (max-width: 767px) {
      .b-order_details-track_order_btn {
        width: 100%; } }
  .b-order_details.m-guest {
    margin-top: 30px; }
    .b-order_details.m-guest .b-cart_table.m-full_info .b-cart_table-head {
      grid-template-columns: 1fr 200px 150px 170px; }
    @media screen and (min-width: 1200px) {
      .b-order_details.m-guest .b-cart_product.m-full_info {
        grid-template: [start details-s image-s price-s qty-s total-s] auto [bundle-s details-e price-e qty-e total-e] minmax(0, 1fr) [bundle-e image-e message-s] auto [end message-e]/[start image-s message-s] 115px [image-e bundle-s details-s] minmax(0, 1fr) [details-e price-s] 200px [price-e qty-s] 150px [bundle-e qty-e total-s] 170px [end total-e message-e]; } }
  @media screen and (min-width: 1200px) {
    .b-order_details.m-guest .b-order_details-top {
      display: flex;
      justify-content: space-between; } }
  .b-order_details.m-guest .b-order_details-top .b-account_card {
    flex: 1; }
  .b-order_details.m-guest .b-order_details-caption {
    text-align: center; }
  @media screen and (min-width: 1200px) {
    .b-order_details.m-guest .b-order_details-aside {
      max-width: 65%; } }

.b-summary_table {
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.75;
  text-align: left;
  width: 100%; }
  .b-summary_table-name, .b-summary_table-value {
    font-weight: inherit;
    padding: 5px 0; }
  .b-summary_table-value {
    padding-left: 5px;
    text-align: right;
    white-space: nowrap; }
  .b-summary_table-item {
    display: flex;
    justify-content: space-between; }
    .b-summary_table-item.m-discount {
      color: #BA8D36; }
    .b-summary_table-item.m-total {
      border-top: 1px solid rgba(210, 210, 206, 0.8);
      font-size: 18px;
      font-weight: 700;
      margin-top: 15px; }
  .b-summary_table-item.m-total .b-summary_table-name,
  .b-summary_table-item.m-total .b-summary_table-value {
    padding: 20px 0 0; }
  .b-summary_table-cellar {
    border-top: 1px solid;
    font-size: 16px;
    letter-spacing: 0.15px;
    padding-bottom: 8px;
    padding-top: 16px; }
  .b-summary_table-address {
    color: #A7A8A8;
    font-size: 12px;
    letter-spacing: 0.12px; }

.b-order_summary {
  border: 1px solid #D2D2CE; }
  .b-order_summary ~ .b-order_summary {
    margin-top: 50px; }
  .b-order_summary-header {
    border-bottom: 1px solid rgba(210, 210, 206, 0.8);
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    padding: 20px 25px 15px; }
  .b-order_summary-title {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase; }
  .b-order_summary-edit_button {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    align-items: center;
    display: inline-flex;
    letter-spacing: 2.1px;
    text-decoration: none;
    text-transform: uppercase; }
    .b-order_summary-edit_button:hover {
      text-decoration: none; }
    .b-order_summary-edit_button:visited {
      color: var(--color-link-visited, #584C44); }
    .b-order_summary-edit_button::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 1em;
      mask: url("./svg-icons/sprite.svg#edit");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 1em;
      display: inline-block;
      margin-right: 5px; }
    .b-order_summary-edit_button svg {
      display: inline-block;
      height: var(--link-icon_height, 8px);
      margin-right: 5px;
      width: var(--link-icon_width, 8px); }
  .b-order_summary-content {
    padding: 20px 25px; }
  .b-order_summary-shipping, .b-order_summary-totals, .b-order_summary-cta_wrap {
    margin-bottom: 25px; }
  .b-order_summary .apple-pay-message {
    text-align: center;
    margin-bottom: 15px; }
  .b-order_summary.m-cart_summary {
    border-width: 0 0 1px; }
  .b-order_summary.m-cart_summary .b-order_summary-header,
  .b-order_summary.m-cart_summary .b-order_summary-content {
    padding-left: 0;
    padding-right: 0; }
  .b-order_summary.m-cart_summary .b-order_summary-content {
    padding: 0; }
    @media screen and (min-width: 1200px) {
      .b-order_summary.m-cart_summary .b-order_summary-content {
        max-height: 620px;
        overflow: auto; } }
  .b-order_summary-apple_pay.hide-apple_pay {
    display: none; }
  .b-order_summary-apple_pay_format_button {
    background: var(--button_main-background_color, #fff); }

.b-cart_table-head {
  color: #202025;
  display: grid;
  font-size: 16px;
  grid-gap: 45px;
  grid-template-columns: 1fr 135px 152px;
  line-height: 26px;
  padding: 25px 0 10px;
  padding-top: 0;
  margin-top: -36px; }
  @media screen and (max-width: 1199px) {
    .b-cart_table-head {
      display: none;
      margin-bottom: 0; } }

.b-cart_table-body {
  border-top: 1px solid rgba(210, 210, 206, 0.8);
  padding-top: 30px; }

.b-cart_table .b-cart_product {
  border-bottom: 1px solid rgba(210, 210, 206, 0.8);
  padding-bottom: 30px;
  margin-bottom: 30px; }

.b-cart_table .b-message {
  margin-bottom: 20px; }

.b-cart_table.m-full_info .b-cart_table-head {
  grid-gap: 20px;
  grid-template-columns: 1fr 110px 70px 170px; }
  @media screen and (max-width: 1199px) {
    .b-cart_table.m-full_info .b-cart_table-head {
      display: block; } }

.b-cart_table.m-full_info .b-cart_table-qty,
.b-cart_table.m-full_info .b-cart_table-price,
.b-cart_table.m-full_info .b-cart_table-total {
  text-align: center; }
  @media screen and (max-width: 1199px) {
    .b-cart_table.m-full_info .b-cart_table-qty,
    .b-cart_table.m-full_info .b-cart_table-price,
    .b-cart_table.m-full_info .b-cart_table-total {
      display: none; } }

.b-cart_table.m-full_info .b-cart_table-total {
  text-align: right; }

.m-errortile {
  background: none;
  font-size: 14px;
  text-align: left;
  width: 600px;
  padding: 14px 21px !important;
  border: none !important;
  color: red !important; }

.b-cart_product {
  font: 16px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  align-items: start;
  display: grid;
  grid-template: [start details-s image-s qty-s total-s remove-s] auto [bundle-s details-e qty-e total-e] minmax(0, 1fr) [end bundle-e image-e remove-e]/[start image-s] 165px [image-e] 20px [bundle-s details-s] minmax(0, 1fr) [details-e] 45px [qty-s] 135px [bundle-e qty-e] 45px [total-s] 130px [remove-s total-e] 22px [end remove-e]; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-cart_product {
      grid-template: [start details-s image-s remove-s] auto [details-e qty-s total-s] auto [bundle-s qty-e total-e] minmax(0, 1fr) [end bundle-e image-e remove-e]/[start image-s] 100px [image-e] 20px [bundle-s details-s qty-s] 135px [qty-e total-s] minmax(0, 1fr) [bundle-e details-e total-e] 20px [remove-s] 22px [end remove-e]; } }
  @media screen and (max-width: 767px) {
    .b-cart_product {
      grid-template: [start image-s details-s remove-s] auto [details-e qty-s] auto [qty-e total-s] auto [bundle-s total-e] auto [end bundle-e image-e remove-e]/[start image-s] 100px [image-e] 15px [bundle-s details-s qty-s total-s] minmax(0, 1fr) [bundle-e details-e qty-e total-e] 15px [remove-s] 22px [end remove-e]; } }
  .b-cart_product-price_column {
    grid-area: price-s/price-s/price-e/price-e; }
  .b-cart_product-image {
    grid-area: image-s/image-s/image-e/image-e; }
  .b-cart_product-image_link {
    display: block; }
  .b-cart_product-picture {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
    .b-cart_product-picture img {
      bottom: 0;
      color: #252525;
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  .b-cart_product-details {
    grid-area: details-s/details-s/details-e/details-e; }
    @media screen and (max-width: 1199px) {
      .b-cart_product-details {
        margin-bottom: 20px; } }
  .b-cart_product-qty {
    display: flex;
    grid-area: qty-s/qty-s/qty-e/qty-e; }
    @media screen and (max-width: 767px) {
      .b-cart_product-qty {
        margin-bottom: 20px; } }
    .b-cart_product-qty.m-preselected {
      margin-top: 20px; }
      @media screen and (min-width: 1441px) {
        .b-cart_product-qty.m-preselected {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .b-cart_product-qty.m-preselected {
          margin-top: 0; } }
    .b-cart_product-qty .b-product_quantity {
      width: 135px; }
  .b-cart_product-qty_value {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; }
  .b-cart_product-total {
    grid-area: total-s/total-s/total-e/total-e; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-cart_product-total {
        align-self: center;
        margin-left: 20px; } }
    @media screen and (max-width: 1199px) {
      .b-cart_product-total {
        align-items: baseline;
        display: flex; } }
    @media screen and (min-width: 1200px) {
      .b-cart_product-total .b-product_price {
        font-size: 18px; } }
  .b-cart_product-total_label {
    display: none; }
    @media screen and (max-width: 1199px) {
      .b-cart_product-total_label {
        display: block;
        margin-right: 5px; } }
  .b-cart_product-remove {
    grid-area: remove-s/remove-s/remove-e/remove-e; }
  @media screen and (min-width: 1200px) {
    .b-cart_product-total, .b-cart_product-remove {
      align-items: center;
      display: flex;
      height: 60px; } }
  .b-cart_product-bundle {
    grid-area: bundle-s/bundle-s/bundle-e/bundle-e;
    margin-top: 15px; }
  .b-cart_product-bundle_control {
    align-items: center;
    cursor: pointer;
    display: flex; }
    .b-cart_product-bundle_control::after {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 6px;
      mask: url("./svg-icons/sprite.svg#arrow-down"), url("./svg-icons/sprite.svg#arrow-up");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%, 0;
      width: 10px; }
    .b-cart_product-bundle_control[aria-expanded='true']::after {
      mask-size: 0, 100%; }
  .b-cart_product-bundle_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    margin-right: 10px; }
    .b-cart_product-bundle_link:hover {
      text-decoration: none; }
  .b-cart_product-bundle_items {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    transition-property: height, opacity;
    visibility: hidden; }
    .b-cart_product-bundle_items[aria-hidden='false'] {
      height: auto;
      opacity: 1;
      visibility: visible;
      overflow: visible; }
  .b-cart_product-error {
    align-items: center;
    background: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 3px;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    padding: 15px 35px;
    text-align: center;
    background: #FAEDED;
    border: none;
    color: #CA1E36;
    grid-area: message-s/message-s/message-e/message-e;
    margin-top: 30px; }
    @media screen and (max-width: 767px) {
      .b-cart_product-error {
        padding: 20px; } }
  .b-cart_product-error_accent_text {
    font-weight: 700; }
  .b-cart_product .b-cart_bundle {
    margin-top: 30px; }
  .b-cart_product.m-full_info {
    column-gap: 20px;
    grid-template: [start details-s image-s price-s qty-s total-s] auto [bundle-s details-e price-e qty-e total-e] minmax(0, 1fr) [bundle-e image-e messsage-s] auto [end message-e]/[start image-s message-s] 115px [image-e bundle-s details-s] minmax(0, 1fr) [details-e price-s] 110px [price-e qty-s] 70px [bundle-e qty-e total-s] 170px [end total-e message-e]; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-cart_product.m-full_info {
        grid-template: [start details-s image-s] auto [details-e] auto [price-s] auto [price-e total-s] auto [bundle-s total-e] minmax(0, 1fr) [bundle-e image-e message-s] auto [end message-e]/[start image-s message-s] 100px [image-e bundle-s details-s price-s total-s] minmax(0, 1fr) [end bundle-e price-e details-e total-e message-e]; } }
    @media screen and (max-width: 767px) {
      .b-cart_product.m-full_info {
        column-gap: 15px;
        grid-template: [start details-s image-s] auto [details-e] auto [price-s] auto [price-e total-s] auto [bundle-s total-e] minmax(0, 1fr) [bundle-e image-e message-s] auto [end message-e]/[start image-s message-s] 100px [image-e bundle-s details-s price-s qty-s total-s] minmax(0, 1fr) [end bundle-e details-e price-e qty-e total-e message-e]; } }
  .b-cart_product.m-full_info .b-cart_product-qty {
    justify-content: center; }
    @media screen and (max-width: 1199px) {
      .b-cart_product.m-full_info .b-cart_product-qty {
        display: none; } }
  @media screen and (max-width: 1199px) {
    .b-cart_product.m-full_info .b-cart_product-price_column {
      display: none; } }
  .b-cart_product.m-full_info .b-cart_product-price_column .b-product_price {
    font-size: 16px;
    justify-content: center; }
  .b-cart_product.m-full_info .b-cart_product-total {
    height: auto;
    justify-content: flex-end; }
    @media screen and (max-width: 1199px) {
      .b-cart_product.m-full_info .b-cart_product-total {
        justify-content: flex-start;
        margin: 0; } }
    .b-cart_product.m-full_info .b-cart_product-total .b-product_price {
      justify-content: flex-end; }
  @media screen and (max-width: 1199px) {
    .b-cart_product.m-full_info .b-cart_product_details-attributes {
      font-size: 14px; } }
  .b-cart_product.m-full_info .b-minicart_product-subtotal_price {
    justify-content: flex-end; }

.b-cart_product_details-name {
  font: 500 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
  text-transform: capitalize; }

.b-cart_product_details-sku {
  color: #202025;
  font: 14px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  margin-top: 5px; }

.b-cart_product_details-attributes {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 1.5; }

.b-cart_product_details-attribute {
  align-items: center;
  display: flex; }
  .b-cart_product_details-attribute::after {
    background: #D2D2CE;
    content: '';
    height: 12px;
    margin: 0 10px;
    width: 1px; }
  .b-cart_product_details-attribute:last-child::after {
    content: none; }
  .b-cart_product_details-attribute.m-qty {
    text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .b-cart_product_details-attribute.m-qty {
        display: none; } }

@media screen and (min-width: 1200px) {
  .b-cart_product_details-attributes.m-with_qty .b-cart_product_details-attribute:nth-last-child(2)::after {
    content: none; } }

.b-cart_product_details-qty {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px; }

.b-cart_product_details-qty_value {
  font-weight: bold; }

.b-cart_product_details-price, .b-cart_product_details-attributes,
.b-cart_product_details .b-product_promo,
.b-cart_product_details .b-product_availability {
  margin-top: 15px; }

.b-cart_product_details .b-product_promo {
  width: fit-content; }

.b-cart_product_details .b-product_availability {
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 1.5; }
  .b-cart_product_details .b-product_availability::before {
    margin-right: 10px; }

.b-cart_product_details .b-product_actions-description {
  font-size: 14px; }

.b-cart_product_details .b-product_actions-description_date {
  font-weight: 700; }

.b-cart_bundle {
  display: grid;
  gap: 20px;
  grid-template-areas: 'image details';
  grid-template-columns: 120px 1fr; }
  @media screen and (max-width: 1199px) {
    .b-cart_bundle {
      grid-template-columns: 75px 1fr; } }
  @media screen and (max-width: 767px) {
    .b-cart_bundle {
      gap: 15px; } }
  .b-cart_bundle-image {
    grid-area: image; }
  .b-cart_bundle-image_link {
    display: block; }
  .b-cart_bundle-picture {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
    .b-cart_bundle-picture img {
      bottom: 0;
      color: #252525;
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  .b-cart_bundle-details {
    grid-area: details; }

.b-summary_address {
  font: 14px/1.57 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px; }
  .b-summary_address-default {
    align-items: center;
    display: flex;
    font-weight: 600;
    margin-top: 20px; }
    .b-summary_address-default::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 13px;
      mask: url("./svg-icons/sprite.svg#checkmark");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 13px;
      margin-right: 5px; }

.b-summary_payment {
  font: 14px/1.57 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  margin-bottom: 20px; }
  .b-summary_payment .b-payment_icon {
    margin: 0 5px; }
  .b-summary_payment-number {
    display: flex; }
  .b-summary_payment-type {
    text-transform: capitalize; }
  .b-summary_payment .b-payment_methods {
    margin-left: 60px; }

.b-summary_group {
  display: grid;
  gap: 0 20px;
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 767px) {
    .b-summary_group {
      gap: 20px;
      grid-template-columns: 1fr; } }
  .b-summary_group-title {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-bottom: 15px; }
  .b-summary_group-item.m-gift {
    word-break: break-word; }
  .b-summary_group .b-expected_ship_date {
    grid-area: 2 / 2; }
    @media screen and (max-width: 767px) {
      .b-summary_group .b-expected_ship_date {
        grid-area: 3 / 1; } }

@media screen and (min-width: 1200px) {
  .b-checkout_products {
    padding-right: 5px; } }

.b-checkout_products .b-minicart_product {
  padding-bottom: 25px; }
  .b-checkout_products .b-minicart_product-details .b-minicart_product-subtotal .b-product_price .b-product_price-value span {
    display: none; }

.b-checkout_products .b-minicart_product:first-child {
  margin-top: 25px; }

.b-checkout_products .b-minicart_product + .b-minicart_product {
  border-top: 1px solid #D2D2CE;
  padding-top: 25px; }

.b-payment_icon {
  color: #A7A8A8;
  display: inline-block;
  flex-shrink: 0;
  height: 22px;
  width: 32px; }

body {
  opacity: var(--page_opacity, 0);
  overflow: auto;
  overflow-y: scroll;
  pointer-events: all;
  visibility: var(--page_visibility, hidden); }

@media screen and (min-width: 768px) {
  .cancelOrder-modal.b-dialog.m-quick_view .b-dialog-window {
    width: 40%; }
  .cancelOrder-modal .renderTemplate {
    margin-left: 15%; } }

.cancelOrder-modal.b-dialog.m-quick_view .b-dialog-window {
  top: 10%;
  width: 90%; }

.cancelOrder-modal .b-dialog-close {
  position: absolute;
  right: 40px;
  top: 40px; }

.cancelOrder-modal .b-dialog-body {
  text-align: left; }
  .cancelOrder-modal .b-dialog-body .cancel-order-FAQ {
    display: inline-block; }
  .cancelOrder-modal .b-dialog-body .cancelOrder-question {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px; }
    .cancelOrder-modal .b-dialog-body .cancelOrder-question a {
      cursor: pointer;
      font-family: "Proxima Nova", sans-serif;
      padding: 0 5px;
      text-decoration: underline; }
  .cancelOrder-modal .b-dialog-body .cancelOrder-title {
    color: #202025;
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.23px;
    line-height: 32px;
    margin: 108px 0 8px; }
  .cancelOrder-modal .b-dialog-body select#cancelreason {
    border: 2px solid #202025;
    color: #0c0c0c;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    height: 56px;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin: 8px 0;
    width: 100%; }
  .cancelOrder-modal .b-dialog-body .b-form-btn_alt {
    margin: 8px 0px; }
  .cancelOrder-modal .b-dialog-body .b-form-btn_main {
    background-color: #202025;
    color: #fff;
    margin: 8px 0; }
  .cancelOrder-modal .b-dialog-body .cancelOrderfailure-title {
    color: #202025;
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.23px;
    line-height: 32px;
    margin: 108px 0 24px; }
  .cancelOrder-modal .b-dialog-body .cancelOrderfailure-msg {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin: 12px 0 41px; }
    .cancelOrder-modal .b-dialog-body .cancelOrderfailure-msg a {
      cursor: pointer;
      font-family: "Proxima Nova", sans-serif;
      padding: 0 4px;
      text-decoration: underline; }

@media screen and (min-width: 768px) {
  .b-order_short_summary-list .cancelorder-modal {
    text-align: right; } }

.b-order_short_summary-list .cancelorder-modal {
  text-align: left; }

.cancelinfotime-msg {
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  letter-spacing: 0.15px;
  margin-top: 8px; }

@media screen and (max-width: 1199px) {
  .b-account_card .cancelinfotime-msg {
    text-align: center; }
  .b-account_card .cancelorder-modal .cancelinfotime-msg {
    text-align: left; } }

.b-account_card-defaultaddress svg {
  height: 12px;
  width: 17px; }

.b-order_short_summary-status-deleted {
  color: #CA1E36;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: bold; }

.deleted {
  color: #CA1E36; }

/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS, which puts the properties and values you are changing right next
to each other.

That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in another different file.

## Configuration

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1200px)',
	lg-down: 'screen and (max-width: 1440px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Rh (Indents rhythm)

Initial assumption is that Designs should follow the agreed Rhythm.

The main puprose of Rhythm function is to soothe inconsistencies of design for vertical and horizontal indents if such appear.

If we have agreed on Rhythm = 5 and we meet padding or margin 22px in design - we should round it to rh(4)=20px

Rhythm function can be avoided if some measurements are specific, i.e.:
	-icon size, blocks width/height are specific values
	-form elements paddings/margins/sizes are usually specific values
	-positioning of elements might have nothing to do with parent paddings

## Usage

```scss
.component {
	@include rh(2); // => 10px
	@include rh(2 4 0); // => 10px 20px 0
}
```
*/
/* stylelint-disable */
/* stylelint-enable */
/*md
@no-stat

# Z-indexes

This is map of z-indexes used on project. To easily work with z-indexes and avoid many issues related to this,
you need to not use z-index directly, but instead use z() function to get z-index value from this map.

## Usage

### Global components

```scss
// settings.scss
$z-indexes: (
	content: (),
	popup-menu: ()
);

// component.scss
.b-pop_up-menu {
	//...
	z-index: z(popup-menu);
}
```

### Inside a component

```scss
// settings.scss
$z-indexes: (
	components: (
		component_name: (),
		checkbox: (
			before: (),
			after: (),
			icon: (),
		)
	),
	content: (),
	popup-menu: ()
);

// component.scss
.component_name {
	position: relative;
	z-index: z(components, component_name);
}

// checkbox.scss
.checkbox {
	&-icon {
		z-index: z(components, checkbox, icon);
	}
}
```

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 56.25%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

Second parameter is for the `!important` keyword.

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
/*md
@no-stat

# Lines clamp

This mixin is useful for adding ... for text with more than 1 line

## Usage

```scss
.component {
	@include line-clamp;
}
```
*/
/*md
@no-stat

# Lines clamp with lines reserve

This mixin is useful for adding ... for text with more than 1 line and reserve space

## Usage

```scss
.component {
	@include lines-clamp-reserve;
}
```
*/
/* stylelint-disable
	property-no-vendor-prefix,
	property-no-unknown
*/
/*md
@no-stat

# Force repaint

This mixin is useful when repaint needs to be triggered. (sometimes on iOS)

## Usage

```scss
.component {
	@include force_repaint;
}
```
*/
@keyframes repaint {
  from {
    padding-bottom: 0.01px; }
  to {
    padding-bottom: 0; } }

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behaviour of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# Snap scroll

CSS snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: `.b-carousel`, `.b-product_gallery`, `.b-product_slider` etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

```scss_example
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container. It could be used not only for standalone blocks
but for page layouts also.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# Headings components

```scss_example
.b-title {
	@include t-heading_1;
}
```

*/
/*md

# Core component for all links

All core and common links styling
stores in this mixin

Be careful changing styles in this mixin!

```scss_example
@mixin t-link_6 {
	@include t-link;
}
```

*/
/*md

# Underlined link component

It extends and modifies `t-link` component
and is a core component for underlined links

```scss_example
@mixin t-link_1 {
	@include t-link_underlined;

	...
}
```
*/
@keyframes link-underline {
  0% {
    background-size: 0 1px; }
  100% {
    background-size: 100% 1px; } }

/*md

# Link type 1

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_1;
}
```
*/
/*md

# Link type 2

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component
```scss_example
.b-some_link {
	@include t-link_2;
}
```
*/
/*md

# Link type 3

Link followed with the arrow.
It extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_3;
}
```
*/
@keyframes link-arrow {
  0% {
    width: 5px; }
  100% {
    width: 50px; } }

/*md

# Link type 4

Link with an icon.
It inherits and extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_4;
}
```

Mixin accepts following parameters:
- `$icon` (icon name)
- `$icon-height` (1em by default)
- `$icon-width` (1em by default)

*/
/*md

# Link type 5

Another variation of an underlined link

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_5;
}
```
*/
/*md

# Paragraph components

```scss_example
.b-cart_empty {
	// ...

	&-paragraph {
		@include t-paragraph_1;
	}
}
```

*/
/*md

# List component

Component for styling ordered or unordered lists

List items are also styled.

```scss_example
.b-tips {
	@include t-list;
}

.b-tips {
	@include t-list(ordered);
}
```

*/
/*md

# g-promo*

Mixin for promotion.

```scss_example
.b-cart_promo {
	@include g-promo();

}
```

*/
/*md

# g-button_reset*

Mixin for reset button style.

```scss_example
.b-button {
	@include g-button_reset;
}

```

*/
/*md

# g-button_*

# Mixin for button global styles.

```scss_example
.b-button {
	@include g-button_global($_mod: default);
}
```

*/
/*md

# g-button_main*

# Mixin for main button.

```scss_example
.b-button {
	@include g-button_main($_mod: default);
}
```
*/
/*md

# g-button_alt*

# Mixin for alt button.

```scss_example
.b-button {
	@include g-button_alt;
}
```
*/
/*md

# f-checkbox

Default checkbox element

## Default

```scss_example
	.b-input_checkbox {
		@include f-checkbox($el: root, $mod: default);
	}
```
*/
/*
# Input - type date

Default input element

```scss_example
	.b-some_context_component {
		@include f-input_date($style: default);
	}
```
*/
/*md

# f-input_password

Default input element

## Default input

```scss_example
	.f-input_password {
		@include f-input_password($style: default);
	}
```
*/
/*md

# f-input_reset

Default reset input element

## Default

```scss_example
	.b-input {
		@include f-input_reset;
	}
```
*/
/*md

# f-input_text

Default input element

## Default input

```scss_example
	.f-input_text {
		@include f-input_text($style: default);
	}
```

##Alt input

```scss_example
	.f-input_text {
		@include f-input_text($_mod: alt);
	}
```
*/
/*md

# f-label

Default label element

## Default label

```scss_example
	.f-input_text-label {
		@include f-label($style: default);
	}
```
*/
/*md

# f-radio

Default radio element

## Default

```scss_example
	.f-input_radio {
		@include f-radio;
	}
```
*/
/*md

# f-select

Default label element

## Default

```scss_example
	.f-input_select {
		@include f-select;
	}
```
*/
/*md

# f-textarea

Default textarea element

## Default

```scss_example
	.f-input_textarea {
		@include f-textarea;
	}
```
*/
/*md
# Carousel component
This carousel component is based on a simple built-in scroll functionality.

All layout, sizing, positioning is also controlled in CSS or with the help of HTML attributes.

## Carousel layout options - Number of slides per view

There are two types of configuration:
- static number of slides
- static slide's width

### Static number of slides

To define number of slides per view you can use a CSS custom properties or HTML attributes

#### CSS custom properties
`--carousel-slides_number` and `--carousel-number_of_gaps_per_view`.

We need `--carousel-number_of_gaps_per_view` for accurate calculations of slide's width.

Formulas for these calculations are defined in `g-carousel` mixin.

Default values for these properies are:

```
--carousel-slides_number: 1;
--carousel-number_of_gaps_per_view: 0;
```

```scss_example
.b-custom_carousel {
	@include g-carousel;

	--carousel-slides_number: 3;
	--carousel-number_of_gaps_per_view: 2;
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-number` where you
can define viewport names which have static slides number configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure exact number of slides for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-number_of_gaps_per_view-sm` and `--carousel-slides_number-sm` for Small
- `--carousel-number_of_gaps_per_view-md` and `--carousel-slides_number-md` for Medium
- `--carousel-number_of_gaps_per_view-lg` and `--carousel-slides_number-lg` for Large
- `--carousel-number_of_gaps_per_view-xl` and `--carousel-slides_number-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-number="sm lg"
	style="
		--carousel-number_of_gaps_per_view-sm: 1; --carousel-slides_number-sm: 2;
		--carousel-number_of_gaps_per_view-lg: 3; --carousel-slides_number-lg: 3.5;
	"
>...</section>
```

### Dynamic number of slides

The opposite to the static number of slides is a dynamic number of slides.

You should use it when you don't need the exact number of slides per view
but rather slides width. Visually it will behave this way: when you stretch
viewport size number of slides will increase but the slide's width won't change.

To define slides width you can use CSS custom property or HTML attributes

#### CSS custom properties

There's just one CSS custom property you need: `--carousel-slides-width`

```scss_example
.b-custom_slider {
	--carousel-slides-width: 300px;

	@include media(sm) {
		--carousel-slides-width: 150px;
	}
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-width` where you
can define viewport names which have static slides width configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure a slides width for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-slides_width-sm` for Small
- `--carousel-slides_width-md` for Medium
- `--carousel-slides_width-lg` for Large
- `--carousel-slides_width-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-width="md xl"
	style="
		--carousel-slides_width-md: 250px;
		--carousel-slides_width-xl: 350px;
	"
>...</section>
```

## Carousel layout options - Gutter value between slides

Gap between slides is controlled via CSS custom
property: `--carousel-gutter`

It has the default value: `--carousel-gutter: 0`

## Carousel layout options - Number of rows

Number of rows is controlled via CSS custom
property: `--carousel-rows_number`

It has the default value: `--carousel-rows_number: 1`

*/
/*md

# g-separator

Separator

```scss_example
	b-form {
		@include g-separator;

		// ...
	}
}
```
*/
/*md

# g-accordion

Accordion styles that used in several component.

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss_example
.g-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(item);
	}

	&-title {
		@include g-accordion(control);
	}

	&-content {
		@include g-accordion(content);

		&[aria-hidden='false'] {
			@include g-accordion(content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(content_inner);
	}
}
```

*/
/*md

# g-message*

Mixin for message tile.

```scss_example
.b-message {
	@include g-message;
}
```

*/
.l-account {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1308px;
  margin-bottom: 110px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .l-account {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .l-account {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .l-account {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .l-account {
      margin-bottom: 50px; } }
  @media screen and (min-width: 1200px) {
    .l-account-content {
      display: grid;
      gap: var(--grid-gutter);
      grid-template-columns: repeat(10, 1fr); } }
  .l-account-aside {
    margin: 0 0 30px; }
    @media screen and (min-width: 1200px) {
      .l-account-aside {
        grid-area: 1 / 1 / 2 / 4;
        margin: 0 40px 0 0; } }
  @media screen and (min-width: 1200px) {
    .l-account-main {
      grid-area: 1 / 4 / 2 / -1; } }
  .l-account-form {
    margin: 0 auto;
    max-width: 450px;
    width: 70%; }
    @media screen and (max-width: 767px) {
      .l-account-form {
        width: 100%; } }
    .l-account-form .b-login_helpers {
      margin: 25px 0 35px 0; }
    .l-account-form .b-social_login {
      margin: 60px 0 30px 0; }
      @media screen and (max-width: 767px) {
        .l-account-form .b-social_login {
          margin: 50px 0 0 0; } }
    .l-account-form .b-tab_list {
      margin-bottom: 25px; }
    .l-account-form .b-account_benefits {
      margin-bottom: 30px; }
  .l-account-breadcrumbs {
    margin: 20px 0 30px 0; }
    @media screen and (min-width: 1200px) {
      .l-account-breadcrumbs {
        margin: 35px 0; } }
  .l-account.m-top_margin {
    margin-top: 50px; }
  .l-account .b-back_button {
    margin-top: 20px; }
  .l-account .b-account_title {
    margin-bottom: 45px; }
    @media screen and (max-width: 767px) {
      .l-account .b-account_title {
        margin-bottom: 30px; } }
  .l-account .b-payment_option {
    margin-bottom: 25px; }
  .l-account .b-account_card {
    margin-bottom: 30px; }
  .l-account .b-account_nav {
    margin-bottom: 55px; }
  .l-account .b-user_greeting {
    margin-bottom: 35px; }
  .l-account-navigation_cta {
    border: 2px solid #202025;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    justify-content: flex-start;
    line-height: 50px;
    padding: 0;
    text-transform: uppercase;
    width: 100%; }
    @media screen and (min-width: 1200px) {
      .l-account-navigation_cta {
        display: none; } }
    .l-account-navigation_cta svg {
      border-right: 2px solid #202025;
      box-sizing: content-box;
      height: 100%;
      padding: 0 15px; }
  .l-account-navigation_cta_text {
    margin: 0 auto; }
  .l-account-info_msg {
    font-size: 16px;
    margin-bottom: 25px; }
    @media screen and (max-width: 767px) {
      .l-account-info_msg {
        text-align: center; } }
  .l-account .b-address_selector,
  .l-account .b-address_selector-back {
    margin-bottom: 20px; }
  .l-account nav.b-account_nav.fav-height {
    margin-bottom: 25px !important; }

.b-account_title {
  font: 600 40px/56px "Proxima Nova", sans-serif;
  letter-spacing: 0.6px; }
  @media screen and (max-width: 1199px) {
    .b-account_title {
      font-size: 40px;
      letter-spacing: 0.5px;
      line-height: 48px; } }
  @media screen and (max-width: 767px) {
    .b-account_title {
      text-align: center; } }
  .b-account_title.m-center {
    text-align: center; }

.b-reset_password_success {
  text-align: center; }
  .b-reset_password_success-title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0 30px; }
  .b-reset_password_success-copy {
    margin: 10px 0 30px; }

.b-code_field-caption {
  margin: 10px 0 30px; }

.b-code_field-link {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: normal;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 0; }
  .b-code_field-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-code_field-link:hover {
    text-decoration: none; }
  .b-code_field-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .b-code_field-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-code_field-link:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-code_field-link:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-code_field-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }

.b-code_field .f-field {
  margin-bottom: 0; }

/*md

# Global messages

## Info message

```html_example
<div class="b-message">
	Your wish list is only available on this device and expire after 7 days.
	Sign in or Create account to keep your items always at hand.
</div>
```

## Promo message

```html_example
<div class="b-message m-promo">
	Today only! Buy 6 bottles and get 20% OFF
</div>
```

## Error message

```html_example
<div class="b-message m-error">
	Oops! It looks like some of the items below are no longer available.
	Please update your shopping cart to continue
</div>
```

*/
.b-message {
  align-items: center;
  background: #fff;
  border: 2px solid #D2D2CE;
  border-radius: 3px;
  color: #202025;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  line-height: 24px;
  padding: 15px 35px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .b-message {
      padding: 20px; } }
  .b-message.m-promo {
    background: rgba(246, 246, 245, 0.2);
    border-color: #F6F6F5;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase; }
  .b-message.m-error {
    background: #FAEDED;
    border: none;
    color: #CA1E36; }
  .b-message-accent_text {
    font-weight: 700; }
  .b-message-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: normal;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-message-link:hover {
      text-decoration: none; }
    .b-message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-message-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-message-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

/*md

# b-option_switch

This component allows user to choose one option for ex. shipping method, saved address etc.

```html_example
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-001" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="001" data-value="001" data-ref="field" data-event-change="update">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-001">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">Ground</span>
				Free
			</div>
			<span class="b-option_switch-description">
				7-10 Business Days
			</span>
		</label>
	</div>
</div>
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-002" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="002" data-value="002" data-ref="field" data-event-change="update" checked="">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-002">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">2-Day Express</span>
				$9.99
			</div>
			<span class="b-option_switch-description">
				2 Business Days
			</span>
		</label>
	</div>
</div>
```

*/
.b-option_switch {
  padding: 25px;
  user-select: none; }
  .b-option_switch.m-disabled {
    background-color: #252525;
    pointer-events: none; }
  .b-option_switch ~ .b-option_switch {
    margin-top: 10px; }
  .b-option_switch-inner {
    display: flex;
    position: relative; }
  .b-option_switch-label {
    cursor: pointer;
    display: block;
    width: 100%; }
    .b-option_switch-label::before {
      border: 1px solid #D2D2CE;
      bottom: -25px;
      content: '';
      left: -25px;
      position: absolute;
      right: -25px;
      top: -25px; }
      .b-option_switch-input:checked ~ .b-option_switch-label::before {
        border-color: #202025; }
  .b-option_switch-label_surface {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
  .b-option_switch-edit_button {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    align-items: center;
    display: inline-flex;
    letter-spacing: 2.1px;
    text-decoration: none;
    text-transform: uppercase;
    position: relative; }
    .b-option_switch-edit_button:hover {
      text-decoration: none; }
    .b-option_switch-edit_button:visited {
      color: var(--color-link-visited, #584C44); }
    .b-option_switch-edit_button::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 1em;
      mask: url("./svg-icons/sprite.svg#edit");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 1em;
      display: inline-block;
      margin-right: 5px; }
    .b-option_switch-edit_button svg {
      display: inline-block;
      height: var(--link-icon_height, 8px);
      margin-right: 5px;
      width: var(--link-icon_width, 8px); }
  .b-option_switch-label_edit {
    margin-left: 30px; }
  .b-option_switch-input {
    border: 0 ;
    clip: rect(1px, 1px, 1px, 1px) ;
    margin: 0 ;
    max-height: 1px ;
    max-width: 1px ;
    overflow: hidden ;
    padding: 0 ;
    position: absolute ; }
  .b-option_switch-icon {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    background: var(--icon-color, #BA8D36);
    display: block;
    height: 20px;
    mask: url("./svg-icons/sprite.svg#radio-unchecked"), url("./svg-icons/sprite.svg#radio-checked");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%, 0;
    width: 20px;
    background: #202025;
    cursor: pointer;
    height: 20px;
    margin-right: 10px;
    width: 20px; }
    .b-option_switch-icon::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .b-option_switch-icon:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
  .b-option_switch-input:checked + .b-option_switch-icon {
    mask-size: 0, 100%; }
  .b-option_switch-name {
    color: #A7A8A8;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase; }
  .b-option_switch-input:checked ~ .b-option_switch-label .b-option_switch-name {
    color: inherit; }
  .b-option_switch-description {
    display: block;
    margin-top: 10px; }
  .b-option_switch-promotion {
    display: inline-block;
    margin-top: 10px; }
  .b-option_switch .b-product_promo,
  .b-option_switch .b-product_price {
    font-size: inherit; }

.b-address_selector {
  margin: 0; }
  .b-address_selector-actions {
    display: flex;
    justify-content: space-between;
    margin: 20px 0; }
  .b-address_selector-button {
    cursor: pointer;
    font-size: 16px; }
    .b-address_selector-button::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 12px;
      mask: url("./svg-icons/sprite.svg#plus");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 12px;
      display: inline-block;
      margin-right: 5px; }

.b-login_helpers {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .b-login_helpers-forgot_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    font-size: 16px; }
    .b-login_helpers-forgot_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-login_helpers-forgot_link:hover {
      text-decoration: none; }
    .b-login_helpers-forgot_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-login_helpers-forgot_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-login_helpers-forgot_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-login_helpers-forgot_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-login_helpers-forgot_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-login_helpers .f-field {
    margin-bottom: 0; }

.b-cards_grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 767px) {
    .b-cards_grid {
      grid-template-columns: 1fr; } }

.b-user_greeting {
  align-items: center;
  display: flex; }
  .b-user_greeting-message {
    color: #202025;
    font: normal 32px/40px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  .b-user_greeting-signout {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
    transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s; }
    .b-user_greeting-signout:visited {
      color: var(--color-link-visited, #584C44); }
    .b-user_greeting-signout:hover {
      text-decoration: none; }
    .b-user_greeting-signout.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-user_greeting-signout:visited {
      color: var(--color-link-visited, #202025); }
    .b-user_greeting-signout:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-user_greeting-signout:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-user_greeting-signout.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-user_greeting-signout:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-user_greeting-signout:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }

.b-account_nav-item {
  border-top: 1px solid #D2D2CE;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2.2px;
  padding: 0;
  text-transform: uppercase;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s; }
  .b-account_nav-item:last-child {
    border-bottom: 1px solid #D2D2CE; }
  .b-account_nav-item.m-active {
    background: #F6F6F5;
    color: #584C44;
    font-weight: 700; }

.b-account_nav-item_link {
  display: block;
  padding: 25px 20px;
  position: relative;
  text-align: left; }
  .b-account_nav-item_link::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 11px;
    mask: url("./svg-icons/sprite.svg#arrow-right-thin");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em;
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
  .b-account_nav-item_link:hover {
    text-decoration: none; }
  .b-account_nav-item_link:hover::after {
    opacity: 1; }

.b-account_nav-item.m-club .b-account_nav-item_text::after {
  background: var(--icon-color, #BA8D36);
  content: '';
  display: block;
  height: 2em;
  mask: url("./svg-icons/sprite.svg#grape");
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 1em;
  color: #bfb9a1;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle; }

.b-account_nav-item.m-active .b-account_nav-item_link::after {
  opacity: 1; }

.b-navigation_panel {
  padding: 0; }
  @media screen and (min-width: 1200px) {
    .b-navigation_panel {
      display: block;
      position: static;
      visibility: visible;
      z-index: initial; } }
  @media screen and (max-width: 1199px) {
    .b-navigation_panel.m-opened {
      overflow-y: hidden; } }
  @media screen and (min-width: 1200px) {
    .b-navigation_panel::after {
      display: none; } }
  @media screen and (max-width: 1199px) {
    .b-navigation_panel-inner {
      background-color: #fff;
      bottom: 0;
      height: 100%;
      left: 0;
      max-width: 375px;
      overflow-y: auto;
      padding: 30px 20px 70px;
      position: fixed;
      top: 0;
      transform: translateX(-100%);
      transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
      transition-property: visibility, transform;
      visibility: hidden;
      width: 100%;
      z-index: 11; }
      .b-navigation_panel-inner.m-active {
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
        transform: translateX(0);
        visibility: visible; } }
  .b-navigation_panel-title {
    font: 400 28px/36px "Proxima Nova", sans-serif;
    letter-spacing: 0.35px;
    display: block;
    margin-bottom: 40px; }
    @media screen and (max-width: 1199px) {
      .b-navigation_panel-title {
        font-size: 24px;
        letter-spacing: 0.3px; } }
    @media screen and (max-width: 1199px) {
      .b-navigation_panel-title {
        display: none; } }
  @media screen and (min-width: 1200px) {
    .b-navigation_panel .b-dialog-header {
      display: none; } }

.b-back_button {
  cursor: pointer;
  display: block;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase; }
  .b-back_button::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 12px;
    mask: url("./svg-icons/sprite.svg#line-arrow");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 51px;
    display: inline-block;
    margin-right: 5px;
    transform: rotate(180deg);
    vertical-align: middle; }

.b-load_more {
  text-align: center; }
  .b-load_more-button {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0;
    padding: 0 30px;
    width: 100%; }
    .b-load_more-button:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-load_more-button:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-load_more-button:disabled:not(.m-processing), .b-load_more-button.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-load_more-button:disabled:not(.m-processing), .b-load_more-button.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-load_more-button.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-load_more-button.m-width_s {
      width: var(--button-s); }
    .b-load_more-button.m-width_m {
      width: var(--button-m); }
    .b-load_more-button.m-width_l {
      width: var(--button-l); }
    .b-load_more-button.m-processing {
      cursor: wait; }

/*md

# Load progress

```html_example
<div class="b-load_progress">
	<span class="b-load_progress-description">
		You've viewed 8 of 16 products
	</span>
	<progress class="b-load_progress-indicator" max="100" value="50"></progress>
</div>
```

*/
.b-load_progress {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* WEBKIT */
  /* MOZILLA FIREFOX */ }
  .b-load_progress-description {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
    text-transform: none; }
  .b-load_progress-indicator {
    appearance: none;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    color: #BA8D36;
    height: 5px;
    width: 100%; }
  .b-load_progress-indicator::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 2px; }
  .b-load_progress-indicator::-webkit-progress-value {
    background-color: #BA8D36;
    border-radius: 2px; }
  .b-load_progress-indicator::-moz-progress-bar {
    background-color: #fff;
    border-radius: 2px; }
  .b-load_progress-indicator::-ms-fill {
    background-color: #BA8D36;
    border-radius: 2px; }

.b-payment_option {
  --payment_option-side-offset: 25px; }
  @media screen and (max-width: 767px) {
    .b-payment_option {
      --payment_option-side-offset: 15px; } }
  .b-payment_option-item:not(.m-zero_payment) {
    border: 1px solid #D2D2CE;
    margin-bottom: 25px; }
  .b-payment_option-title {
    border-bottom: 1px solid #D2D2CE;
    padding-bottom: 30px;
    padding: 15px var(--payment_option-side-offset); }
  .b-payment_option-head {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }
  .b-payment_option-label {
    font-weight: bold;
    text-transform: uppercase; }
  .b-payment_option-img {
    margin: 5px 0; }
  .b-payment_option-content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    transition-property: height, opacity;
    visibility: hidden; }
    .b-payment_option-content.m-opened {
      height: auto;
      opacity: 1;
      visibility: visible;
      height: auto !important; }
  .b-payment_option .apple-pay-container {
    margin: 30px; }
  .b-payment_option .apple-pay-message {
    margin-bottom: 20px; }
  .b-payment_option-content_inner.m-zero_payment {
    align-items: center;
    background: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 3px;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    padding: 15px 35px;
    text-align: center;
    background: rgba(246, 246, 245, 0.2);
    border-color: #F6F6F5;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      .b-payment_option-content_inner.m-zero_payment {
        padding: 20px; } }
  .b-payment_option .b-form-set,
  .b-payment_option .b-payment_options_group {
    margin: 30px var(--payment_option-side-offset) 10px; }
  .b-payment_option .b-form-line_exp_cvv {
    display: grid;
    gap: 0 15px;
    grid-template-columns: 1fr 1fr; }
    .b-payment_option .b-form-line_exp_cvv .f-field {
      margin-right: 0; }
    .b-payment_option .b-form-line_exp_cvv .f-field-caption {
      grid-column: 1/-1;
      margin: -20px 0 var(--payment_option-side-offset); }
    .b-payment_option .b-form-line_exp_cvv .f-input_password-toggle_visibility {
      display: none; }
  @media screen and (max-width: 767px) {
    .b-payment_option-head .b-payment_methods {
      margin-top: 10px;
      width: 100%; } }
  .b-payment_option .b-payment_icon {
    height: 20px;
    width: 28px; }
  .b-payment_option .b-form-back {
    margin-bottom: 25px; }
  .b-payment_option .b-message {
    margin-bottom: 20px; }

.b-expired_badge {
  background-color: #D2D2CE;
  border-radius: 2px;
  color: #CA1E36;
  font-size: 10px;
  font-weight: bold;
  height: 22px;
  line-height: 14px;
  padding: 4px 5px; }

.b-payment_methods {
  column-gap: 7px;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column; }
  .b-payment_methods .b-payment_icon {
    display: block; }

.b-social_login-header {
  font-size: 16px;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .b-social_login-header {
      margin-bottom: 25px; } }

.b-social_login-btns {
  display: flex; }

.b-social_login-btn {
  box-shadow: none;
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: box-shadow; }
  @media screen and (max-width: 767px) {
    .b-social_login-btn {
      padding: 15px 10px; } }
  .b-social_login-btn:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 5px rgba(0, 0, 0, 0.11); }
  .b-social_login-btn svg {
    margin-right: 10px; }

.b-social_login-btn.m-facebook {
  background-color: #4267b2;
  color: #fff;
  margin-right: 15px; }

.b-social_login-btn.m-google {
  background: #fff;
  border: 1px solid #202025; }

.b-social_login-text {
  color: #181818; }

.b-account_benefits-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px; }

.b-account_benefits-list {
  list-style: disc inside;
  margin-left: 10px; }
  .b-account_benefits-list li {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px; }

.b-account_benefits.m-with_icons .b-account_benefits-title {
  font: 700 22px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.3px; }

.b-account_benefits.m-with_icons .b-account_benefits-list {
  list-style-type: none;
  margin-left: 0; }

.b-account_benefits.m-with_icons .b-account_benefits-item {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative; }
  .b-account_benefits.m-with_icons .b-account_benefits-item::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 13px;
    mask: url("./svg-icons/sprite.svg#checkmark");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 13px;
    left: 0;
    position: absolute;
    top: 5px; }

.b-account_card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px; }
  .b-account_card-header {
    margin-bottom: 15px; }
  .b-account_card-title {
    font: 400 28px/36px "Proxima Nova", sans-serif;
    letter-spacing: 0.35px;
    border-bottom: 1px solid #D2D2CE;
    font-family: "Proxima Nova", sans-serif;
    font-weight: 500;
    letter-spacing: 0.35px;
    padding-bottom: 20px;
    text-align: left; }
    @media screen and (max-width: 1199px) {
      .b-account_card-title {
        font-size: 24px;
        letter-spacing: 0.3px; } }
  .b-account_card-title.m-club::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 1em;
    mask: url("./svg-icons/sprite.svg#grape");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em;
    color: #bfb9a1;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle; }
  .b-account_card-body {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px;
    margin: 10px 0 30px; }
    .b-account_card-body.m-delete_card {
      display: flex;
      justify-content: space-between; }
    .b-account_card-body .b-payment_methods {
      margin-left: 10px; }
  .b-account_card-button {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0;
    width: 100%;
    margin-bottom: 25px; }
    .b-account_card-button:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-account_card-button:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-account_card-button:disabled:not(.m-processing), .b-account_card-button.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-account_card-button:disabled:not(.m-processing), .b-account_card-button.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-account_card-button.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-account_card-button.m-width_s {
      width: var(--button-s); }
    .b-account_card-button.m-width_m {
      width: var(--button-m); }
    .b-account_card-button.m-width_l {
      width: var(--button-l); }
    .b-account_card-button.m-processing {
      cursor: wait; }
    @media screen and (min-width: 768px) {
      .b-account_card-button {
        margin-bottom: 0;
        margin-right: 25px;
        width: 315px; } }
    .b-account_card-button:last-child {
      margin-bottom: 0;
      margin-right: 0; }
  .b-account_card-list_item {
    margin-bottom: 10px; }
  .b-account_card-list_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-account_card-list_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_card-list_link:hover {
      text-decoration: none; }
    .b-account_card-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_card-list_link:visited {
      color: var(--color-link-visited, #202025); }
    .b-account_card-list_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_card-list_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_card-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-account_card-list_link.m-uppercase {
    text-transform: uppercase; }
  .b-account_card-message {
    font-weight: 300;
    margin-bottom: 5px; }
  .b-account_card-thumbnails {
    display: flex;
    margin-top: 25px; }
  .b-account_card-thumbnails_item {
    margin-right: 10px;
    max-width: 100%;
    width: 115px; }
    @media screen and (max-width: 767px) {
      .b-account_card-thumbnails_item {
        max-width: 62px;
        width: 16.67%; } }
    .b-account_card-thumbnails_item:last-child {
      margin-right: 0; }
    .b-account_card-thumbnails_item.m-additional {
      align-items: center;
      border: 2px solid #202025;
      border-radius: 50%;
      color: #202025;
      display: flex;
      flex-shrink: 0;
      font-size: 16px;
      font-weight: 700;
      height: 48px;
      justify-content: center;
      margin: auto 15px;
      transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
      transition-property: background-color, color;
      width: 48px; }
      .b-account_card-thumbnails_item.m-additional:hover {
        background-color: #202025;
        color: #fff;
        text-decoration: none; }
  .b-account_card-thumbnails_picture {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
  .b-account_card-thumbnails_img {
    bottom: 0;
    color: #252525;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%; }
  .b-account_card-footer {
    align-items: center;
    display: flex; }
    @media screen and (max-width: 767px) {
      .b-account_card-footer {
        flex-direction: column; } }
  .b-account_card-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 700;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    align-self: center;
    font-size: 16px; }
    .b-account_card-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_card-link:hover {
      text-decoration: none; }
    .b-account_card-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_card-link:visited {
      color: var(--color-link-visited, #202025); }
    .b-account_card-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_card-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_card-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-track_order {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 18px;
  letter-spacing: 0.2px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-track_order {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-track_order {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-track_order {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-track_order-inner {
    margin: 30px auto 50px auto;
    max-width: 680px; }
    @media screen and (min-width: 1200px) {
      .b-track_order-inner {
        margin: 90px auto 120px auto; } }
  .b-track_order-form {
    margin: 0 auto;
    max-width: 450px; }
    .b-track_order-form .b-form-btn_main {
      margin-top: 10px; }
  .b-track_order-note {
    text-align: center; }
  .b-track_order-sign_in {
    align-items: center;
    display: inline-flex;
    margin: 30px 0; }
  .b-track_order-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px; }
    .b-track_order-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-track_order-link:hover {
      text-decoration: none; }
    .b-track_order-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-track_order-link:visited {
      color: var(--color-link-visited, #202025); }
    .b-track_order-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-track_order-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-track_order-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-track_order-link:hover:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-track_order-link:hover:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-track_order .b-account_title {
    margin-bottom: 30px; }

.b-track_notification {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1308px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-track_notification {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-track_notification {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-track_notification {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-track_notification-inner {
    align-items: center;
    background: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 3px;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    padding: 15px 35px;
    text-align: center;
    background: rgba(246, 246, 245, 0.2);
    border-color: #F6F6F5;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin: 60px auto 30px; }
    @media screen and (max-width: 767px) {
      .b-track_notification-inner {
        padding: 20px; } }
    @media screen and (max-width: 767px) {
      .b-track_notification-inner {
        flex-direction: column;
        margin: 30px 0 20px;
        padding: 15px 20px; } }
  .b-track_notification-title {
    margin-right: 20px;
    padding-right: 20px;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap; }
    @media screen and (max-width: 767px) {
      .b-track_notification-title {
        margin: 5px 0 10px 0;
        padding-right: 0; } }
    .b-track_notification-title::after {
      background: #D2D2CE;
      content: '';
      height: 12px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px; }
      @media screen and (max-width: 767px) {
        .b-track_notification-title::after {
          display: none; } }
  .b-track_notification-message {
    font-weight: 400;
    letter-spacing: 0.2px;
    text-transform: none; }
  .b-track_notification-image {
    display: inline-block;
    padding: 0 10px; }
  .b-track_notification-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 700;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-track_notification-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-track_notification-link:hover {
      text-decoration: none; }
    .b-track_notification-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-track_notification-link:visited {
      color: var(--color-link-visited, #202025); }
    .b-track_notification-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-track_notification-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-track_notification-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-account_info_tile {
  border: 1px solid #D2D2CE;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
  min-height: 190px;
  padding: 25px; }
  @media screen and (max-width: 767px) {
    .b-account_info_tile {
      min-height: 145px; } }
  .b-account_info_tile-add_new {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    margin-top: 20px;
    text-transform: uppercase; }
    .b-account_info_tile-add_new:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-add_new:hover {
      text-decoration: none; }
    .b-account_info_tile-add_new.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-add_new:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-add_new:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_info_tile-add_new:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_info_tile-add_new.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    @media screen and (max-width: 767px) {
      .b-account_info_tile-add_new {
        margin-left: 20px;
        margin-top: 0; } }
  .b-account_info_tile-body {
    margin-bottom: 30px; }
  .b-account_info_tile-footer {
    align-items: baseline;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    order: 1; }
  .b-account_info_tile-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: auto; }
    .b-account_info_tile-title::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 20px;
      mask: url("./svg-icons/sprite.svg#checkmark");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 20px;
      display: inline-block;
      margin-right: 2px;
      vertical-align: text-top; }
  .b-account_info_tile-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    cursor: pointer;
    font-weight: 600; }
    .b-account_info_tile-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-link:hover {
      text-decoration: none; }
    .b-account_info_tile-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_info_tile-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_info_tile-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-link:visited {
      color: var(--color-link, inherit);
      cursor: pointer;
      display: inline;
      font-weight: 600;
      letter-spacing: 0.16px;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 90%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      padding-bottom: 7px; }
      .b-account_info_tile-link:visited:visited {
        color: var(--color-link-visited, #584C44); }
      .b-account_info_tile-link:visited:hover {
        text-decoration: none; }
      .b-account_info_tile-link:visited.m-disabled {
        color: var(--color-link-disabled, #D2D2CE);
        pointer-events: none; }
  .b-account_info_tile-link.m-edit {
    margin-right: 10px; }
  .b-account_info_tile-link.m-set_default {
    margin-right: auto; }
  .b-account_info_tile-action_text {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    font-size: 16px;
    margin-top: 15px; }
    .b-account_info_tile-action_text:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-action_text:hover {
      text-decoration: none; }
    .b-account_info_tile-action_text.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-account_info_tile-action_text:visited {
      color: var(--color-link-visited, #584C44); }
    .b-account_info_tile-action_text:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-account_info_tile-action_text:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-account_info_tile-action_text.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-account_info_tile-info {
    font-size: 16px;
    line-height: 24px; }
  .b-account_info_tile-info_row {
    align-items: center;
    display: flex; }
  .b-account_info_tile.m-ghost {
    align-items: center;
    background: linear-gradient(#fff, #fff) padding-box, repeating-linear-gradient(-45deg, #D2D2CE 0, #D2D2CE 25%, #fff 0, #fff 50%) 0 / 20px 20px;
    border: 1px solid transparent;
    color: #202025;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: background cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s; }
    .b-account_info_tile.m-ghost:hover {
      background: linear-gradient(#fff, #fff) padding-box, repeating-linear-gradient(-45deg, #F6F6F5 0, #F6F6F5 25%, #fff 0, #fff 50%) 0 / 20px 20px;
      text-decoration: none; }
  .b-account_info_tile.m-default {
    background: #F6F6F5;
    border: none; }
  .b-account_info_tile .b-payment_icon {
    margin: 0 5px 0 15px; }

.b-order_short_summary {
  display: flex; }
  @media screen and (max-width: 767px) {
    .b-order_short_summary {
      display: block; } }
  .b-order_short_summary-status {
    font-weight: 700; }
  .b-order_short_summary-list {
    flex: 1; }
  .b-order_short_summary-list + .b-order_short_summary-list {
    margin-left: 10px; }
    @media screen and (max-width: 767px) {
      .b-order_short_summary-list + .b-order_short_summary-list {
        margin: 10px 0 0 0; } }
  .b-order_short_summary-list_item {
    margin-bottom: 10px; }
  .b-order_short_summary-list_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-order_short_summary-list_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-order_short_summary-list_link:hover {
      text-decoration: none; }
    .b-order_short_summary-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-order_short_summary-list_link:visited {
      color: var(--color-link-visited, #202025); }
    .b-order_short_summary-list_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-order_short_summary-list_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-order_short_summary-list_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-order_info_card-header {
  border-bottom: 1px solid #D2D2CE;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 20px; }

.b-order_info_card-title {
  font: 400 28px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.35px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 500;
  letter-spacing: 0.35px; }
  @media screen and (max-width: 1199px) {
    .b-order_info_card-title {
      font-size: 24px;
      letter-spacing: 0.3px; } }

.b-order_history-find_order {
  margin-bottom: 40px; }

.b-order_history-filter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .b-order_history-filter {
      flex-wrap: wrap; } }

.b-order_history-total {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.1px;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .b-order_history-total {
      margin-bottom: 30px;
      text-align: center;
      width: 100%; } }

.b-order_history-recent {
  border-bottom: solid 1px;
  line-height: 32px;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600; }

@media screen and (max-width: 767px) {
  .b-order_history-main {
    margin-top: 30px; } }

.b-order_history-select_wrap {
  align-items: center;
  display: flex; }
  .b-order_history-select_wrap .f-input_select {
    position: relative; }
    .b-order_history-select_wrap .f-input_select::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 8px;
      mask: url("./svg-icons/sprite.svg#arrow-down");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 15px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%); }
    .b-order_history-select_wrap .f-input_select-field {
      appearance: none;
      background: transparent;
      border: none;
      border-radius: 0;
      min-width: 0;
      vertical-align: baseline;
      width: 100%;
      border: 1px solid #A7A8A8;
      border-radius: 0;
      color: #202025;
      font: 700 16px/22px "Proxima Nova", sans-serif;
      height: 46px;
      letter-spacing: 2.1px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      width: 100%;
      border-color: #A7A8A8;
      border-width: 0 0 1px;
      padding: 12px 15px 12px 0; }
      .b-order_history-select_wrap .f-input_select-field::placeholder {
        color: transparent;
        text-transform: capitalize; }
      .b-order_history-select_wrap .f-input_select-field:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #fff inset;
        -webkit-text-fill-color: inherit;
        transition-delay: 9999s;
        transition-property: all; }
      @media screen and (max-width: 767px) {
        .b-order_history-select_wrap .f-input_select-field {
          height: 56px; } }
      .b-order_history-select_wrap .f-input_select-field > option {
        background: #fff;
        color: #202025; }
    .b-order_history-select_wrap .f-input_select-field:disabled {
      border-color: #D2D2CE;
      color: #D2D2CE; }
      .b-order_history-select_wrap .f-input_select-field:disabled::before {
        color: #D2D2CE; }
  @media screen and (max-width: 767px) {
    .b-order_history-select_wrap {
      display: block;
      flex: 1; } }
  .b-order_history-select_wrap .f-input_select {
    flex-basis: 100%;
    min-width: 220px; }

.b-order_history-select_label {
  color: #202025;
  font-size: 16px;
  margin-right: 15px; }
  @media screen and (max-width: 767px) {
    .b-order_history-select_label {
      display: none; } }

.b-order_history-list {
  margin-bottom: 70px; }
  @media screen and (max-width: 767px) {
    .b-order_history-list {
      margin-bottom: 40px; } }

.b-order_history-footer {
  margin: 30px auto;
  max-width: 400px; }
  @media screen and (max-width: 767px) {
    .b-order_history-footer {
      margin-top: 50px; } }
  .b-order_history-footer .b-load_progress {
    margin-bottom: 20px; }

.b-order_history .b-account_card {
  border-bottom: 1px solid #D2D2CE;
  padding-bottom: 60px; }
  @media screen and (max-width: 767px) {
    .b-order_history .b-account_card {
      margin-bottom: 50px;
      padding-bottom: 50px; } }

.b-order_details {
  display: grid;
  grid-column-gap: 30px;
  grid-template: 'title' 'short-details' 'aside' 'product-summary ' / 1fr; }
  .b-order_details-top {
    grid-area: short-details; }
    .b-order_details-top .b-account_card {
      margin-bottom: 0;
      padding-bottom: 0; }
  .b-order_details-caption {
    font: 600 40px/56px "Proxima Nova", sans-serif;
    letter-spacing: 0.6px;
    grid-area: title;
    padding-bottom: 45px; }
    @media screen and (max-width: 1199px) {
      .b-order_details-caption {
        font-size: 40px;
        letter-spacing: 0.5px;
        line-height: 48px; } }
  .b-order_details-main {
    grid-area: product-summary;
    margin-bottom: 70px; }
    @media screen and (max-width: 767px) {
      .b-order_details-main {
        margin-bottom: 0; } }
  .b-order_details-aside {
    grid-area: aside; }
    .b-order_details-aside .b-order_info_card {
      margin: 30px 0 40px 0; }
  .b-order_details-track_order {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .b-order_details-track_order {
        margin-top: 30px; } }
  .b-order_details-track_order_btn {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0; }
    .b-order_details-track_order_btn:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-order_details-track_order_btn:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-order_details-track_order_btn:disabled:not(.m-processing), .b-order_details-track_order_btn.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-order_details-track_order_btn:disabled:not(.m-processing), .b-order_details-track_order_btn.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-order_details-track_order_btn.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-order_details-track_order_btn.m-width_s {
      width: var(--button-s); }
    .b-order_details-track_order_btn.m-width_m {
      width: var(--button-m); }
    .b-order_details-track_order_btn.m-width_l {
      width: var(--button-l); }
    .b-order_details-track_order_btn.m-processing {
      cursor: wait; }
    @media screen and (max-width: 767px) {
      .b-order_details-track_order_btn {
        width: 100%; } }
  .b-order_details.m-guest {
    margin-top: 30px; }
    .b-order_details.m-guest .b-cart_table.m-full_info .b-cart_table-head {
      grid-template-columns: 1fr 200px 150px 170px; }
    @media screen and (min-width: 1200px) {
      .b-order_details.m-guest .b-cart_product.m-full_info {
        grid-template: [start details-s image-s price-s qty-s total-s] auto [bundle-s details-e price-e qty-e total-e] minmax(0, 1fr) [bundle-e image-e message-s] auto [end message-e]/[start image-s message-s] 115px [image-e bundle-s details-s] minmax(0, 1fr) [details-e price-s] 200px [price-e qty-s] 150px [bundle-e qty-e total-s] 170px [end total-e message-e]; } }
  @media screen and (min-width: 1200px) {
    .b-order_details.m-guest .b-order_details-top {
      display: flex;
      justify-content: space-between; } }
  .b-order_details.m-guest .b-order_details-top .b-account_card {
    flex: 1; }
  .b-order_details.m-guest .b-order_details-caption {
    text-align: center; }
  @media screen and (min-width: 1200px) {
    .b-order_details.m-guest .b-order_details-aside {
      max-width: 65%; } }

.b-summary_table {
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 1.75;
  text-align: left;
  width: 100%; }
  .b-summary_table-name, .b-summary_table-value {
    font-weight: inherit;
    padding: 5px 0; }
  .b-summary_table-value {
    padding-left: 5px;
    text-align: right;
    white-space: nowrap; }
  .b-summary_table-item {
    display: flex;
    justify-content: space-between; }
    .b-summary_table-item.m-discount {
      color: #BA8D36; }
    .b-summary_table-item.m-total {
      border-top: 1px solid rgba(210, 210, 206, 0.8);
      font-size: 18px;
      font-weight: 700;
      margin-top: 15px; }
  .b-summary_table-item.m-total .b-summary_table-name,
  .b-summary_table-item.m-total .b-summary_table-value {
    padding: 20px 0 0; }
  .b-summary_table-cellar {
    border-top: 1px solid;
    font-size: 16px;
    letter-spacing: 0.15px;
    padding-bottom: 8px;
    padding-top: 16px; }
  .b-summary_table-address {
    color: #A7A8A8;
    font-size: 12px;
    letter-spacing: 0.12px; }

.b-order_summary {
  border: 1px solid #D2D2CE; }
  .b-order_summary ~ .b-order_summary {
    margin-top: 50px; }
  .b-order_summary-header {
    border-bottom: 1px solid rgba(210, 210, 206, 0.8);
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    padding: 20px 25px 15px; }
  .b-order_summary-title {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase; }
  .b-order_summary-edit_button {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    align-items: center;
    display: inline-flex;
    letter-spacing: 2.1px;
    text-decoration: none;
    text-transform: uppercase; }
    .b-order_summary-edit_button:hover {
      text-decoration: none; }
    .b-order_summary-edit_button:visited {
      color: var(--color-link-visited, #584C44); }
    .b-order_summary-edit_button::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 1em;
      mask: url("./svg-icons/sprite.svg#edit");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 1em;
      display: inline-block;
      margin-right: 5px; }
    .b-order_summary-edit_button svg {
      display: inline-block;
      height: var(--link-icon_height, 8px);
      margin-right: 5px;
      width: var(--link-icon_width, 8px); }
  .b-order_summary-content {
    padding: 20px 25px; }
  .b-order_summary-shipping, .b-order_summary-totals, .b-order_summary-cta_wrap {
    margin-bottom: 25px; }
  .b-order_summary .apple-pay-message {
    text-align: center;
    margin-bottom: 15px; }
  .b-order_summary.m-cart_summary {
    border-width: 0 0 1px; }
  .b-order_summary.m-cart_summary .b-order_summary-header,
  .b-order_summary.m-cart_summary .b-order_summary-content {
    padding-left: 0;
    padding-right: 0; }
  .b-order_summary.m-cart_summary .b-order_summary-content {
    padding: 0; }
    @media screen and (min-width: 1200px) {
      .b-order_summary.m-cart_summary .b-order_summary-content {
        max-height: 620px;
        overflow: auto; } }
  .b-order_summary-apple_pay.hide-apple_pay {
    display: none; }
  .b-order_summary-apple_pay_format_button {
    background: var(--button_main-background_color, #fff); }

.b-cart_table-head {
  color: #202025;
  display: grid;
  font-size: 16px;
  grid-gap: 45px;
  grid-template-columns: 1fr 135px 152px;
  line-height: 26px;
  padding: 25px 0 10px;
  padding-top: 0;
  margin-top: -36px; }
  @media screen and (max-width: 1199px) {
    .b-cart_table-head {
      display: none;
      margin-bottom: 0; } }

.b-cart_table-body {
  border-top: 1px solid rgba(210, 210, 206, 0.8);
  padding-top: 30px; }

.b-cart_table .b-cart_product {
  border-bottom: 1px solid rgba(210, 210, 206, 0.8);
  padding-bottom: 30px;
  margin-bottom: 30px; }

.b-cart_table .b-message {
  margin-bottom: 20px; }

.b-cart_table.m-full_info .b-cart_table-head {
  grid-gap: 20px;
  grid-template-columns: 1fr 110px 70px 170px; }
  @media screen and (max-width: 1199px) {
    .b-cart_table.m-full_info .b-cart_table-head {
      display: block; } }

.b-cart_table.m-full_info .b-cart_table-qty,
.b-cart_table.m-full_info .b-cart_table-price,
.b-cart_table.m-full_info .b-cart_table-total {
  text-align: center; }
  @media screen and (max-width: 1199px) {
    .b-cart_table.m-full_info .b-cart_table-qty,
    .b-cart_table.m-full_info .b-cart_table-price,
    .b-cart_table.m-full_info .b-cart_table-total {
      display: none; } }

.b-cart_table.m-full_info .b-cart_table-total {
  text-align: right; }

.m-errortile {
  background: none;
  font-size: 14px;
  text-align: left;
  width: 600px;
  padding: 14px 21px !important;
  border: none !important;
  color: red !important; }

.b-cart_product {
  font: 16px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  align-items: start;
  display: grid;
  grid-template: [start details-s image-s qty-s total-s remove-s] auto [bundle-s details-e qty-e total-e] minmax(0, 1fr) [end bundle-e image-e remove-e]/[start image-s] 165px [image-e] 20px [bundle-s details-s] minmax(0, 1fr) [details-e] 45px [qty-s] 135px [bundle-e qty-e] 45px [total-s] 130px [remove-s total-e] 22px [end remove-e]; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-cart_product {
      grid-template: [start details-s image-s remove-s] auto [details-e qty-s total-s] auto [bundle-s qty-e total-e] minmax(0, 1fr) [end bundle-e image-e remove-e]/[start image-s] 100px [image-e] 20px [bundle-s details-s qty-s] 135px [qty-e total-s] minmax(0, 1fr) [bundle-e details-e total-e] 20px [remove-s] 22px [end remove-e]; } }
  @media screen and (max-width: 767px) {
    .b-cart_product {
      grid-template: [start image-s details-s remove-s] auto [details-e qty-s] auto [qty-e total-s] auto [bundle-s total-e] auto [end bundle-e image-e remove-e]/[start image-s] 100px [image-e] 15px [bundle-s details-s qty-s total-s] minmax(0, 1fr) [bundle-e details-e qty-e total-e] 15px [remove-s] 22px [end remove-e]; } }
  .b-cart_product-price_column {
    grid-area: price-s/price-s/price-e/price-e; }
  .b-cart_product-image {
    grid-area: image-s/image-s/image-e/image-e; }
  .b-cart_product-image_link {
    display: block; }
  .b-cart_product-picture {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
    .b-cart_product-picture img {
      bottom: 0;
      color: #252525;
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  .b-cart_product-details {
    grid-area: details-s/details-s/details-e/details-e; }
    @media screen and (max-width: 1199px) {
      .b-cart_product-details {
        margin-bottom: 20px; } }
  .b-cart_product-qty {
    display: flex;
    grid-area: qty-s/qty-s/qty-e/qty-e; }
    @media screen and (max-width: 767px) {
      .b-cart_product-qty {
        margin-bottom: 20px; } }
    .b-cart_product-qty.m-preselected {
      margin-top: 20px; }
      @media screen and (min-width: 1441px) {
        .b-cart_product-qty.m-preselected {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .b-cart_product-qty.m-preselected {
          margin-top: 0; } }
    .b-cart_product-qty .b-product_quantity {
      width: 135px; }
  .b-cart_product-qty_value {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; }
  .b-cart_product-total {
    grid-area: total-s/total-s/total-e/total-e; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-cart_product-total {
        align-self: center;
        margin-left: 20px; } }
    @media screen and (max-width: 1199px) {
      .b-cart_product-total {
        align-items: baseline;
        display: flex; } }
    @media screen and (min-width: 1200px) {
      .b-cart_product-total .b-product_price {
        font-size: 18px; } }
  .b-cart_product-total_label {
    display: none; }
    @media screen and (max-width: 1199px) {
      .b-cart_product-total_label {
        display: block;
        margin-right: 5px; } }
  .b-cart_product-remove {
    grid-area: remove-s/remove-s/remove-e/remove-e; }
  @media screen and (min-width: 1200px) {
    .b-cart_product-total, .b-cart_product-remove {
      align-items: center;
      display: flex;
      height: 60px; } }
  .b-cart_product-bundle {
    grid-area: bundle-s/bundle-s/bundle-e/bundle-e;
    margin-top: 15px; }
  .b-cart_product-bundle_control {
    align-items: center;
    cursor: pointer;
    display: flex; }
    .b-cart_product-bundle_control::after {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 6px;
      mask: url("./svg-icons/sprite.svg#arrow-down"), url("./svg-icons/sprite.svg#arrow-up");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%, 0;
      width: 10px; }
    .b-cart_product-bundle_control[aria-expanded='true']::after {
      mask-size: 0, 100%; }
  .b-cart_product-bundle_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    margin-right: 10px; }
    .b-cart_product-bundle_link:hover {
      text-decoration: none; }
  .b-cart_product-bundle_items {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    transition-property: height, opacity;
    visibility: hidden; }
    .b-cart_product-bundle_items[aria-hidden='false'] {
      height: auto;
      opacity: 1;
      visibility: visible;
      overflow: visible; }
  .b-cart_product-error {
    align-items: center;
    background: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 3px;
    color: #202025;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    padding: 15px 35px;
    text-align: center;
    background: #FAEDED;
    border: none;
    color: #CA1E36;
    grid-area: message-s/message-s/message-e/message-e;
    margin-top: 30px; }
    @media screen and (max-width: 767px) {
      .b-cart_product-error {
        padding: 20px; } }
  .b-cart_product-error_accent_text {
    font-weight: 700; }
  .b-cart_product .b-cart_bundle {
    margin-top: 30px; }
  .b-cart_product.m-full_info {
    column-gap: 20px;
    grid-template: [start details-s image-s price-s qty-s total-s] auto [bundle-s details-e price-e qty-e total-e] minmax(0, 1fr) [bundle-e image-e messsage-s] auto [end message-e]/[start image-s message-s] 115px [image-e bundle-s details-s] minmax(0, 1fr) [details-e price-s] 110px [price-e qty-s] 70px [bundle-e qty-e total-s] 170px [end total-e message-e]; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-cart_product.m-full_info {
        grid-template: [start details-s image-s] auto [details-e] auto [price-s] auto [price-e total-s] auto [bundle-s total-e] minmax(0, 1fr) [bundle-e image-e message-s] auto [end message-e]/[start image-s message-s] 100px [image-e bundle-s details-s price-s total-s] minmax(0, 1fr) [end bundle-e price-e details-e total-e message-e]; } }
    @media screen and (max-width: 767px) {
      .b-cart_product.m-full_info {
        column-gap: 15px;
        grid-template: [start details-s image-s] auto [details-e] auto [price-s] auto [price-e total-s] auto [bundle-s total-e] minmax(0, 1fr) [bundle-e image-e message-s] auto [end message-e]/[start image-s message-s] 100px [image-e bundle-s details-s price-s qty-s total-s] minmax(0, 1fr) [end bundle-e details-e price-e qty-e total-e message-e]; } }
  .b-cart_product.m-full_info .b-cart_product-qty {
    justify-content: center; }
    @media screen and (max-width: 1199px) {
      .b-cart_product.m-full_info .b-cart_product-qty {
        display: none; } }
  @media screen and (max-width: 1199px) {
    .b-cart_product.m-full_info .b-cart_product-price_column {
      display: none; } }
  .b-cart_product.m-full_info .b-cart_product-price_column .b-product_price {
    font-size: 16px;
    justify-content: center; }
  .b-cart_product.m-full_info .b-cart_product-total {
    height: auto;
    justify-content: flex-end; }
    @media screen and (max-width: 1199px) {
      .b-cart_product.m-full_info .b-cart_product-total {
        justify-content: flex-start;
        margin: 0; } }
    .b-cart_product.m-full_info .b-cart_product-total .b-product_price {
      justify-content: flex-end; }
  @media screen and (max-width: 1199px) {
    .b-cart_product.m-full_info .b-cart_product_details-attributes {
      font-size: 14px; } }
  .b-cart_product.m-full_info .b-minicart_product-subtotal_price {
    justify-content: flex-end; }

.b-cart_product_details-name {
  font: 500 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
  text-transform: capitalize; }

.b-cart_product_details-sku {
  color: #202025;
  font: 14px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  margin-top: 5px; }

.b-cart_product_details-attributes {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 1.5; }

.b-cart_product_details-attribute {
  align-items: center;
  display: flex; }
  .b-cart_product_details-attribute::after {
    background: #D2D2CE;
    content: '';
    height: 12px;
    margin: 0 10px;
    width: 1px; }
  .b-cart_product_details-attribute:last-child::after {
    content: none; }
  .b-cart_product_details-attribute.m-qty {
    text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .b-cart_product_details-attribute.m-qty {
        display: none; } }

@media screen and (min-width: 1200px) {
  .b-cart_product_details-attributes.m-with_qty .b-cart_product_details-attribute:nth-last-child(2)::after {
    content: none; } }

.b-cart_product_details-qty {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px; }

.b-cart_product_details-qty_value {
  font-weight: bold; }

.b-cart_product_details-price, .b-cart_product_details-attributes,
.b-cart_product_details .b-product_promo,
.b-cart_product_details .b-product_availability {
  margin-top: 15px; }

.b-cart_product_details .b-product_promo {
  width: fit-content; }

.b-cart_product_details .b-product_availability {
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 1.5; }
  .b-cart_product_details .b-product_availability::before {
    margin-right: 10px; }

.b-cart_product_details .b-product_actions-description {
  font-size: 14px; }

.b-cart_product_details .b-product_actions-description_date {
  font-weight: 700; }

.b-cart_bundle {
  display: grid;
  gap: 20px;
  grid-template-areas: 'image details';
  grid-template-columns: 120px 1fr; }
  @media screen and (max-width: 1199px) {
    .b-cart_bundle {
      grid-template-columns: 75px 1fr; } }
  @media screen and (max-width: 767px) {
    .b-cart_bundle {
      gap: 15px; } }
  .b-cart_bundle-image {
    grid-area: image; }
  .b-cart_bundle-image_link {
    display: block; }
  .b-cart_bundle-picture {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
    .b-cart_bundle-picture img {
      bottom: 0;
      color: #252525;
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  .b-cart_bundle-details {
    grid-area: details; }

.b-summary_address {
  font: 14px/1.57 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px; }
  .b-summary_address-default {
    align-items: center;
    display: flex;
    font-weight: 600;
    margin-top: 20px; }
    .b-summary_address-default::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 13px;
      mask: url("./svg-icons/sprite.svg#checkmark");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 13px;
      margin-right: 5px; }

.b-summary_payment {
  font: 14px/1.57 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  margin-bottom: 20px; }
  .b-summary_payment .b-payment_icon {
    margin: 0 5px; }
  .b-summary_payment-number {
    display: flex; }
  .b-summary_payment-type {
    text-transform: capitalize; }
  .b-summary_payment .b-payment_methods {
    margin-left: 60px; }

.b-summary_group {
  display: grid;
  gap: 0 20px;
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 767px) {
    .b-summary_group {
      gap: 20px;
      grid-template-columns: 1fr; } }
  .b-summary_group-title {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-bottom: 15px; }
  .b-summary_group-item.m-gift {
    word-break: break-word; }
  .b-summary_group .b-expected_ship_date {
    grid-area: 2 / 2; }
    @media screen and (max-width: 767px) {
      .b-summary_group .b-expected_ship_date {
        grid-area: 3 / 1; } }

@media screen and (min-width: 1200px) {
  .b-checkout_products {
    padding-right: 5px; } }

.b-checkout_products .b-minicart_product {
  padding-bottom: 25px; }
  .b-checkout_products .b-minicart_product-details .b-minicart_product-subtotal .b-product_price .b-product_price-value span {
    display: none; }

.b-checkout_products .b-minicart_product:first-child {
  margin-top: 25px; }

.b-checkout_products .b-minicart_product + .b-minicart_product {
  border-top: 1px solid #D2D2CE;
  padding-top: 25px; }

.b-payment_icon {
  color: #A7A8A8;
  display: inline-block;
  flex-shrink: 0;
  height: 22px;
  width: 32px; }

body {
  opacity: var(--page_opacity, 0);
  overflow: auto;
  overflow-y: scroll;
  pointer-events: all;
  visibility: var(--page_visibility, hidden); }

@media screen and (min-width: 768px) {
  .cancelOrder-modal.b-dialog.m-quick_view .b-dialog-window {
    width: 40%; }
  .cancelOrder-modal .renderTemplate {
    margin-left: 15%; } }

.cancelOrder-modal.b-dialog.m-quick_view .b-dialog-window {
  top: 10%;
  width: 90%; }

.cancelOrder-modal .b-dialog-close {
  position: absolute;
  right: 40px;
  top: 40px; }

.cancelOrder-modal .b-dialog-body {
  text-align: left; }
  .cancelOrder-modal .b-dialog-body .cancel-order-FAQ {
    display: inline-block; }
  .cancelOrder-modal .b-dialog-body .cancelOrder-question {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px; }
    .cancelOrder-modal .b-dialog-body .cancelOrder-question a {
      cursor: pointer;
      font-family: "Proxima Nova", sans-serif;
      padding: 0 5px;
      text-decoration: underline; }
  .cancelOrder-modal .b-dialog-body .cancelOrder-title {
    color: #202025;
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.23px;
    line-height: 32px;
    margin: 108px 0 8px; }
  .cancelOrder-modal .b-dialog-body select#cancelreason {
    border: 2px solid #202025;
    color: #0c0c0c;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    height: 56px;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin: 8px 0;
    width: 100%; }
  .cancelOrder-modal .b-dialog-body .b-form-btn_alt {
    margin: 8px 0px; }
  .cancelOrder-modal .b-dialog-body .b-form-btn_main {
    background-color: #202025;
    color: #fff;
    margin: 8px 0; }
  .cancelOrder-modal .b-dialog-body .cancelOrderfailure-title {
    color: #202025;
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.23px;
    line-height: 32px;
    margin: 108px 0 24px; }
  .cancelOrder-modal .b-dialog-body .cancelOrderfailure-msg {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin: 12px 0 41px; }
    .cancelOrder-modal .b-dialog-body .cancelOrderfailure-msg a {
      cursor: pointer;
      font-family: "Proxima Nova", sans-serif;
      padding: 0 4px;
      text-decoration: underline; }

@media screen and (min-width: 768px) {
  .b-order_short_summary-list .cancelorder-modal {
    text-align: right; } }

.b-order_short_summary-list .cancelorder-modal {
  text-align: left; }

.cancelinfotime-msg {
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  letter-spacing: 0.15px;
  margin-top: 8px; }

@media screen and (max-width: 1199px) {
  .b-account_card .cancelinfotime-msg {
    text-align: center; }
  .b-account_card .cancelorder-modal .cancelinfotime-msg {
    text-align: left; } }

.b-account_card-defaultaddress svg {
  height: 12px;
  width: 17px; }

.b-order_short_summary-status-deleted {
  color: #CA1E36;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: bold; }

.deleted {
  color: #CA1E36; }

/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS, which puts the properties and values you are changing right next
to each other.

That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in another different file.

## Configuration

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1200px)',
	lg-down: 'screen and (max-width: 1440px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Rh (Indents rhythm)

Initial assumption is that Designs should follow the agreed Rhythm.

The main puprose of Rhythm function is to soothe inconsistencies of design for vertical and horizontal indents if such appear.

If we have agreed on Rhythm = 5 and we meet padding or margin 22px in design - we should round it to rh(4)=20px

Rhythm function can be avoided if some measurements are specific, i.e.:
	-icon size, blocks width/height are specific values
	-form elements paddings/margins/sizes are usually specific values
	-positioning of elements might have nothing to do with parent paddings

## Usage

```scss
.component {
	@include rh(2); // => 10px
	@include rh(2 4 0); // => 10px 20px 0
}
```
*/
/* stylelint-disable */
/* stylelint-enable */
/*md
@no-stat

# Z-indexes

This is map of z-indexes used on project. To easily work with z-indexes and avoid many issues related to this,
you need to not use z-index directly, but instead use z() function to get z-index value from this map.

## Usage

### Global components

```scss
// settings.scss
$z-indexes: (
	content: (),
	popup-menu: ()
);

// component.scss
.b-pop_up-menu {
	//...
	z-index: z(popup-menu);
}
```

### Inside a component

```scss
// settings.scss
$z-indexes: (
	components: (
		component_name: (),
		checkbox: (
			before: (),
			after: (),
			icon: (),
		)
	),
	content: (),
	popup-menu: ()
);

// component.scss
.component_name {
	position: relative;
	z-index: z(components, component_name);
}

// checkbox.scss
.checkbox {
	&-icon {
		z-index: z(components, checkbox, icon);
	}
}
```

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 56.25%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

Second parameter is for the `!important` keyword.

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
/*md
@no-stat

# Lines clamp

This mixin is useful for adding ... for text with more than 1 line

## Usage

```scss
.component {
	@include line-clamp;
}
```
*/
/*md
@no-stat

# Lines clamp with lines reserve

This mixin is useful for adding ... for text with more than 1 line and reserve space

## Usage

```scss
.component {
	@include lines-clamp-reserve;
}
```
*/
/* stylelint-disable
	property-no-vendor-prefix,
	property-no-unknown
*/
/*md
@no-stat

# Force repaint

This mixin is useful when repaint needs to be triggered. (sometimes on iOS)

## Usage

```scss
.component {
	@include force_repaint;
}
```
*/
@keyframes repaint {
  from {
    padding-bottom: 0.01px; }
  to {
    padding-bottom: 0; } }

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behaviour of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# Snap scroll

CSS snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: `.b-carousel`, `.b-product_gallery`, `.b-product_slider` etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

```scss_example
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container. It could be used not only for standalone blocks
but for page layouts also.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# Headings components

```scss_example
.b-title {
	@include t-heading_1;
}
```

*/
/*md

# Core component for all links

All core and common links styling
stores in this mixin

Be careful changing styles in this mixin!

```scss_example
@mixin t-link_6 {
	@include t-link;
}
```

*/
/*md

# Underlined link component

It extends and modifies `t-link` component
and is a core component for underlined links

```scss_example
@mixin t-link_1 {
	@include t-link_underlined;

	...
}
```
*/
@keyframes link-underline {
  0% {
    background-size: 0 1px; }
  100% {
    background-size: 100% 1px; } }

/*md

# Link type 1

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_1;
}
```
*/
/*md

# Link type 2

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component
```scss_example
.b-some_link {
	@include t-link_2;
}
```
*/
/*md

# Link type 3

Link followed with the arrow.
It extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_3;
}
```
*/
@keyframes link-arrow {
  0% {
    width: 5px; }
  100% {
    width: 50px; } }

/*md

# Link type 4

Link with an icon.
It inherits and extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_4;
}
```

Mixin accepts following parameters:
- `$icon` (icon name)
- `$icon-height` (1em by default)
- `$icon-width` (1em by default)

*/
/*md

# Link type 5

Another variation of an underlined link

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_5;
}
```
*/
/*md

# Paragraph components

```scss_example
.b-cart_empty {
	// ...

	&-paragraph {
		@include t-paragraph_1;
	}
}
```

*/
/*md

# List component

Component for styling ordered or unordered lists

List items are also styled.

```scss_example
.b-tips {
	@include t-list;
}

.b-tips {
	@include t-list(ordered);
}
```

*/
/*md

# g-promo*

Mixin for promotion.

```scss_example
.b-cart_promo {
	@include g-promo();

}
```

*/
/*md

# g-button_reset*

Mixin for reset button style.

```scss_example
.b-button {
	@include g-button_reset;
}

```

*/
/*md

# g-button_*

# Mixin for button global styles.

```scss_example
.b-button {
	@include g-button_global($_mod: default);
}
```

*/
/*md

# g-button_main*

# Mixin for main button.

```scss_example
.b-button {
	@include g-button_main($_mod: default);
}
```
*/
/*md

# g-button_alt*

# Mixin for alt button.

```scss_example
.b-button {
	@include g-button_alt;
}
```
*/
/*md

# f-checkbox

Default checkbox element

## Default

```scss_example
	.b-input_checkbox {
		@include f-checkbox($el: root, $mod: default);
	}
```
*/
/*
# Input - type date

Default input element

```scss_example
	.b-some_context_component {
		@include f-input_date($style: default);
	}
```
*/
/*md

# f-input_password

Default input element

## Default input

```scss_example
	.f-input_password {
		@include f-input_password($style: default);
	}
```
*/
/*md

# f-input_reset

Default reset input element

## Default

```scss_example
	.b-input {
		@include f-input_reset;
	}
```
*/
/*md

# f-input_text

Default input element

## Default input

```scss_example
	.f-input_text {
		@include f-input_text($style: default);
	}
```

##Alt input

```scss_example
	.f-input_text {
		@include f-input_text($_mod: alt);
	}
```
*/
/*md

# f-label

Default label element

## Default label

```scss_example
	.f-input_text-label {
		@include f-label($style: default);
	}
```
*/
/*md

# f-radio

Default radio element

## Default

```scss_example
	.f-input_radio {
		@include f-radio;
	}
```
*/
/*md

# f-select

Default label element

## Default

```scss_example
	.f-input_select {
		@include f-select;
	}
```
*/
/*md

# f-textarea

Default textarea element

## Default

```scss_example
	.f-input_textarea {
		@include f-textarea;
	}
```
*/
/*md
# Carousel component
This carousel component is based on a simple built-in scroll functionality.

All layout, sizing, positioning is also controlled in CSS or with the help of HTML attributes.

## Carousel layout options - Number of slides per view

There are two types of configuration:
- static number of slides
- static slide's width

### Static number of slides

To define number of slides per view you can use a CSS custom properties or HTML attributes

#### CSS custom properties
`--carousel-slides_number` and `--carousel-number_of_gaps_per_view`.

We need `--carousel-number_of_gaps_per_view` for accurate calculations of slide's width.

Formulas for these calculations are defined in `g-carousel` mixin.

Default values for these properies are:

```
--carousel-slides_number: 1;
--carousel-number_of_gaps_per_view: 0;
```

```scss_example
.b-custom_carousel {
	@include g-carousel;

	--carousel-slides_number: 3;
	--carousel-number_of_gaps_per_view: 2;
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-number` where you
can define viewport names which have static slides number configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure exact number of slides for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-number_of_gaps_per_view-sm` and `--carousel-slides_number-sm` for Small
- `--carousel-number_of_gaps_per_view-md` and `--carousel-slides_number-md` for Medium
- `--carousel-number_of_gaps_per_view-lg` and `--carousel-slides_number-lg` for Large
- `--carousel-number_of_gaps_per_view-xl` and `--carousel-slides_number-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-number="sm lg"
	style="
		--carousel-number_of_gaps_per_view-sm: 1; --carousel-slides_number-sm: 2;
		--carousel-number_of_gaps_per_view-lg: 3; --carousel-slides_number-lg: 3.5;
	"
>...</section>
```

### Dynamic number of slides

The opposite to the static number of slides is a dynamic number of slides.

You should use it when you don't need the exact number of slides per view
but rather slides width. Visually it will behave this way: when you stretch
viewport size number of slides will increase but the slide's width won't change.

To define slides width you can use CSS custom property or HTML attributes

#### CSS custom properties

There's just one CSS custom property you need: `--carousel-slides-width`

```scss_example
.b-custom_slider {
	--carousel-slides-width: 300px;

	@include media(sm) {
		--carousel-slides-width: 150px;
	}
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-width` where you
can define viewport names which have static slides width configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure a slides width for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-slides_width-sm` for Small
- `--carousel-slides_width-md` for Medium
- `--carousel-slides_width-lg` for Large
- `--carousel-slides_width-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-width="md xl"
	style="
		--carousel-slides_width-md: 250px;
		--carousel-slides_width-xl: 350px;
	"
>...</section>
```

## Carousel layout options - Gutter value between slides

Gap between slides is controlled via CSS custom
property: `--carousel-gutter`

It has the default value: `--carousel-gutter: 0`

## Carousel layout options - Number of rows

Number of rows is controlled via CSS custom
property: `--carousel-rows_number`

It has the default value: `--carousel-rows_number: 1`

*/
/*md

# g-separator

Separator

```scss_example
	b-form {
		@include g-separator;

		// ...
	}
}
```
*/
/*md

# g-accordion

Accordion styles that used in several component.

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss_example
.g-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(item);
	}

	&-title {
		@include g-accordion(control);
	}

	&-content {
		@include g-accordion(content);

		&[aria-hidden='false'] {
			@include g-accordion(content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(content_inner);
	}
}
```

*/
/*md

# g-message*

Mixin for message tile.

```scss_example
.b-message {
	@include g-message;
}
```

*/
@media print {
  @page {
    margin: 10px 10px auto;
    padding: 20px 0;
    size: a4; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid; }
  .b-hide_print {
    display: none !important; }
  .b-header,
  .b-menu_panel,
  .b-footer {
    display: none; } }

html {
  background: #fff;
  color: #202025;
  direction: ltr;
  font: 14px/1.5 "Proxima Nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  box-sizing: border-box;
  margin: 0;
  min-width: 310px;
  overflow-y: scroll;
  padding: 0.01px 0 0; }

a {
  color: inherit;
  text-decoration: none; }
  @media not all and (pointer: coarse) {
    a:hover {
      text-decoration: underline; } }

button {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0; }

img {
  overflow: hidden; }

:target::before {
  content: '';
  display: block;
  height: 80px;
  margin-top: -80px;
  visibility: hidden; }

/* stylelint-disable selector-no-qualifying-type */
body {
  --grid-gutter: 15px;
  --grid-margin: 80px;
  --grid-columns: 12;
  padding: var(--header-height) 0 0; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    body {
      --grid-gutter: 15px;
      --grid-margin: 60px;
      --grid-columns: 12; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    body {
      --grid-gutter: 15px;
      --grid-margin: 15px;
      --grid-columns: 12; } }
  @media screen and (max-width: 767px) {
    body {
      --grid-gutter: 15px;
      --grid-margin: 15px;
      --grid-columns: 6; } }
  body.m-transparent_header {
    padding: 0; }
  body.m-panel_opened {
    overflow: hidden; }

html.m-header-hamburger_opened, html.m-age_gate-open {
  height: 100vh;
  overflow: hidden; }

html.m-age_gate-open .b-skip_to,
html.m-age_gate-open .b-header,
html.m-age_gate-open .l-page-content,
html.m-age_gate-open .l-page-footer,
html.m-age_gate-open .b-back_to_top {
  display: none; }

html:not(.m-age_gate-open) .b-age_gate {
  display: none; }

q {
  quotes: '\201c' '\201d'; }

/* stylelint-enable selector-no-qualifying-type */
@media screen and (max-width: 1199px) {
  .l-page.m-has_dialog .b-header-navigation_inner,
  .l-page.m-panel_opened .b-header-navigation_inner {
    left: -100%;
    opacity: 1;
    overflow: visible; } }

@media screen and (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 4px; }
  ::-webkit-scrollbar-track {
    background-color: transparent; }
  ::-webkit-scrollbar-thumb {
    background: #ababab;
    border-radius: 3px; } }

.h-layout-grid_width {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .h-layout-grid_width {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .h-layout-grid_width {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .h-layout-grid_width {
      padding-left: 15px;
      padding-right: 15px; } }

.h-text-align_center {
  text-align: center; }

/* stylelint-disable declaration-no-important */
@media screen and (max-width: 480px) {
  .h-hide-xs {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .h-hide-sm {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .h-hide-md {
    display: none !important; } }

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .h-hide-md_wide {
    display: none !important; } }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .h-hide-lg {
    display: none !important; } }

@media screen and (min-width: 1441px) {
  .h-hide-xl {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .h-hide-md_up {
    display: none !important; } }

@media screen and (max-width: 1199px) {
  .h-hide-md_down {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .h-hide-lg_up {
    display: none !important; } }

@media screen and (max-width: 1440px) {
  .h-hide-lg_down {
    display: none !important; } }

.h-hide-text {
  font-size: 0; }

.h-hidden {
  display: none !important; }

.h-hide_visually {
  border: 0 ;
  clip: rect(1px, 1px, 1px, 1px) ;
  margin: 0 ;
  max-height: 1px ;
  max-width: 1px ;
  overflow: hidden ;
  padding: 0 ;
  position: absolute ; }

@media not screen and (max-width: 480px) {
  .h-show_only-xs {
    display: none !important; } }

@media not screen and (max-width: 767px) {
  .h-show_only-sm {
    display: none !important; } }

@media not screen and (min-width: 768px) and (max-width: 1199px) {
  .h-show_only-md {
    display: none !important; } }

@media not screen and (min-width: 1024px) and (max-width: 1199px) {
  .h-show_only-md_wide {
    display: none !important; } }

@media not screen and (min-width: 1200px) and (max-width: 1440px) {
  .h-show_only-lg {
    display: none !important; } }

@media not screen and (min-width: 1441px) {
  .h-show_only-xl {
    display: none !important; } }

@media not screen and (min-width: 768px) {
  .h-show_only-md_up {
    display: none !important; } }

@media not screen and (max-width: 1199px) {
  .h-show_only-md_down {
    display: none !important; } }

@media not screen and (min-width: 1200px) {
  .h-show_only-lg_up {
    display: none !important; } }

@media not screen and (max-width: 1440px) {
  .h-show_only-lg_down {
    display: none !important; } }

/* stylelint-enable declaration-no-important */
/*md

# Normalize forms

This package address differences of default styling through all major browsers.

Best practice not include this package *globally* until we use HTML-tags for UI components.

This styles are based on N.Galaher [normalize.css](https://necolas.github.io/normalize.css/)

*/
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

input[type=button], input[type=submit], input[type=reset] {
  -webkit-appearance: button; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=checkbox] {
  vertical-align: baseline; }

button[disabled],
input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: none;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: none !important; }

input::-webkit-inner-spin-button {
  display: none; }

input::-ms-clear {
  display: none; }

/* stylelint-disable selector-max-universal */
*,
*::before,
*::after {
  box-sizing: inherit; }

/* stylelint-enable */
a {
  background-color: transparent; }

ul,
ol,
p {
  list-style: none;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0; }

figure {
  margin: 0; }

img {
  border: none;
  height: auto;
  max-width: 100%; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

button,
input,
select,
textarea {
  color: inherit;
  font: inherit; }

input::-ms-reveal,
input::-ms-clear {
  display: none; }

[hidden] {
  display: none !important; }

body {
  --xs: 10px;
  --xxs: 5px;
  --zero: 0; }
  @media screen and (min-width: 1200px) {
    body {
      --xxxl: 140px; } }
  @media screen and (max-width: 1199px) {
    body {
      --xxxl: 100px; } }
  @media screen and (min-width: 1200px) {
    body {
      --xxl: 90px; } }
  @media screen and (max-width: 1199px) {
    body {
      --xxl: 70px; } }
  @media screen and (min-width: 1200px) {
    body {
      --xl: 60px; } }
  @media screen and (max-width: 1199px) {
    body {
      --xl: 50px; } }
  @media screen and (min-width: 1200px) {
    body {
      --l: 40px; } }
  @media screen and (max-width: 1199px) {
    body {
      --l: 35px; } }
  @media screen and (min-width: 1200px) {
    body {
      --m: 30px; } }
  @media screen and (max-width: 1199px) {
    body {
      --m: 25px; } }
  @media screen and (min-width: 768px) {
    body {
      --s: 20px; } }
  @media screen and (max-width: 767px) {
    body {
      --s: 15px; } }

@media screen and (min-width: 1200px) {
  body {
    --button-l: 370px; } }

@media screen and (min-width: 768px) and (max-width: 1199px) {
  body {
    --button-l: 320px; } }

@media screen and (max-width: 767px) {
  body {
    --button-l: 100%; } }

@media screen and (min-width: 1200px) {
  body {
    --button-m: 300px; } }

@media screen and (min-width: 768px) and (max-width: 1199px) {
  body {
    --button-m: 270px; } }

@media screen and (max-width: 767px) {
  body {
    --button-m: 100%; } }

@media screen and (min-width: 1200px) {
  body {
    --button-s: 240px; } }

@media screen and (min-width: 768px) and (max-width: 1199px) {
  body {
    --button-s: 220px; } }

@media screen and (max-width: 767px) {
  body {
    --button-s: 100%; } }

/*
# Global animations

This file to stores all global animation and include it only once in single place
so all other components could reuse this animations.
*/
@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes animation-chevron-up {
  0% {
    transform: translateY(-50%) rotate(46deg);
    width: 9px; }
  50% {
    transform: translate(-20%, -50%) rotate(0deg);
    width: 7px; }
  100% {
    transform: translateY(-50%) rotate(-46deg);
    width: 9px; } }

@keyframes animation-chevron-down {
  0% {
    transform: translateY(-50%) rotate(-46deg);
    width: 9px; }
  50% {
    transform: translate(-20%, -50%) rotate(0deg);
    width: 7px; }
  100% {
    transform: translateY(-50%) rotate(46deg);
    width: 9px; } }

@keyframes thumbs-zoom {
  0% {
    transform: translateY(35px); }
  100% {
    transform: translateY(0); } }

@keyframes slide-from-bottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes dialog-appearance {
  0% {
    opacity: 0.5;
    transform: scale(0.8); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@media (prefers-reduced-motion) {
  * {
    animation: none !important;
    transition: none !important; } }

/*md

# Global alerts

These alerts used to notify blind user about dynamic changes on the page. Ex: load more products,
filters applied, sorting applied etc. To meet basic a11y requirements.

It is recommended not to hide these alerts and make them visible.

<style>
.b-global_alerts {
	position: static !important;
}
</style>

```html_example
<div class="b-global_alerts">
    <div
        class="b-global_alerts-item"
        role="alert"
    >
        Filters applied
    </div>
</div>
```

## Visually hide alerts

It is possible to hide these alerts visually, but it is not recommended.

We have a special modification class for this - `m-visually_hidden`.

*/
.b-global_alerts {
  bottom: 25px;
  left: 25px;
  pointer-events: none;
  position: fixed;
  right: 25px;
  text-align: center;
  z-index: 14; }
  @media screen and (max-width: 767px) {
    .b-global_alerts {
      bottom: 150px; } }
  .b-global_alerts.m-visually_hidden {
    border: 0 ;
    clip: rect(1px, 1px, 1px, 1px) ;
    margin: 0 ;
    max-height: 1px ;
    max-width: 1px ;
    overflow: hidden ;
    padding: 0 ;
    position: absolute ; }
  .b-global_alerts-item {
    animation: slide-from-bottom cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    background-color: #D2D2CE;
    border-radius: 3px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    color: #202025;
    display: inline-block;
    font-size: 14px;
    margin: 0 auto;
    max-width: 400px;
    min-width: 194px;
    padding: 15px 20px;
    transform: translateY(0); }
    @media screen and (max-width: 767px) {
      .b-global_alerts-item {
        padding: 10px 15px; } }
    .b-global_alerts-item.m-error {
      background-color: #FAEDED;
      color: #CA1E36; }

/*md

# Button Main

## States

### Normal state

```html_example
<button class="g-button_main">Button</button>
```

### Disabled state

There are two variants of disabled buttons:

- disabled with CSS class (only disabled look, can be focused)
- disabled with HTML attribute

```html_example
<button class="g-button_main m-disabled">Disabled (CSS)</button>
<button class="g-button_main" disabled>Disabled (HTML)</button>
```

## Modifications

### Small

```html_example
<button class="g-button_main m-small">Small button</button>
```

### Full width

Is the same as the default one but with 100% width

```html_example
<button class="g-button_main m-full_width">Full width</button>
```

### Inversed

The same type of button but with inversed colors

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed">Inversed</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed m-small">Small</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed m-disabled">Disabled (CSS)</button>
	<button class="g-button_main m-inversed" disabled>Disabled (HTML)</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed m-full_width">Full width</button>
</div>
```
*/
.g-button_main {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: top;
  word-break: break-word;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: 800;
  height: 60px;
  letter-spacing: 3px;
  line-height: 1.2;
  padding: 5px 25px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color, border;
  background: var(--button_main-background_color, #BA8D36);
  border: 2px solid var(--button_main-border_color, #BA8D36);
  color: var(--button_main-text_color, #202025);
  border-radius: 0; }
  .g-button_main:hover {
    text-decoration: none; }
  @media (hover: hover) and (pointer: fine) {
    .g-button_main:hover {
      background: var(--button_main__hover-background_color, #fff);
      border-color: var(--button_main__hover-border_color, #BA8D36);
      border-radius: 0;
      color: var(--button_main__hover-text_color, #202025); } }
  .g-button_main:disabled:not(.m-processing), .g-button_main.m-disabled:not(.m-processing) {
    pointer-events: none;
    opacity: 1;
    background-color: #D2D2CE;
    color: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 0; }
    @media screen and (max-width: 1199px) {
      .g-button_main:disabled:not(.m-processing), .g-button_main.m-disabled:not(.m-processing) {
        padding: 5px 0; } }
  .g-button_main.m-small {
    font-weight: 700;
    height: 40px;
    padding: 8px 15px; }
  .g-button_main.m-width_s {
    width: var(--button-s); }
  .g-button_main.m-width_m {
    width: var(--button-m); }
  .g-button_main.m-width_l {
    width: var(--button-l); }
  .g-button_main.m-processing {
    cursor: wait; }
  .g-button_main.m-inversed {
    background: var(--button_main__inversed-background_color, #fff);
    border: 2px solid var(--button_main__inversed-border_color, #BA8D36);
    border-radius: 0;
    color: var(--button_main__inversed-text_color, #202025); }
    @media (hover: hover) and (pointer: fine) {
      .g-button_main.m-inversed:hover {
        background: var(--button_main__inversed__hover-background_color, #BA8D36);
        border-color: var(--button_main__inversed__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_main__inversed__hover-text_color, #202025); } }
    .g-button_main.m-inversed:disabled:not(.m-processing), .g-button_main.m-inversed.m-disabled:not(.m-processing) {
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border: 2px solid #D2D2CE;
      border-radius: 0; }
  .g-button_main.m-full_width {
    width: 100%; }

/*md

# Button Alt

## States

### Normal state

```html_example
<button class="g-button_alt">Button</button>
```

### Disabled state

There are two variants of disabled buttons:

- disabled with CSS class (only disabled look, can be focused)
- disabled with HTML attribute

```html_example
<button class="g-button_alt m-disabled">Disabled (CSS)</button>
<button class="g-button_alt" disabled>Disabled (HTML)</button>
```

## Modifications

### Small

```html_example
<button class="g-button_alt m-small">Small button</button>
```

### Full width

Is the same as the default one but with 100% width

```html_example
<button class="g-button_alt m-full_width">Full width</button>
```

### Inversed

The same type of button but with inversed colors

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_alt m-inversed">Inversed</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_alt m-inversed m-small">Small</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_alt m-inversed m-disabled">Disabled (CSS)</button>
	<button class="g-button_alt m-inversed" disabled>Disabled (HTML)</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_alt m-inversed m-full_width">Full width</button>
</div>
```
*/
.g-button_alt {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: top;
  word-break: break-word;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: 800;
  height: 60px;
  letter-spacing: 3px;
  line-height: 1.2;
  padding: 5px 25px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color, border;
  background: var(--button_alt-background_color, #fff);
  border: 2px solid var(--button_alt-border_color, #BA8D36);
  color: var(--button_alt-text_color, #202025);
  border-radius: 0; }
  .g-button_alt:hover {
    text-decoration: none; }
  @media (hover: hover) and (pointer: fine) {
    .g-button_alt:hover {
      background: var(--button_alt__hover-background_color, #BA8D36);
      border-color: var(--button_alt__hover-border_color, #BA8D36);
      border-radius: 0;
      color: var(--button_alt__hover-text_color, #202025); } }
  .g-button_alt:disabled:not(.m-processing), .g-button_alt.m-disabled:not(.m-processing) {
    pointer-events: none;
    opacity: 1;
    background-color: #fff;
    color: #D2D2CE;
    border-color: #D2D2CE;
    border-radius: 0; }
    @media screen and (max-width: 1199px) {
      .g-button_alt:disabled:not(.m-processing), .g-button_alt.m-disabled:not(.m-processing) {
        padding: 5px 0; } }
  .g-button_alt.m-small {
    font-weight: 700;
    height: 40px;
    padding: 8px 15px; }
  .g-button_alt.m-width_s {
    width: var(--button-s); }
  .g-button_alt.m-width_m {
    width: var(--button-m); }
  .g-button_alt.m-width_l {
    width: var(--button-l); }
  .g-button_alt.m-processing {
    cursor: wait; }
  .g-button_alt.m-inversed {
    background: var(--button_alt__inversed-background_color, #BA8D36);
    border-color: var(--button_alt__inversed-border_color, #BA8D36);
    border-radius: 0;
    color: var(--button_alt__inversed-text_color, #fff); }
    @media (hover: hover) and (pointer: fine) {
      .g-button_alt.m-inversed:hover {
        background: var(--button_alt__inversed__hover-background_color, #fff);
        border-color: var(--button_alt__inversed__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__inversed__hover-text_color, #BA8D36);
        text-decoration: none; } }
  .g-button_alt.m-full_width {
    width: 100%; }

.b-back_to_top {
  color: #202025;
  cursor: pointer;
  display: block;
  text-align: center; }
  @media screen and (min-width: 1200px) {
    .b-back_to_top {
      bottom: 80px;
      display: flex;
      margin: 0 0 30px auto;
      position: sticky;
      right: 50px;
      width: 48px;
      z-index: 2; }
      .b-back_to_top.m-hide {
        opacity: 0;
        visibility: hidden; } }
  @media screen and (max-width: 1199px) {
    .b-back_to_top {
      margin: 0 auto;
      max-width: 1720px;
      padding-left: 80px;
      padding-right: 80px;
      align-items: center;
      color: #584C44;
      flex-direction: column;
      font-weight: 700;
      letter-spacing: 3px;
      margin: 20px 0 40px;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%; } }
  @media screen and (max-width: 1199px) and (min-width: 1200px) and (max-width: 1440px) {
    .b-back_to_top {
      max-width: 1400px; } }
  @media screen and (max-width: 1199px) and (min-width: 768px) and (max-width: 1199px) {
    .b-back_to_top {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 1199px) and (max-width: 767px) {
    .b-back_to_top {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-back_to_top:hover {
    text-decoration: none; }
  .b-back_to_top-icon {
    opacity: 0.5;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: opacity, filter; }
    @media screen and (max-width: 1199px) {
      .b-back_to_top-icon {
        margin-bottom: 10px;
        opacity: 1; } }
  .b-back_to_top-icon_circle {
    fill: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .b-back_to_top:hover .b-back_to_top-icon {
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0 4px 5px rgba(0, 0, 0, 0.11));
      opacity: 1; } }
  .b-back_to_top-copy {
    display: none; }
    @media screen and (max-width: 1199px) {
      .b-back_to_top-copy {
        display: block; } }

.b-button_remove {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: top;
  word-break: break-word;
  color: #202025; }
  .b-button_remove::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 22px;
    mask: url("./svg-icons/sprite.svg#remove-2");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 22px; }

.b-button_close {
  cursor: pointer;
  padding: 15px;
  user-select: none; }
  .b-button_close::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 21px;
    mask: url("./svg-icons/sprite.svg#close");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 21px; }
  @media screen and (max-width: 767px) {
    .b-button_close::before {
      height: 16px;
      width: 16px; } }
  .b-button_close.m-small::before {
    height: 16px;
    width: 16px; }

.b-countdown {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative; }
  .b-countdown-time {
    font: 400 28px/36px "Proxima Nova", sans-serif;
    letter-spacing: 0.35px;
    position: absolute; }
    @media screen and (max-width: 1199px) {
      .b-countdown-time {
        font-size: 24px;
        letter-spacing: 0.3px; } }
  .b-countdown-icon {
    height: 190px;
    transform: rotateZ(-90deg);
    width: 190px; }
  .b-countdown-circle_bg {
    fill: none;
    stroke: #fff;
    stroke-width: 5px; }
  .b-countdown-circle_animated {
    fill: none;
    stroke: #202025;
    stroke-width: 5px;
    transition: stroke-dasharray 2.8s linear; }

@keyframes loader_icon-grape {
  0% {
    opacity: 0.2; }
  100% {
    opacity: 1; } }

.b-loader {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 16; }
  .b-loader .b-icon_loader {
    color: #202025;
    height: 100px;
    width: 100px; }
  .b-loader:not(.h-hidden) .b-icon_loader-grape {
    animation: loader_icon-grape 1s infinite alternate ease-in-out;
    opacity: 0; }
  .b-loader:not(.h-hidden) .b-icon_loader-branch {
    animation: loader_icon-grape 0.7s forwards ease-in-out; }
  .b-loader .b-icon_loader-grape:nth-child(1) {
    animation-delay: 0s; }
  .b-loader .b-icon_loader-grape:nth-child(2) {
    animation-delay: -0.3s; }
  .b-loader .b-icon_loader-grape:nth-child(3) {
    animation-delay: -0.6s; }
  .b-loader .b-icon_loader-grape:nth-child(4) {
    animation-delay: -0.9s; }
  .b-loader .b-icon_loader-grape:nth-child(5) {
    animation-delay: -1.2s; }
  .b-loader .b-icon_loader-grape:nth-child(6) {
    animation-delay: -1.5s; }
  .b-loader .b-icon_loader-grape:nth-child(7) {
    animation-delay: -1.8s; }
  .b-loader .b-icon_loader-grape:nth-child(8) {
    animation-delay: -2.1s; }

.b-overlay {
  background-color: rgba(210, 210, 206, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1; }

.b-search_input {
  align-items: center;
  border-bottom: 1px solid #202025;
  display: flex;
  position: relative;
  width: 100%; }
  .b-search_input-input {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    color: #202025;
    flex-grow: 1;
    font-family: "Proxima Nova", sans-serif;
    font-size: 32px;
    line-height: 40px;
    order: 1;
    padding: 0 0 10px 0;
    width: auto; }
    .b-search_input-input::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .b-search_input-input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
    @media screen and (min-width: 768px) {
      .b-search_input-input {
        font-weight: 400; } }
    @media screen and (max-width: 767px) {
      .b-search_input-input {
        width: 100%; } }
    .b-search_input-input::placeholder {
      color: #202025;
      text-transform: capitalize; }
    .b-search_input-input::-webkit-search-cancel-button, .b-search_input-input::-webkit-search-decoration, .b-search_input-input::-webkit-inner-spin-button {
      appearance: none; }
    .b-search_input-input::-ms-clear {
      display: none; }
  .b-search_input-submit {
    align-self: stretch;
    cursor: pointer;
    order: 3;
    padding-left: 20px; }
    .b-search_input-submit::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 16px;
      mask: url("./svg-icons/sprite.svg#search");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 16px; }
  .b-search_input-clear {
    cursor: pointer;
    font-weight: bold;
    opacity: 0;
    order: 2;
    pointer-events: none;
    transition: opacity 0.3s; }
    .b-search_input-clear.m-visible {
      opacity: 1;
      pointer-events: all; }

/*md

# Separator

It's a full-width horizontal line block

<style>
.b-separator {
	margin: 10px;
}
</style>
```html_example
<hr class="b-separator" />
```
*/
.b-separator {
  border-bottom: 1px solid #D2D2CE;
  padding-bottom: 40px;
  border-width: 0 0 1px;
  margin-top: 0; }

/*md

# Tabs (`b-tab_list` / `b-tab_panel`)

Tabs presents multiple mutually exclusive panes of content in the same area.
Includes a tabbed control(tab) and a content area. Clicking a tab displays its corresponding pane in the content area.

## Attributes

```
[boolean] - data-active-first - activate first tab and first tab panel
[string]  - data-active-panel - activate tab and tab panel by provided panel id
[boolean] - data-auto-activation - if tabs list should follow accessibility `Tabs with Automatic Activation` feature
```

## Usage

To get started with tabs we should link the tab and the content area.
We have a `data-panel-name` attribute on a tab and `id` attribute on the pane for that.

### Tabs with automatic activation (data-auto-activation="true")

The tab pane content will change just in case of using arrow keys.
With using just a Tab key, inactive tabs will be skipped by focus.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="true"
    data-active-panel="firstPanel"
    data-event-keydown="handleKeydown"
>
    <div class="b-tab_list">
        <div data-ref="tablist" role="tablist" class="b-tab_list-wrapper">
            <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
            >
                First tab
            </button>

            <button
                    tabindex="-1"
                    aria-selected="false"
                    class="b-tab_list-tab"
                    data-panel-name="secondPanel"
                    data-widget-event-click="handleTabClick"
                    data-id="button-secondPanel"
                    data-event-click.prevent="handleClick"
                    data-widget="button"
                    role="tab"
            >
                Second tab
            </button>
        </div>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

### Tabs with manual activation (data-auto-activation="false")

The tab pane content will change just in case of using Enter/Space button on focused element.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="false"
    data-event-keydown="handleKeydown"
    data-active-panel="firstPanel"
>
    <div class="b-tab_list">
        <div data-ref="tablist" role="tablist" class="b-tab_list-wrapper">
            <button aria-selected="true"
                    class="b-tab_list-tab"
                    data-panel-name="firstPanel"
                    data-widget-event-click="handleTabClick"
                    data-id="button-firstPanel"
                    data-event-click.prevent="handleClick"
                    data-widget="button"
                    role="tab"
            >
                First tab
            </button>

            <button
                aria-selected="false"
                class="b-tab_list-tab"
                data-panel-name="secondPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-secondPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
            >
                Second tab
            </button>
        </div>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

## SCSS Notes

We have two SCSS blocks for Tabs. The first is for tab controls `b-tab_list` , and the second is fortab content
areas `b-tab_panel`.
*/
.b-tab_list {
  position: relative; }
  .b-tab_list-wrapper {
    scrollbar-width: none;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    user-select: none; }
    .b-tab_list-wrapper::-webkit-scrollbar {
      display: none; }
    .b-tab_list-wrapper.m-scrollable::before, .b-tab_list-wrapper.m-scrollable::after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 40px;
      z-index: 1; }
    .b-tab_list-wrapper.m-scrollable::before {
      background: linear-gradient(to right, #fff, transparent); }
    .b-tab_list-wrapper.m-scrollable::after {
      background: linear-gradient(to left, #fff, transparent);
      right: 0; }
    .b-tab_list-wrapper.m-scrollable.m-scroll_start::before, .b-tab_list-wrapper.m-scrollable.m-scroll_end::after {
      display: none; }
  .b-tab_list-tab {
    appearance: none;
    background: transparent;
    border-bottom: 1px solid #D2D2CE;
    border-radius: 0;
    color: #A7A8A8;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 24px;
    padding: 20px 30px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    white-space: nowrap; }
    @media screen and (max-width: 767px) {
      .b-tab_list-tab {
        flex-basis: 100%;
        padding: 15px 20px; } }
    .b-tab_list-tab:first-child {
      margin-left: auto;
      padding-left: 0; }
    .b-tab_list-tab:last-child {
      margin-right: auto;
      padding-right: 0; }
    .b-tab_list-tab:not(:first-child)::before {
      background: #D2D2CE;
      content: '';
      height: 15px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px; }
    .b-tab_list-tab:not(:disabled):hover, .b-tab_list-tab.m-active {
      color: #202025; }

.b-tab_panel {
  width: 100%; }
  .b-tab_panel:not(.m-active) {
    display: none; }

/*

# Form

TBD

*/
.b-form-message {
  align-items: center;
  background: #fff;
  border: 2px solid #D2D2CE;
  border-radius: 3px;
  color: #202025;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  line-height: 24px;
  padding: 15px 35px;
  text-align: center;
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    .b-form-message {
      padding: 20px; } }

.b-form-message.m-inversed {
  background: rgba(255, 255, 255, 0.15);
  color: #202025; }

.b-form-message.m-error {
  background: #FAEDED;
  border: none;
  color: #CA1E36;
  margin-bottom: 35px; }

.b-form-set {
  border: 0;
  margin: 20px 0;
  padding: 0; }
  .b-form-set[hidden] {
    display: none; }
  .b-form-set.m-gift_message {
    margin: 0; }
  .b-form-set .b-expected_ship_date {
    margin-top: 20px; }

.b-form-set_label.m-wai {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  margin: 0 !important;
  max-height: 1px !important;
  max-width: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important; }

.b-form-note {
  margin-bottom: 20px; }

.b-form-line {
  display: flex;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .b-form-line {
      display: block; }
      .b-form-line .f-field {
        margin-right: 0; } }
  .b-form-line .f-field {
    flex: 1;
    margin-right: 15px; }
    .b-form-line .f-field:last-child {
      margin-right: 0; }

.b-form-fixed_wrapper {
  max-width: 100%; }

.b-form-field_group {
  display: inline-block;
  margin-bottom: 20px;
  width: 100%; }

.b-form-static_row {
  border-bottom: 1px solid #D2D2CE;
  font-size: 18px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
  padding-bottom: 20px; }

.b-form-static_row_label {
  font-weight: 700; }

.b-form-controls {
  display: flex; }

.b-form-controls.m-column {
  flex-direction: row; }
  .b-form-controls.m-column .b-form-btn_alt {
    margin-top: 0;
    margin-left: 18px; }

.b-form-btn_main {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: top;
  word-break: break-word;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: 800;
  height: 60px;
  letter-spacing: 3px;
  line-height: 1.2;
  padding: 5px 25px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color, border;
  background: var(--button_main-background_color, #BA8D36);
  border: 2px solid var(--button_main-border_color, #BA8D36);
  color: var(--button_main-text_color, #202025);
  border-radius: 0;
  width: 100%; }
  .b-form-btn_main:hover {
    text-decoration: none; }
  @media (hover: hover) and (pointer: fine) {
    .b-form-btn_main:hover {
      background: var(--button_main__hover-background_color, #fff);
      border-color: var(--button_main__hover-border_color, #BA8D36);
      border-radius: 0;
      color: var(--button_main__hover-text_color, #202025); } }
  .b-form-btn_main:disabled:not(.m-processing), .b-form-btn_main.m-disabled:not(.m-processing) {
    pointer-events: none;
    opacity: 1;
    background-color: #D2D2CE;
    color: #fff;
    border: 2px solid #D2D2CE;
    border-radius: 0; }
    @media screen and (max-width: 1199px) {
      .b-form-btn_main:disabled:not(.m-processing), .b-form-btn_main.m-disabled:not(.m-processing) {
        padding: 5px 0; } }
  .b-form-btn_main.m-small {
    font-weight: 700;
    height: 40px;
    padding: 8px 15px; }
  .b-form-btn_main.m-width_s {
    width: var(--button-s); }
  .b-form-btn_main.m-width_m {
    width: var(--button-m); }
  .b-form-btn_main.m-width_l {
    width: var(--button-l); }
  .b-form-btn_main.m-processing {
    cursor: wait; }

.b-form-btn_main.m-inversed {
  background: var(--button_main__inversed-background_color, #fff);
  border: 2px solid var(--button_main__inversed-border_color, #BA8D36);
  border-radius: 0;
  color: var(--button_main__inversed-text_color, #202025); }
  @media (hover: hover) and (pointer: fine) {
    .b-form-btn_main.m-inversed:hover {
      background: var(--button_main__inversed__hover-background_color, #BA8D36);
      border-color: var(--button_main__inversed__hover-border_color, #BA8D36);
      border-radius: 0;
      color: var(--button_main__inversed__hover-text_color, #202025); } }
  .b-form-btn_main.m-inversed:disabled:not(.m-processing), .b-form-btn_main.m-inversed.m-disabled:not(.m-processing) {
    opacity: 1;
    background-color: #fff;
    color: #D2D2CE;
    border: 2px solid #D2D2CE;
    border-radius: 0; }

.b-form-btn_alt {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  vertical-align: top;
  word-break: break-word;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: 800;
  height: 60px;
  letter-spacing: 3px;
  line-height: 1.2;
  padding: 5px 25px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color, border;
  background: var(--button_alt-background_color, #fff);
  border: 2px solid var(--button_alt-border_color, #BA8D36);
  color: var(--button_alt-text_color, #202025);
  border-radius: 0;
  width: 100%; }
  .b-form-btn_alt:hover {
    text-decoration: none; }
  @media (hover: hover) and (pointer: fine) {
    .b-form-btn_alt:hover {
      background: var(--button_alt__hover-background_color, #BA8D36);
      border-color: var(--button_alt__hover-border_color, #BA8D36);
      border-radius: 0;
      color: var(--button_alt__hover-text_color, #202025); } }
  .b-form-btn_alt:disabled:not(.m-processing), .b-form-btn_alt.m-disabled:not(.m-processing) {
    pointer-events: none;
    opacity: 1;
    background-color: #fff;
    color: #D2D2CE;
    border-color: #D2D2CE;
    border-radius: 0; }
    @media screen and (max-width: 1199px) {
      .b-form-btn_alt:disabled:not(.m-processing), .b-form-btn_alt.m-disabled:not(.m-processing) {
        padding: 5px 0; } }
  .b-form-btn_alt.m-small {
    font-weight: 700;
    height: 40px;
    padding: 8px 15px; }
  .b-form-btn_alt.m-width_s {
    width: var(--button-s); }
  .b-form-btn_alt.m-width_m {
    width: var(--button-m); }
  .b-form-btn_alt.m-width_l {
    width: var(--button-l); }
  .b-form-btn_alt.m-processing {
    cursor: wait; }

.b-form-btn_alt.m-inversed {
  background: var(--button_alt__inversed-background_color, #BA8D36);
  border-color: var(--button_alt__inversed-border_color, #BA8D36);
  border-radius: 0;
  color: var(--button_alt__inversed-text_color, #fff); }
  @media (hover: hover) and (pointer: fine) {
    .b-form-btn_alt.m-inversed:hover {
      background: var(--button_alt__inversed__hover-background_color, #fff);
      border-color: var(--button_alt__inversed__hover-border_color, #BA8D36);
      border-radius: 0;
      color: var(--button_alt__inversed__hover-text_color, #BA8D36);
      text-decoration: none; } }

.b-form-btn_main + .b-form-btn_alt {
  margin-top: 25px; }

.b-form-back {
  cursor: pointer; }
  .b-form-back::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 14px;
    mask: url("./svg-icons/sprite.svg#arrow-left");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 8px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle; }

.b-form-set.m-no_margin {
  margin: 0; }

/*

# Input checkbox field

## Standard checkbox field

```html_example
<div class="f-input_checkbox">
	<input class="f-input_checkbox-field"
		id="input-1"
		name="name"
		maxlength="50"
		placeholder=" "
		required
		type="checkbox"
	/>
	<label class="f-input_checkbox-label"
		for="input-1"
	>Remember Me</label>
</div>
```
*/
.f-input_checkbox {
  align-items: flex-start;
  display: flex;
  width: 100%; }
  .f-input_checkbox-field {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    background: var(--icon-color, #BA8D36);
    display: block;
    height: 20px;
    mask: url("./svg-icons/sprite.svg#checkbox-unchecked"), url("./svg-icons/sprite.svg#checkbox-checked");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%, 0;
    width: 20px;
    background: #202025;
    cursor: pointer;
    flex-shrink: 0;
    margin: 3px 10px 0 0; }
    .f-input_checkbox-field::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .f-input_checkbox-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
  .f-input_checkbox-field:checked, .f-input_checkbox-field.m-checked {
    mask-size: 0, 100%; }
  .f-input_checkbox-label {
    color: #6A6A67;
    cursor: pointer;
    display: flex;
    font: 600 12px/16px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform-origin: left top;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 5px 0; }
    .f-input_checkbox-label.m-gift_label {
      color: #BA8D36;
      font-weight: 700;
      text-transform: uppercase; }
  .f-input_checkbox-field[readonly='readonly'],
  .f-input_checkbox-field[readonly='readonly'] ~ .f-input_checkbox-label {
    pointer-events: none; }
  .f-input_checkbox.m-required .f-input_checkbox-label::after {
    content: '*'; }
  .f-input_checkbox-field:checked + .f-input_checkbox-label,
  .f-input_checkbox-field.m-checked + .f-input_checkbox-label {
    color: #202025; }
  .f-input_checkbox-field:checked + .f-input_checkbox-label.m-gift_label {
    color: #BA8D36; }
  .f-input_checkbox-field:disabled {
    background: #D2D2CE;
    pointer-events: none; }
  .f-input_checkbox-field:disabled + .f-input_checkbox-label {
    color: #D2D2CE;
    pointer-events: none; }

/*

# f-field

Use this component to add some structure to forms.
It is possible to use any form element inside.

## Input element with label

```html_example
	<div class="f-field">
		<div lass="f-input_text">
			<input class="f-input_text-field" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
			<label class="f-input_text-label  m-required" for="input1">
				Enter Promo Code
			</label>
		</div>
	</div>
```

## Input element with required label
```html_example
	<div class="f-field">
		<div class="f-field-caption">Promo Code</div>
		<div lass="f-input_text">
			<input class="f-input_text-field" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
			<label class="f-input_text-label m-required" for="input1">
				Enter Promo Code
			</label>
		</div>
	</div>
```

## Input element with caption
```html_example
	<div class="f-field">
		<div class="f-field-caption">Promo Code</div>
		<div lass="f-input_text">
			<input class="f-input_text-field" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
			<label class="f-input_text-label" for="input1">
				Enter Promo Code
			</label>
		</div>
	</div>
```
*/
.f-field {
  margin-bottom: 25px; }
  .f-field-caption, .f-field-error {
    font-family: "Proxima Nova", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 5px; }
  .f-field-caption {
    color: var(--color-form-caption, #6A6A67); }
  .f-field-error {
    color: var(--color-error, #CA1E36); }
  .f-field.m-valid .f-input_text::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 20px;
    mask: url("./svg-icons/sprite.svg#checkmark-plain");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 20px;
    color: #37a10a;
    position: absolute;
    right: 15px;
    top: 13px; }
    @media screen and (max-width: 767px) {
      .f-field.m-valid .f-input_text::before {
        top: 18px; } }
  .f-field.m-no_margin {
    margin-bottom: 0; }
  .f-field.m-gift_message {
    margin-bottom: 0;
    margin-top: 25px;
    text-align: right; }
    .f-field.m-gift_message .f-input_textarea-label,
    .f-field.m-gift_message .f-input_textarea-field {
      text-align: left; }

/*md

# Input Date field

## Standard input field

### States

#### Default state

```html_example
<div class="f-input_date">
	<input class="f-input_date-field"
		id="input-1"
		name="name"
		placeholder="dd/mm/yyyy"
		required
		type="date"
	/>
	<label class="f-input_date-label"
		for="input-1"
	>Birthday</label>
</div>
```

#### Disabled state

```html_example
<div class="f-input_date">
	<input class="f-input_date-field"
		id="input-1"
		name="name"
		placeholder="dd/mm/yyyy"
		required
		disabled
		type="date"
	/>
	<label class="f-input_date-label"
		for="input-1"
	>Birthday</label>
</div>
```

#### Invalid state

```html_example
<div class="f-input_date">
	<input class="f-input_date-field m-invalid"
		id="input-1"
		name="name"
		value="2020-09-09"
		placeholder="dd/mm/yyyy"
		required
		type="date"
	/>
	<label class="f-input_date-label"
		for="input-1"
	>Birthday</label>
</div>
```
*/
.f-input_date {
  position: relative; }
  .f-input_date::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 1em;
    mask: url("./svg-icons/sprite.svg#calendar");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    pointer-events: none; }
  .f-input_date-field {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    font: 700 16px/22px "Proxima Nova", sans-serif;
    height: 46px;
    letter-spacing: 2.1px;
    line-height: 22px;
    transition: height linear 0.5s;
    border: 1px solid #A7A8A8;
    color: #6A6A67;
    padding: 21px 40px 7px 15px; }
    .f-input_date-field::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .f-input_date-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
    @media screen and (max-width: 767px) {
      .f-input_date-field {
        height: 56px; } }
    @media screen and (max-width: 767px) {
      .f-input_date-field {
        padding: 28px 40px 10px 15px; } }
    .f-input_date-field::-webkit-calendar-picker-indicator {
      height: 20px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      opacity: 0; }
    .f-input_date-field:hover {
      border-color: #202025; }
    .f-input_date-field.m-invalid {
      color: #CA1E36; }
    .f-input_date-field:disabled {
      border-color: #D2D2CE;
      color: #D2D2CE; }
    .f-input_date-field:focus, .f-input_date-field:valid {
      color: #202025; }
    .f-input_date-field::placeholder {
      color: #6A6A67;
      text-transform: uppercase; }
  .f-input_date-label {
    color: #6A6A67;
    cursor: pointer;
    display: flex;
    font: 600 12px/16px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform-origin: left top;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 14px;
    transform: translateY(-40%) scale(0.75); }
    @media screen and (max-width: 767px) {
      .f-input_date-label {
        top: 19px; } }
  .f-input_date.m-required .f-input_date-label::after {
    content: '*'; }
  .f-input_date-field:disabled ~ .f-input_date-label {
    color: #D2D2CE; }

/*

# Input Text field

## Standard input field

### States

#### Default state

```html_example
<div class="f-input_text">
	<input class="f-input_text-field"
		id="input-1"
		name="name"
		maxlength="50"
		placeholder=" "
		required
		type="text"
	/>
	<label class="f-input_text-label"
		for="input-1"
	>First Name</label>
</div>
```
*/
.f-input_text {
  position: relative; }
  .f-input_text-field {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    font: 700 16px/22px "Proxima Nova", sans-serif;
    height: 46px;
    letter-spacing: 2.1px;
    line-height: 22px;
    transition: height linear 0.5s;
    border: 1px solid #A7A8A8;
    padding: 21px 40px 7px 15px; }
    .f-input_text-field::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .f-input_text-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
    @media screen and (max-width: 767px) {
      .f-input_text-field {
        height: 56px; } }
    @media screen and (max-width: 767px) {
      .f-input_text-field {
        padding: 28px 40px 10px 15px; } }
    .f-input_text-field:hover {
      border-color: #202025; }
    .f-input_text-field.m-invalid {
      color: #CA1E36; }
    .f-input_text-field:disabled {
      border-color: #D2D2CE;
      color: #D2D2CE; }
    .f-input_text-field:focus::placeholder {
      color: #6A6A67; }
  .f-input_text-label {
    color: #6A6A67;
    cursor: pointer;
    display: flex;
    font: 600 12px/16px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform-origin: left top;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 14px; }
    @media screen and (max-width: 767px) {
      .f-input_text-label {
        top: 19px; } }
  .f-input_text.m-required .f-input_text-label::after {
    content: '*'; }
  .f-input_text-field:not(:placeholder-shown) ~ .f-input_text-label,
  .f-input_text-field:focus ~ .f-input_text-label {
    transform: translateY(-40%) scale(0.75); }
  .f-input_text-field:disabled ~ .f-input_text-label {
    color: #D2D2CE; }

/*

# Input Text field

## Standard input field

### States

#### Default state

```html_example
<div class="f-input_password">
	<input class="f-input_password-field"
		id="input-1"
		name="name"
		maxlength="50"
		placeholder=" "
		required
		type="text"
	/>
	<button class="f-input_password-toggle_visibility" type="button">Show</button>
	<label class="f-input_password-label"
		for="input-1"
	>First Name</label>
</div>
```
*/
.f-input_password {
  position: relative; }
  .f-input_password-field {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    font: 700 16px/22px "Proxima Nova", sans-serif;
    height: 46px;
    letter-spacing: 2.1px;
    line-height: 22px;
    transition: height linear 0.5s;
    border: 1px solid #A7A8A8;
    padding: 21px 55px 7px 15px;
    text-transform: none; }
    .f-input_password-field::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .f-input_password-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
    @media screen and (max-width: 767px) {
      .f-input_password-field {
        height: 56px; } }
    @media screen and (max-width: 767px) {
      .f-input_password-field {
        padding: 28px 55px 10px 15px; } }
    .f-input_password-field:hover {
      border-color: #202025; }
    .f-input_password-field.m-invalid {
      color: #CA1E36; }
    .f-input_password-field:disabled {
      border-color: #D2D2CE;
      color: #D2D2CE; }
    .f-input_password-field:focus::placeholder {
      color: #6A6A67; }
  .f-input_password-label {
    color: #6A6A67;
    cursor: pointer;
    display: flex;
    font: 600 12px/16px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform-origin: left top;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 14px; }
    @media screen and (max-width: 767px) {
      .f-input_password-label {
        top: 19px; } }
  .f-input_password-toggle_visibility {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    background-size: 0 0;
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 15px; }
    .f-input_password-toggle_visibility:visited {
      color: var(--color-link-visited, #584C44); }
    .f-input_password-toggle_visibility:hover {
      text-decoration: none; }
    .f-input_password-toggle_visibility.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .f-input_password-toggle_visibility:visited {
      color: var(--color-link-visited, #584C44); }
    .f-input_password-toggle_visibility:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .f-input_password-toggle_visibility:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .f-input_password-toggle_visibility.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    @media screen and (max-width: 767px) {
      .f-input_password-toggle_visibility {
        top: 20px; } }
  .f-input_password.m-required .f-input_password-label::after {
    content: '*'; }
  .f-input_password-field:not(:placeholder-shown) ~ .f-input_password-label,
  .f-input_password-field:focus ~ .f-input_password-label {
    transform: translateY(-40%) scale(0.75); }
  .f-input_password-field:disabled ~ .f-input_password-label {
    color: #D2D2CE; }

/*

# Input radio field

## Standard radio field

```html_example
<div class="f-input_radio">
	<input class="f-input_radio-field"
		id="input-1"
		name="name"
		maxlength="50"
		placeholder=" "
		required
		type="radio"
	/>
	<label class="f-input_radio-label"
		for="input-1"
	>Remember Me</label>
</div>
```
*/
.f-input_radio {
  align-items: center;
  display: flex;
  width: 100%; }
  .f-input_radio-field {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    background: var(--icon-color, #BA8D36);
    display: block;
    height: 20px;
    mask: url("./svg-icons/sprite.svg#radio-unchecked"), url("./svg-icons/sprite.svg#radio-checked");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%, 0;
    width: 20px;
    background: #202025;
    cursor: pointer;
    height: 20px;
    margin-right: 10px;
    width: 20px; }
    .f-input_radio-field::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .f-input_radio-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
  .f-input_radio-field:checked, .f-input_radio-field.m-checked {
    mask-size: 0, 100%; }
  .f-input_radio-label {
    color: #6A6A67;
    cursor: pointer;
    display: flex;
    font: 600 12px/16px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform-origin: left top;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1); }
  .f-input_radio.m-required .f-input_radio-label::after {
    content: '*'; }
  .f-input_radio-field:checked + .f-input_radio-label,
  .f-input_radio-field.m-checked + .f-input_radio-label {
    color: #202025; }
  .f-input_radio-field:disabled {
    background: #D2D2CE;
    pointer-events: none; }
  .f-input_radio-field:disabled + .f-input_radio-label {
    color: #D2D2CE;
    pointer-events: none; }

/*

# Input select

## Standard select element

```html_example
<div class="f-input_select">
	<select class="f-input_select-field"
		id="input-1"
		name="name"
		maxlength="50"
		placeholder=" "
		required
	>
		<option>Option 1</option>
		<option>Option 2</option>
	</select>
</div>
```
*/
.f-input_select {
  position: relative; }
  .f-input_select::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 8px;
    mask: url("./svg-icons/sprite.svg#arrow-down");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 15px;
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translate(0, -50%); }
  .f-input_select-field {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    border: 1px solid #A7A8A8;
    border-radius: 0;
    color: #202025;
    font: 700 16px/22px "Proxima Nova", sans-serif;
    height: 46px;
    letter-spacing: 2.1px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    width: 100%;
    padding: 12px 25px 12px 15px; }
    .f-input_select-field::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .f-input_select-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
    @media screen and (max-width: 767px) {
      .f-input_select-field {
        height: 56px; } }
    .f-input_select-field > option {
      background: #fff;
      color: #202025; }
    @media screen and (max-width: 767px) {
      .f-input_select-field {
        padding-bottom: 17px;
        padding-top: 17px; } }
    .f-input_select-field:disabled {
      border-color: #D2D2CE;
      color: #D2D2CE; }
      .f-input_select-field:disabled::before {
        color: #D2D2CE; }
    .f-input_select-field:required:not(.m-valid) {
      color: #202025; }
    .f-input_select-field:required option:disabled {
      display: none; }

.f-input_textarea-field {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  min-width: 0;
  vertical-align: baseline;
  width: 100%;
  border: 1px solid #A7A8A8;
  border-radius: 0;
  color: #202025;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  letter-spacing: 2.1px;
  line-height: 24px;
  padding: 20px 25px;
  resize: vertical;
  width: 100%; }
  .f-input_textarea-field::placeholder {
    color: transparent;
    text-transform: capitalize; }
  .f-input_textarea-field:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: inherit;
    transition-delay: 9999s;
    transition-property: all; }
  .f-input_textarea-field:focus, .f-input_textarea-field:hover {
    border-color: #202025; }
  .f-input_textarea-field.m-invalid, .f-input_textarea-field:disabled {
    border-color: #D2D2CE;
    color: #D2D2CE; }

.f-input_textarea-label {
  color: #6A6A67;
  cursor: pointer;
  display: flex;
  font: 600 12px/16px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  transform-origin: left top;
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 5px; }

.f-input_textarea.m-required .f-input_textarea-label::after {
  content: '*'; }

/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Init accordion on sm, md & lg devices

```
.g-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.g-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

## Simple accordion example

```html_example
<div
    data-widget="accordion"
    data-event-keydown="handleKeydown"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="g-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		class="g-accordion-item"
	>
		<h2
			data-ref="accordionItemBtn"
			data-event-click.prevent="togglePanel"
			data-event-keydown="handleKeydown"
			class="g-accordion-title"
		>
			<button
				class="g-accordion-button"
				type="button"
			>
				Title 1
			</button>
		</h2>
		<div
			data-ref="accordionItemPanel"
			class="g-accordion-content"
		>
			<div class="g-accordion-content_inner" data-ref="accordionItemPanelInner">
				Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
			</div>
		</div>
	</section>
</div>
```

*/
.g-accordion-item {
  border-bottom: 1px solid #D2D2CE; }

.g-accordion-title {
  cursor: pointer; }

.g-accordion-control {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  letter-spacing: 0.3px;
  line-height: 24px;
  padding: 30px 0;
  width: 100%; }
  .g-accordion-control::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 14px;
    mask: url("./svg-icons/sprite.svg#plus"), url("./svg-icons/sprite.svg#minus");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%, 0;
    width: 14px; }
  .g-accordion-control.m-opened::after {
    mask-size: 0, 100%; }

.g-accordion-content {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: height, opacity;
  visibility: hidden; }
  .g-accordion-content.m-opened {
    height: auto;
    opacity: 1;
    visibility: visible; }

.g-accordion-content_inner {
  overflow: hidden;
  padding: 0 0 25px; }

/*md

# Headings

## Heading 1

```html_example
<h1 class="t-heading_1">Heading 1</h1>
```
## Heading 2

```html_example
<h2 class="t-heading_2">Heading 2</h2>
```
## Heading 3

```html_example
<h3 class="t-heading_3">Heading 3</h3>
```
## Heading 4

```html_example
<h4 class="t-heading_4">Heading 4</h4>
```
## Heading 5

```html_example
<h5 class="t-heading_5">Heading 5</h5>
```
## Heading 6

```html_example
<h6 class="t-heading_6">Heading 6</h6>
```

# Paragraphs

## Paragraphs 1

```html_example
<p class="t-paragraph_1">Paragraph 1</p>
```

## Paragraphs 2

```html_example
<p class="t-paragraph_2">Paragraph 2</p>
```

## Paragraphs 3

```html_example
<p class="t-paragraph_3">Paragraph 3</p>
```

# Links

## Link 1

```html_example
<a href="#" class="t-link_1">Link 1</a>
```
## Link 2

```html_example
<a href="#" class="t-link_2">Link 2</a>
```
## Link 3

```html_example
<a href="#" class="t-link_3">Link 3</a>
```
## Link 4 (link with icon)

### Link 4 (edit)

```html_example
<a href="#" class="t-link_4 m-edit">Edit link</a>
```

### Link 4 (remove)

```html_example
<a href="#" class="t-link_4 m-remove">Remove link</a>

```

### Link 4 (grape via inline svg)

You can control icons dimensions (height and width) using `style` attribute and
`--link-icon_height`, `--link-icon_width` CSS custom properties.

Check the examples below.

Inlined SVG variant:

```html_example
<a href="#" class="t-link_4" style="--link-icon_height: 30px; --link-icon_width: 30px">
	<svg class="b-icon_loader" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" viewBox="0 0 36.8 61">
		<path class="b-icon_loader-grape m-1" d="M18.4 22.6a6 6 0 11-6-5.9 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-4" d="M24.3 33.4a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-3" d="M12.4 33.4a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-5" d="M36.3 33.4a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-2" d="M30.3 22.6a6 6 0 11-6-5.9 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-7" d="M30.3 43.9a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-6" d="M18.4 43.9a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-8" d="M24.3 54.5a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-branch" d="M18.4 22.6S17.6 6.5 25.7.5M19.8 10.3A5.9 5.9 0 0015.5 3a4.7 4.7 0 00-5.7 3.5 3.8 3.8 0 002.7 4.6 3 3 0 003.7-2.3"/>
	</svg>
	Grape icon link
</a>
```

The same result can be achieved in content assets
with less code
(using `$tools` helper)

```
<a href="#" class="t-link_4" style="--link-icon_height: 30px; --link-icon_width: 30px">
	$tools.icon('loader')
	Grape icon link
</a>
```

## Link 5

```html_example
<a href="#" class="t-link_5">Link 5</a>
```

# Lists

## Unordered List

```html_example
<ul class="t-unordered_list">
	<li>item 1</li>
	<li>item 2</li>
	<li>item 3</li>
	<li>item 4</li>
</ul>
```

## Ordered List

```html_example
<ol class="t-ordered_list">
	<li>item 1</li>
	<li>item 2</li>
	<li>item 3</li>
	<li>item 4</li>
</ol>
```

*/
.t-heading_1 {
  font: 600 40px/56px "Proxima Nova", sans-serif;
  letter-spacing: 0.6px; }
  @media screen and (max-width: 1199px) {
    .t-heading_1 {
      font-size: 40px;
      letter-spacing: 0.5px;
      line-height: 48px; } }

.t-heading_2 {
  font: 400 32px/48px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px; }
  @media screen and (max-width: 1199px) {
    .t-heading_2 {
      font-size: 32px;
      letter-spacing: 0.4px;
      line-height: 40px; } }

.t-heading_3 {
  font: 400 24px/40px "Proxima Nova", sans-serif;
  letter-spacing: 0.4px; }
  @media screen and (max-width: 1199px) {
    .t-heading_3 {
      font-size: 26px;
      letter-spacing: 0.325px;
      line-height: 32px; } }

.t-heading_4 {
  font: 400 28px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.35px; }
  @media screen and (max-width: 1199px) {
    .t-heading_4 {
      font-size: 24px;
      letter-spacing: 0.3px; } }

.t-heading_5 {
  font: 700 22px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.3px; }

.t-heading_6 {
  font: 700 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 2.4px;
  text-transform: uppercase; }

.t-paragraph_1 {
  font: 18px/26px "Proxima Nova", sans-serif;
  letter-spacing: 0.2px; }

.t-paragraph_2 {
  font: 16px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px; }

.t-paragraph_3 {
  font: 14px/1.57 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px; }

.t-paragraph_4 {
  font: 15px/1.57 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px; }

.t-link_1 {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: 600;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 7px; }
  .t-link_1:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_1:hover {
    text-decoration: none; }
  .t-link_1.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .t-link_1:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_1:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .t-link_1:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .t-link_1.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }

.t-link_2 {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: bold;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 0; }
  .t-link_2:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_2:hover {
    text-decoration: none; }
  .t-link_2.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .t-link_2:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_2:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .t-link_2:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .t-link_2.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }

.t-link_3 {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: bold;
  letter-spacing: 0.16px;
  font-size: 16px;
  letter-spacing: 2.4px;
  line-height: 24px;
  text-transform: uppercase; }
  .t-link_3:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_3::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 13px;
    mask: url("./svg-icons/sprite.svg#line-arrow");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 50px;
    display: inline-block; }
  .t-link_3:hover {
    text-decoration: none; }
    .t-link_3:hover::after {
      animation: link-arrow 0.4s ease; }

.t-link_4 {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: bold;
  letter-spacing: 0.16px;
  align-items: center;
  display: inline-flex;
  letter-spacing: 2.1px;
  text-decoration: none;
  text-transform: uppercase; }
  .t-link_4:hover {
    text-decoration: none; }
  .t-link_4:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_4 svg {
    display: inline-block;
    height: var(--link-icon_height, 8px);
    margin-right: 5px;
    width: var(--link-icon_width, 8px); }

.t-link_4.m-edit {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: bold;
  letter-spacing: 0.16px;
  align-items: center;
  display: inline-flex;
  letter-spacing: 2.1px;
  text-decoration: none;
  text-transform: uppercase; }
  .t-link_4.m-edit:hover {
    text-decoration: none; }
  .t-link_4.m-edit:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_4.m-edit::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 1em;
    mask: url("./svg-icons/sprite.svg#edit");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em;
    display: inline-block;
    margin-right: 5px; }
  .t-link_4.m-edit svg {
    display: inline-block;
    height: var(--link-icon_height, 8px);
    margin-right: 5px;
    width: var(--link-icon_width, 8px); }

.t-link_4.m-remove {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: bold;
  letter-spacing: 0.16px;
  align-items: center;
  display: inline-flex;
  letter-spacing: 2.1px;
  text-decoration: none;
  text-transform: uppercase; }
  .t-link_4.m-remove:hover {
    text-decoration: none; }
  .t-link_4.m-remove:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_4.m-remove::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 0.9em;
    mask: url("./svg-icons/sprite.svg#remove");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em;
    display: inline-block;
    margin-right: 5px; }
  .t-link_4.m-remove svg {
    display: inline-block;
    height: var(--link-icon_height, 8px);
    margin-right: 5px;
    width: var(--link-icon_width, 8px); }

.t-link_5 {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: normal;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 0;
  background-size: 0 0; }
  .t-link_5:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_5:hover {
    text-decoration: none; }
  .t-link_5.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .t-link_5:visited {
    color: var(--color-link-visited, #584C44); }
  .t-link_5:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .t-link_5:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .t-link_5.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }

.t-unordered_list {
  list-style: disc inside; }
  .t-unordered_list li {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px; }

.t-ordered_list {
  list-style: decimal inside; }
  .t-ordered_list li {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px; }

.b-account_menu {
  padding: 35px 25px; }
  @media screen and (max-width: 1199px) {
    .b-account_menu {
      background-color: #fff;
      border-bottom: 1px solid #584C44;
      border-top: 1px solid #584C44; } }
  .b-account_menu-title {
    font-size: 22px;
    line-height: 1;
    margin-bottom: 25px; }
  .b-account_menu-item + .b-account_menu-item {
    display: block;
    margin-top: 25px; }

.b-login_panel-title {
  font-family: "Proxima Nova", sans-serif;
  font-size: 22px;
  line-height: 1; }
  @media screen and (min-width: 1200px) {
    .b-login_panel-title {
      border-bottom: 1px solid #D2D2CE;
      font-size: 26px;
      margin-bottom: 10px;
      padding-bottom: 35px; } }

.b-login_panel-row {
  margin-top: 25px; }

.b-login_panel-link {
  align-items: center;
  display: inline-flex;
  font: 16px/22px "Proxima Nova", sans-serif; }
  .b-login_panel-link.m-favorites::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 11px;
    mask: url("./svg-icons/sprite.svg#heart");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 12px;
    margin-left: 5px; }
  .b-login_panel-link.m-favorites_active::after {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 11px;
    mask: url("./svg-icons/sprite.svg#wishlist-active");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 12px; }
  @media screen and (min-width: 1200px) {
    .b-login_panel-link.m-sign_in {
      align-items: center;
      background: none;
      border: 0;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      max-width: 100%;
      text-align: center;
      text-transform: uppercase;
      user-select: none;
      vertical-align: top;
      word-break: break-word;
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      font-weight: 800;
      height: 60px;
      letter-spacing: 3px;
      line-height: 1.2;
      padding: 5px 25px;
      transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
      transition-property: background-color, color, border;
      background: var(--button_main-background_color, #BA8D36);
      border: 2px solid var(--button_main-border_color, #BA8D36);
      color: var(--button_main-text_color, #202025);
      border-radius: 0;
      width: 100%; }
      .b-login_panel-link.m-sign_in:hover {
        text-decoration: none; } }
  @media screen and (min-width: 1200px) and (hover: hover) and (pointer: fine) {
    .b-login_panel-link.m-sign_in:hover {
      background: var(--button_main__hover-background_color, #fff);
      border-color: var(--button_main__hover-border_color, #BA8D36);
      border-radius: 0;
      color: var(--button_main__hover-text_color, #202025); } }
  @media screen and (min-width: 1200px) {
      .b-login_panel-link.m-sign_in:disabled:not(.m-processing), .b-login_panel-link.m-sign_in.m-disabled:not(.m-processing) {
        pointer-events: none;
        opacity: 1;
        background-color: #D2D2CE;
        color: #fff;
        border: 2px solid #D2D2CE;
        border-radius: 0; } }
    @media screen and (min-width: 1200px) and (max-width: 1199px) {
      .b-login_panel-link.m-sign_in:disabled:not(.m-processing), .b-login_panel-link.m-sign_in.m-disabled:not(.m-processing) {
        padding: 5px 0; } }
  @media screen and (min-width: 1200px) {
      .b-login_panel-link.m-sign_in.m-small {
        font-weight: 700;
        height: 40px;
        padding: 8px 15px; }
      .b-login_panel-link.m-sign_in.m-width_s {
        width: var(--button-s); }
      .b-login_panel-link.m-sign_in.m-width_m {
        width: var(--button-m); }
      .b-login_panel-link.m-sign_in.m-width_l {
        width: var(--button-l); }
      .b-login_panel-link.m-sign_in.m-processing {
        cursor: wait; } }

.event-enquiry-container {
  padding: 16px;
  text-align: center;
  margin: auto; }
  @media screen and (min-width: 768px) {
    .event-enquiry-container {
      width: 40%; } }
  .event-enquiry-container-content-header {
    padding-top: 74px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.31;
    padding-bottom: 48px; }
  .event-enquiry-container-content-description {
    padding-bottom: 84px; }
  .event-enquiry-container-form {
    text-align: left; }
    .event-enquiry-container-form-footer-privacy {
      line-height: 16px;
      font-size: 12px;
      letter-spacing: 0.12px; }
    .event-enquiry-container-form-footer-submit {
      margin: auto;
      display: flex;
      margin-top: 48px; }
      @media screen and (min-width: 768px) {
        .event-enquiry-container-form-footer-submit {
          width: 45%; } }

.event-enquiry-confirmation {
  display: none;
  padding: 16px;
  text-align: center;
  margin: auto; }
  @media screen and (min-width: 768px) {
    .event-enquiry-confirmation {
      width: 40%; } }
  .event-enquiry-confirmation-header {
    padding-top: 74px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.31;
    padding-bottom: 32px; }
  .event-enquiry-confirmation-description {
    padding-bottom: 54px; }
  .event-enquiry-confirmation-button {
    width: 52%; }
    @media screen and (max-width: 1199px) {
      .event-enquiry-confirmation-button {
        width: 90%; } }

.event-enquiry-carouselcontainer {
  padding: 0 57px; }
  @media screen and (max-width: 767px) {
    .event-enquiry-carouselcontainer {
      padding: 0 19px; } }

.b-social_login_flyout-header {
  font-size: 13px;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .b-social_login_flyout-header {
      margin-bottom: 25px; } }

.b-social_login_flyout-btns {
  display: flex; }

.b-social_login_flyout-btn {
  box-shadow: none;
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: box-shadow; }
  @media screen and (max-width: 767px) {
    .b-social_login_flyout-btn {
      padding: 15px 10px; } }
  .b-social_login_flyout-btn:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 5px rgba(0, 0, 0, 0.11); }
  .b-social_login_flyout-btn svg {
    margin-right: 10px; }

.b-social_login_flyout-btn.m-facebook {
  background-color: #4267b2;
  color: #fff;
  margin-right: 15px; }

.b-social_login_flyout-btn.m-google {
  background: #fff;
  border: 1px solid #202025; }

.b-social_login_flyout-text {
  color: #181818; }

.b-complete_profile-title {
  font: 400 24px/40px "Proxima Nova", sans-serif;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
  margin-top: 50px;
  text-align: center; }
  @media screen and (max-width: 1199px) {
    .b-complete_profile-title {
      font-size: 26px;
      letter-spacing: 0.325px;
      line-height: 32px; } }
  @media screen and (max-width: 767px) {
    .b-complete_profile-title {
      margin-bottom: 20px;
      margin-top: 30px; } }

.b-complete_profile-note {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  text-align: center; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-complete_profile-note {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-complete_profile-note {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-complete_profile-note {
      padding-left: 15px;
      padding-right: 15px; } }

.b-complete_profile-align_form {
  padding-top: 55px; }

.b-minicart_popup {
  font-size: 0; }
  .b-minicart_popup-button, .b-minicart_popup-link {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    padding: 15px; }
    .b-minicart_popup-button::before, .b-minicart_popup-link::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 16px;
      mask: url("./svg-icons/sprite.svg#bag");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 13px; }
    @media screen and (max-width: 1199px) {
      .b-minicart_popup-button, .b-minicart_popup-link {
        padding: 10px; } }
    .b-minicart_popup-button:hover, .b-minicart_popup-link:hover {
      text-decoration: none; }
  .b-minicart_popup-qty {
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 19px;
    margin-left: 5px; }

.b-search_dialog {
  height: 100%;
  opacity: 0;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  width: 100%;
  z-index: 2; }
  .b-search_dialog.m-active {
    opacity: 1; }
  .b-search_dialog::after {
    background-color: #fff;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: visibility 0.5s, opacity 0.5s;
    visibility: hidden;
    z-index: 1; }
  .b-search_dialog.m-active_suggestions::after {
    opacity: 1;
    visibility: visible; }
  .b-search_dialog-inner {
    background-color: transparent;
    background-image: linear-gradient(180deg, #fff 23.32%, rgba(255, 255, 255, 0.75) 100%);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 60px;
    position: relative;
    transition: background-color 0.3s;
    width: 100%;
    z-index: 8; }
    @media screen and (max-width: 767px) {
      .b-search_dialog-inner {
        background: linear-gradient(180deg, #fff 14.83%, rgba(255, 255, 255, 0.9) 100%);
        padding: 50px 35px 0; } }
  .b-search_dialog-inner_top_content {
    margin: 0 auto;
    max-width: 914px; }
  .b-search_dialog-form_wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .b-search_dialog-form_wrap {
        margin-bottom: 60px; } }
  .b-search_dialog .b-button_close {
    position: absolute;
    right: 0;
    top: 0; }
    @media screen and (min-width: 768px) {
      .b-search_dialog .b-button_close {
        right: 15px;
        top: 15px; } }

.b-shipping_panel::after {
  background-color: rgba(210, 210, 206, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: visibility 0.4s, opacity 0.4s, left 0.4s;
  visibility: hidden;
  z-index: 1; }

.b-shipping_panel.m-opened::after {
  opacity: 1;
  visibility: visible; }

.b-shipping_panel-inner {
  bottom: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 460px;
  z-index: 2;
  left: -100%;
  transition: visibility 0.5s, opacity 0.5s, left 0.5s;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-shipping_panel-inner {
      width: 50%; } }
  @media screen and (max-width: 767px) {
    .b-shipping_panel-inner {
      width: 100%; } }

.b-shipping_panel.m-opened .b-shipping_panel-inner {
  opacity: 1;
  visibility: visible;
  left: 0; }

.b-shipping_panel.m-dialog_active .b-shipping_panel-inner {
  opacity: 1;
  overflow: visible;
  visibility: visible; }

.b-shipping_panel-close {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px; }
  .b-shipping_panel-close::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 16px;
    mask: url("./svg-icons/sprite.svg#close");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 16px; }

.b-shipping_panel.m-image_background .b-shipping_panel-top_wrap {
  background: #202025 center center/cover;
  color: #fff; }

.b-shipping_panel-top {
  border-bottom: 1px solid #D2D2CE;
  max-height: 75vh;
  padding: 40px 35px 40px 30px; }
  @media screen and (max-width: 767px) {
    .b-shipping_panel-top {
      padding: 30px 30px 30px 35px; } }

.b-shipping_panel.m-image_background .b-shipping_panel-top {
  border-bottom: none; }

.b-shipping_panel.m-image_background .b-shipping_panel-close {
  color: #fff; }

.b-shipping_panel-title {
  font-family: "Proxima Nova", sans-serif;
  font-size: 26px;
  letter-spacing: 0.3px;
  line-height: 40px;
  margin-bottom: 10px; }
  @media screen and (max-width: 1199px) {
    .b-shipping_panel-title {
      font-size: 22px; } }

.b-shipping_panel-description {
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 22px; }

.b-shipping_states {
  height: 100%;
  overflow-y: hidden;
  padding: 10px 5px 30px 8px;
  position: relative; }
  .b-shipping_states::after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 84.81%);
    bottom: 0;
    content: '';
    height: 144px;
    left: 0;
    max-height: 15vh;
    pointer-events: none;
    position: absolute;
    right: 0; }
  .b-shipping_states-inner {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 114px; }
  .b-shipping_states-item {
    position: relative; }
    .b-shipping_states-item.browsing {
      opacity: 0;
      visibility: hidden;
      height: 0; }
  .b-shipping_states-radio {
    height: 36px;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: -1; }
  .b-shipping_states-label {
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 36px;
    padding-left: 35px;
    transition: background-color 0.3s; }
    .b-shipping_states-label:hover {
      background-color: #F6F6F5; }
  .b-shipping_states-radio:checked + .b-shipping_states-label {
    font-weight: bold;
    position: relative; }
    .b-shipping_states-radio:checked + .b-shipping_states-label::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 10px;
      mask: url("./svg-icons/sprite.svg#checkmark-plain");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 13px;
      position: absolute;
      right: 44px;
      top: 13px; }
  .b-shipping_states-label_note {
    color: #A7A8A8;
    font-size: 12px;
    margin-left: 10px; }

.b-suggestions_category {
  text-decoration: none; }
  .b-suggestions_category-title {
    font-weight: bold; }
  .b-suggestions_category:hover, .b-suggestions_category.m-focused {
    text-decoration: underline; }
  .b-suggestions_category-parent_title {
    font-weight: normal; }

.b-suggestions_product {
  display: flex;
  font-size: 12px; }
  .b-suggestions_product-picture_wrap {
    margin-right: 20px; }
  .b-suggestions_product-picture {
    display: block;
    max-width: 100%;
    width: 70px; }
  .b-suggestions_product-title {
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase; }
  .b-suggestions_product-price {
    font-weight: bold; }

.b-header_message {
  bottom: 0;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
  padding: 30px 15px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 17; }
  .b-header_message-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    font-weight: 400; }
    .b-header_message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-header_message-link:hover {
      text-decoration: none; }
    .b-header_message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-header_message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-header_message-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-header_message-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-header_message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-header_message.m-javascript {
    background: #FAEDED;
    color: #CA1E36; }
  .b-header_message.m-cookies {
    background: #D2D2CE;
    color: #202025; }

.b-suggestions {
  flex-grow: 1;
  letter-spacing: 0.7px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative; }
  @media screen and (min-width: 768px) {
    .b-suggestions {
      margin: 0 auto;
      max-width: 100%;
      width: calc(914px + 3px); } }
  .b-suggestions-inner {
    display: grid; }
    @media screen and (min-width: 768px) {
      .b-suggestions-inner {
        grid-template-columns: repeat(3, 1fr);
        margin: 0 -30px;
        padding: 0 3px; } }
  .b-suggestions-section {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .b-suggestions-section {
        margin: 0 30px; } }
    .b-suggestions-section.m-guess {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      margin-top: 3px; }
      @media screen and (min-width: 768px) {
        .b-suggestions-section.m-guess {
          grid-area: 1 / 1 / 2 / 4; } }
    @media screen and (min-width: 768px) {
      .b-suggestions-section.m-view_all {
        grid-area: 3 / 1 / 4 / 4; } }
    .b-suggestions-section.m-no_results {
      font-size: 18px;
      margin-bottom: 40px; }
      @media screen and (min-width: 768px) {
        .b-suggestions-section.m-no_results {
          grid-area: 1 / 1 / 2 / 4; } }
  .b-suggestions-guess_item {
    margin-bottom: 5px; }
    @media not all and (pointer: coarse) {
      .b-suggestions-guess_item:hover {
        text-decoration: underline; } }
    .b-suggestions-guess_item:last-child {
      margin-bottom: 30px; }
      @media screen and (min-width: 768px) {
        .b-suggestions-guess_item:last-child {
          margin-bottom: 45px; } }
  .b-suggestions-guess_correction {
    font-weight: bold;
    text-transform: uppercase; }
  .b-suggestions-title {
    font-weight: bold;
    margin-bottom: 25px; }
  @media screen and (max-width: 767px) {
    .b-suggestions-section.m-products .b-suggestions-title {
      margin-bottom: 20px; } }
  .b-suggestions-option {
    margin-bottom: 25px; }
  @media (hover: none) and (pointer: coarse) {
    .b-suggestions-option.m-focused {
      outline: none !important;
      /* stylelint-disable-line declaration-no-important */ } }
  @media screen and (min-width: 768px) {
    .b-suggestions-section.m-products .b-suggestions-option {
      margin-bottom: 15px; } }
  @media screen and (max-width: 767px) {
    .b-suggestions-section.m-products, .b-suggestions-section.m-categories, .b-suggestions-section.m-content {
      border-bottom: 1px solid #D2D2CE;
      margin-bottom: 25px; } }
  .b-suggestions-message {
    border: 0 ;
    clip: rect(1px, 1px, 1px, 1px) ;
    margin: 0 ;
    max-height: 1px ;
    max-width: 1px ;
    overflow: hidden ;
    padding: 0 ;
    position: absolute ; }
  .b-suggestions-view_results {
    align-items: center;
    display: flex;
    font-weight: bold;
    letter-spacing: 3px;
    margin-top: 35px;
    text-transform: uppercase; }
    .b-suggestions-view_results::after {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 12px;
      mask: url("./svg-icons/sprite.svg#line-arrow");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 51px;
      margin-left: 10px; }
    @media screen and (max-width: 767px) {
      .b-suggestions-view_results {
        margin-top: 15px; } }
  .b-suggestions .b-product_price {
    font-size: 12px; }
  .b-suggestions-categories-list, .b-suggestions-content-list {
    display: flex;
    flex-direction: column; }

.b-footer {
  background: #fff;
  color: var(--color-footer-text, #202025);
  display: grid;
  font-family: "Proxima Nova", sans-serif;
  gap: 0 var(--grid-gutter);
  grid-template: 'contacts   customer_service   need_help   email' 'contacts   customer_service   need_help   social' 1fr 'bottom     bottom             bottom      bottom' / 1fr 1fr 1fr 1fr;
  padding-left: 60px;
  padding-right: 60px; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-footer {
      grid-template: 'contacts customer_service' 'email    need_help' 'social   .' 'bottom   bottom' / 1fr 1fr;
      row-gap: 50px; } }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    .b-footer {
      grid-template: 'contacts customer_service need_help' 'email    social           .' 'bottom   bottom           bottom' / 1fr 1fr 1fr; } }
  @media screen and (max-width: 767px) {
    .b-footer {
      grid-template: "email" "contacts" "customer_service" "need_help" "social" "bottom"/minmax(0, 1fr);
      padding-left: 15px;
      padding-right: 15px; } }
  .b-footer-email_subscription {
    padding: 45px 0; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-footer-email_subscription {
        padding: 0; } }
    @media screen and (max-width: 767px) {
      .b-footer-email_subscription {
        margin: 0 -15px;
        padding: 40px 15px; } }
  .b-footer-contacts {
    grid-area: contacts; }
    .b-footer-contacts .content-asset {
      padding: 65px 0 0; }
      @media screen and (max-width: 767px) {
        .b-footer-contacts .content-asset {
          padding: 30px 0 40px; } }
  .b-footer-customer_service {
    grid-area: customer_service; }
    .b-footer-customer_service .content-asset {
      padding: 65px 0 0; }
      @media screen and (max-width: 767px) {
        .b-footer-customer_service .content-asset {
          padding: 30px 0 15px; } }
  .b-footer-need_help {
    grid-area: need_help; }
    .b-footer-need_help .content-asset {
      padding: 65px 0 0; }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        .b-footer-need_help .content-asset {
          padding: 0; } }
      @media screen and (min-width: 1024px) and (max-width: 1199px) {
        .b-footer-need_help .content-asset {
          padding: 65px 0 0; } }
      @media screen and (max-width: 767px) {
        .b-footer-need_help .content-asset {
          padding: 30px 0 40px; } }
  .b-footer-social {
    grid-area: social; }
    .b-footer-social .content-asset {
      padding-bottom: 35px; }
      @media screen and (max-width: 767px) {
        .b-footer-social .content-asset {
          padding: 30px 0; } }
  @media screen and (max-width: 767px) {
    .b-footer-contacts .content-asset, .b-footer-customer_service .content-asset, .b-footer-need_help .content-asset, .b-footer-social .content-asset {
      border-top: 1px solid rgba(210, 210, 206, 0.15);
      text-align: center; } }
  .b-footer-bottom_info {
    border-top: 1px solid rgba(210, 210, 206, 0.15);
    display: flex;
    flex-flow: row-reverse;
    grid-area: bottom;
    justify-content: space-between;
    padding: 30px 0; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-footer-bottom_info {
        display: block; } }
    @media screen and (max-width: 767px) {
      .b-footer-bottom_info {
        align-items: center;
        flex-flow: column; } }
  .b-footer-newsletter_title {
    font: 400 24px/40px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px;
    margin-bottom: 15px; }
    @media screen and (max-width: 1199px) {
      .b-footer-newsletter_title {
        font-size: 26px;
        letter-spacing: 0.325px;
        line-height: 32px; } }
    @media screen and (max-width: 767px) {
      .b-footer-newsletter_title {
        margin-bottom: 20px;
        text-align: center; } }
  .b-footer-newsletter_description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 35px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-footer-newsletter_description {
        padding-right: 10px; } }
    @media screen and (max-width: 767px) {
      .b-footer-newsletter_description {
        text-align: center; } }
  .b-footer-copyright {
    font-size: 14px;
    letter-spacing: 0.14px;
    line-height: 22px;
    margin: 0;
    opacity: 0.7; }
    @media screen and (max-width: 767px) {
      .b-footer-copyright {
        font-size: 12px;
        text-align: center; } }
  .b-footer.enable-footer-image {
    background: #fff url("./images/footer-bg.jpg") no-repeat center center/cover;
    color: var(--color-footer-text, #202025); }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-footer.enable-footer-image {
        background-image: url("./images/footer-bg-tablet.jpg"); } }
    @media screen and (max-width: 767px) {
      .b-footer.enable-footer-image {
        background-image: url("./images/footer-bg-mobile.jpg"); } }

.gift-order-msg,
.gift-payment-msg {
  text-align: center;
  padding: 24px 0;
  margin-top: 15px; }
  .gift-order-msg .msg,
  .gift-payment-msg .msg {
    text-transform: capitalize;
    padding: 20px;
    border: 1px solid var(--button_main-background_color, #BA8D36); }

.gift-payment-msg {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    .gift-payment-msg {
      min-height: 25vh; } }

.l-sitemap {
  display: grid;
  grid-template-columns: 2fr 6fr; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .l-sitemap {
      grid-template-columns: 1fr 6fr;
      grid-column-gap: 10px; } }
  @media screen and (max-width: 767px) {
    .l-sitemap {
      display: block; } }
  .l-sitemap-heading {
    font: 400 24px/40px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px;
    padding: 20px 16px 0 16px; }
    @media screen and (max-width: 1199px) {
      .l-sitemap-heading {
        font-size: 26px;
        letter-spacing: 0.325px;
        line-height: 32px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .l-sitemap-heading {
        padding: 40px 10px;
        text-align: center; } }
  .l-sitemap-content {
    padding: 0 16px; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .l-sitemap-content {
        padding-left: 10px; } }
    .l-sitemap-content a.sitemap-text {
      color: #202025; }
  .l-sitemap_title {
    font: 700 22px/36px "Proxima Nova", sans-serif;
    letter-spacing: 0.3px;
    padding: 40px 0 20px; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .l-sitemap_title {
        font: 700 16px/24px "Proxima Nova", sans-serif;
        letter-spacing: 2.4px;
        text-transform: uppercase; } }
    @media screen and (max-width: 767px) {
      .l-sitemap_title {
        padding: 10px 0; } }
  .l-sitemap_detail {
    padding-top: 75px; }
    @media screen and (max-width: 767px) {
      .l-sitemap_detail {
        padding: 0 16px; } }
  .l-sitemap_category_container {
    display: grid;
    gap: 10px 10px;
    grid-template-columns: repeat(6, 1fr); }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .l-sitemap_category_container {
        font-size: x-small;
        column-gap: 10px;
        overflow-wrap: inherit; } }
    @media screen and (max-width: 767px) {
      .l-sitemap_category_container {
        display: block;
        padding-top: 0; } }
    .l-sitemap_category_container__title {
      text-transform: uppercase; }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        .l-sitemap_category_container__title {
          padding: 0; } }
    .l-sitemap_category_container__categories {
      line-height: 25px; }

@media screen and (max-width: 767px) {
  .customer-service {
    margin-bottom: 32px;
    border-bottom: 1px solid #A7A8A8; } }

.customer-service .title {
  font-size: 18px;
  margin: 40px 0 16px; }
  .customer-service .title.contactus-generalinfo {
    font-weight: bold;
    position: relative;
    width: 100%;
    border-top: 1px solid #A7A8A8;
    padding: 16px 0;
    margin: 32px 0 0 0;
    text-align: left; }
    .customer-service .title.contactus-generalinfo::after {
      position: absolute;
      content: '';
      right: 16px;
      top: 20px;
      transform: rotate(45deg);
      height: 12px;
      width: 12px;
      border-bottom: 1px solid #202025;
      border-right: 1px solid #202025; }
    .customer-service .title.contactus-generalinfo[aria-expanded="true"]::after {
      transform: rotate(225deg);
      top: 26px; }

.customer-service-content li {
  margin-bottom: 8px; }

@media screen and (max-width: 767px) {
  .h-sm-hidden {
    display: none; } }

.b-footer_navigation-title {
  font: 700 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 15px; }
  @media screen and (max-width: 767px) {
    .b-footer_navigation-title {
      margin-bottom: 20px; } }

.b-footer_navigation-content {
  align-items: start;
  display: flex;
  flex-flow: column;
  font-size: 16px;
  line-height: 24px; }
  @media screen and (max-width: 767px) {
    .b-footer_navigation-content {
      align-items: center; } }

.b-footer_navigation-link {
  color: var(--color-link, var(--color-footer-text, #202025));
  cursor: pointer;
  display: inline;
  font-weight: normal;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 0;
  background-size: 0 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px; }
  .b-footer_navigation-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-footer_navigation-link:hover {
    text-decoration: none; }
  .b-footer_navigation-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .b-footer_navigation-link:visited {
    color: var(--color-link-visited, #202025); }
  .b-footer_navigation-link:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-footer_navigation-link:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-footer_navigation-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  @media screen and (max-width: 767px) {
    .b-footer_navigation-link {
      margin-bottom: 20px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-footer_navigation-link.sitemap-text {
      font-size: 12px; } }

.b-footer_info-title {
  font: 700 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 15px; }
  @media screen and (max-width: 767px) {
    .b-footer_info-title {
      margin-bottom: 20px; } }

.b-footer_info-content {
  font: 16px/24px "Proxima Nova", sans-serif; }

.b-footer_info-text {
  margin-bottom: 15px; }
  @media screen and (max-width: 767px) {
    .b-footer_info-text {
      margin-bottom: 20px; } }

.b-footer_info-link {
  color: var(--color-link, var(--color-footer-text, #202025));
  cursor: pointer;
  display: inline;
  font-weight: normal;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 0;
  background-size: 0 0;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px; }
  .b-footer_info-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-footer_info-link:hover {
    text-decoration: none; }
  .b-footer_info-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .b-footer_info-link:visited {
    color: var(--color-link-visited, #202025); }
  .b-footer_info-link:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-footer_info-link:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-footer_info-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  @media screen and (max-width: 767px) {
    .b-footer_info-link.m-info {
      color: var(--color-link, #202025);
      cursor: pointer;
      display: inline;
      font-weight: bold;
      letter-spacing: 0.16px;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 90%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      padding-bottom: 0; }
      .b-footer_info-link.m-info:visited {
        color: var(--color-link-visited, #584C44); }
      .b-footer_info-link.m-info:hover {
        text-decoration: none; }
      .b-footer_info-link.m-info.m-disabled {
        color: var(--color-link-disabled, #D2D2CE);
        pointer-events: none; }
      .b-footer_info-link.m-info:visited {
        color: var(--color-link-visited, #584C44); }
      .b-footer_info-link.m-info:hover {
        text-decoration: none; } }
    @media screen and (max-width: 767px) and (hover: hover) and (pointer: fine) {
      .b-footer_info-link.m-info:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  @media screen and (max-width: 767px) {
      .b-footer_info-link.m-info.m-disabled {
        color: var(--color-link-disabled, #D2D2CE);
        pointer-events: none; } }

.b-footer_info-text .b-footer_info-link {
  margin: 0; }

.b-footer_social-title {
  font: bold 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 2.4px;
  margin-bottom: 15px;
  text-transform: uppercase; }

.b-footer_social-links {
  display: flex;
  justify-content: flex-start;
  column-gap: 16px; }
  @media screen and (max-width: 767px) {
    .b-footer_social-links {
      justify-content: center; } }

.b-footer_social-link.m-twitter::before {
  background: var(--icon-color, #BA8D36);
  content: '';
  display: block;
  height: 45px;
  mask: url("./svg-icons/sprite.svg#twitter");
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 45px; }

.b-footer_social-link.m-instagram::before {
  background: var(--icon-color, #BA8D36);
  content: '';
  display: block;
  height: 45px;
  mask: url("./svg-icons/sprite.svg#instagram");
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 45px; }

.b-footer_social-link.m-facebook::before {
  background: var(--icon-color, #BA8D36);
  content: '';
  display: block;
  height: 45px;
  mask: url("./svg-icons/sprite.svg#facebook");
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 45px; }

.b-footer_social-link.m-pinterest::before {
  background: var(--icon-color, #BA8D36);
  content: '';
  display: block;
  height: 45px;
  mask: url("./svg-icons/sprite.svg#pinterest");
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 45px; }

.b-footer_social-link.m-youtube::before {
  background: var(--icon-color, #BA8D36);
  content: '';
  display: block;
  height: 45px;
  mask: url("./svg-icons/sprite.svg#youtube");
  mask-position: 50%;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 45px; }

.b-newsletters .f-input_text {
  position: relative; }
  .b-newsletters .f-input_text-field {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    min-width: 0;
    vertical-align: baseline;
    width: 100%;
    font: 700 16px/22px "Proxima Nova", sans-serif;
    height: 46px;
    letter-spacing: 2.1px;
    line-height: 22px;
    transition: height linear 0.5s;
    border: 1px solid #A7A8A8;
    border-width: 0 0 1px;
    color: #202025;
    padding-top: 15px; }
    .b-newsletters .f-input_text-field::placeholder {
      color: transparent;
      text-transform: capitalize; }
    .b-newsletters .f-input_text-field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: inherit;
      transition-delay: 9999s;
      transition-property: all; }
    @media screen and (max-width: 767px) {
      .b-newsletters .f-input_text-field {
        height: 56px; } }
    .b-newsletters .f-input_text-field:hover {
      border-color: #202025; }
    .b-newsletters .f-input_text-field.m-invalid {
      color: #CA1E36; }
    .b-newsletters .f-input_text-field:disabled {
      border-color: #D2D2CE;
      color: #D2D2CE; }
    .b-newsletters .f-input_text-field:focus::placeholder {
      color: #6A6A67; }
  .b-newsletters .f-input_text-label {
    color: #6A6A67;
    cursor: pointer;
    display: flex;
    font: 600 12px/16px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform-origin: left top;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    left: 15px;
    pointer-events: none;
    position: absolute;
    top: 14px;
    left: 0; }
    @media screen and (max-width: 767px) {
      .b-newsletters .f-input_text-label {
        top: 19px; } }
  .b-newsletters .f-input_text.m-required .b-newsletters .f-input_text-label::after {
    content: '*'; }
  .b-newsletters .f-input_text-field:not(:placeholder-shown) ~ .b-newsletters .f-input_text-label,
  .b-newsletters .f-input_text-field:focus ~ .b-newsletters .f-input_text-label {
    transform: translateY(-40%) scale(0.75); }
  .b-newsletters .f-input_text-field:disabled ~ .b-newsletters .f-input_text-label {
    color: #D2D2CE; }

.b-newsletters-group {
  position: relative; }

.b-newsletters-input {
  border-color: #A7A8A8;
  color: #202025;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase; }
  .b-newsletters-input.m-invalid {
    color: #202025; }

.b-newsletters-submit {
  background: none;
  border: 0;
  cursor: pointer;
  height: 15px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: top linear 0.5s; }
  .b-newsletters-submit::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 1em;
    mask: url("./svg-icons/sprite.svg#line-arrow");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 50px; }
  .b-newsletters-submit:hover {
    background: none; }

.b-newsletters-message_success {
  border-bottom: 1px solid #A7A8A8;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.8px;
  line-height: 24px;
  margin-bottom: 25px;
  padding: 10px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase; }
  .b-newsletters-message_success::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 15px;
    mask: url("./svg-icons/sprite.svg#checkmark");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 15px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: text-top; }

.b-newsletters .f-input_text-label {
  color: var(--color-footer-text, #202025);
  line-height: 24px;
  top: 10px; }

.b-newsletters .f-input_text-field {
  padding: 21px 60px 7px 0; }

.b-newsletters .f-input_text-field::placeholder, .b-newsletters .f-input_text-field:focus::placeholder {
  color: transparent; }

.b-newsletters .f-input_text-field, .b-newsletters .f-input_text-field.m-invalid {
  color: #202025; }

.b-newsletters .f-input_text-field.m-focused, .b-newsletters .f-input_text-field:hover {
  border-color: #A7A8A8; }

.b-newsletters .f-field-error {
  background: rgba(255, 255, 255, 0.55);
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-top: 4px;
  padding: 10px 0 10px 35px;
  position: absolute;
  top: 100%;
  width: 100%; }
  .b-newsletters .f-field-error::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 15px;
    mask: url("./svg-icons/sprite.svg#error");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 17px;
    left: 10px;
    position: absolute; }

.b-footer_legal-links {
  display: flex;
  margin-left: -15px;
  margin-right: -15px; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-footer_legal-links {
      flex-wrap: wrap;
      margin-bottom: 15px; } }
  @media screen and (max-width: 767px) {
    .b-footer_legal-links {
      align-items: center;
      flex-flow: column;
      margin: 0 0 20px; } }

.b-footer_legal-link {
  color: var(--color-link, var(--color-footer-text, #202025));
  cursor: pointer;
  display: inline;
  font-weight: normal;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 0;
  background-size: 0 0;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 22px;
  margin: 0 15px; }
  .b-footer_legal-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-footer_legal-link:hover {
    text-decoration: none; }
  .b-footer_legal-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .b-footer_legal-link:visited {
    color: var(--color-link-visited, #202025); }
  .b-footer_legal-link:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-footer_legal-link:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-footer_legal-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-footer_legal-link {
      margin: 0 15px 15px; } }
  @media screen and (max-width: 767px) {
    .b-footer_legal-link {
      margin: 0 0 20px; } }

.b-age_gate {
  background: #fff url("../images/age-gate-background.jpg") no-repeat center center/cover;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10; }
  @media screen and (max-width: 767px) {
    .b-age_gate {
      background-image: url("../images/age-gate-background.jpg"); } }
  .b-age_gate-inner {
    color: #202025;
    column-gap: var(--grid-gutter);
    display: grid;
    grid-template: [start header-s] auto [header-e content-s] auto [content-e footer-s] 1fr [end footer-e]/[start footer-s] 1fr [] 1fr [] 1fr [content-s] 1fr [] 1fr [header-s] 1fr [] 1fr [header-e] 1fr [] 1fr [content-e] 1fr [] 1fr [] 1fr [end footer-e];
    height: 100%;
    overflow-y: auto;
    padding: 0 var(--grid-margin);
    text-align: center; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-age_gate-inner {
        grid-template: [start header-s] auto [header-e content-s] auto [content-e footer-s] 1fr [end footer-e]/[start footer-s] 1fr [] 1fr [content-s] 1fr [] 1fr [] 1fr [header-s] 1fr [] 1fr [header-e] 1fr [] 1fr [] 1fr [content-e] 1fr [] 1fr [end footer-e]; } }
    @media screen and (max-width: 767px) {
      .b-age_gate-inner {
        grid-template: [start header-s] auto [header-e content-s] auto [content-e footer-s] 1fr [end footer-e]/[start header-s content-s footer-s] 1fr [] 1fr [] 1fr [] 1fr [end header-e content-e footer-e]; } }
  .b-age_gate-header {
    grid-area: header-s / header-s / header-e / header-e;
    justify-self: center;
    margin: 50px 0 100px; }
    @media screen and (max-width: 767px) {
      .b-age_gate-header {
        margin: 50px 0 60px; } }
  .b-age_gate-main {
    grid-area: content-s / content-s / content-e / content-e; }
  .b-age_gate-footer {
    align-self: end;
    grid-area: footer-s / footer-s / footer-e / footer-e;
    margin-bottom: 20px; }
  .b-age_gate-title {
    font: 600 40px/56px "Proxima Nova", sans-serif;
    letter-spacing: 0.6px;
    margin-bottom: 40px; }
    @media screen and (max-width: 1199px) {
      .b-age_gate-title {
        font-size: 40px;
        letter-spacing: 0.5px;
        line-height: 48px; } }
    @media screen and (max-width: 767px) {
      .b-age_gate-title {
        margin-bottom: 50px; } }
  .b-age_gate-subtitle {
    font: 16px/1.5 "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    margin: 0 auto 20px; }
  .b-age_gate-description {
    font: 14px/1.57 "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    margin-bottom: 20px; }
  .b-age_gate-button {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_main-background_color, #BA8D36);
    border: 2px solid var(--button_main-border_color, #BA8D36);
    color: var(--button_main-text_color, #202025);
    border-radius: 0;
    display: block;
    margin: 0 auto 20px;
    width: 335px; }
    .b-age_gate-button:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-age_gate-button:hover {
        background: var(--button_main__hover-background_color, #fff);
        border-color: var(--button_main__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_main__hover-text_color, #202025); } }
    .b-age_gate-button:disabled:not(.m-processing), .b-age_gate-button.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #D2D2CE;
      color: #fff;
      border: 2px solid #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-age_gate-button:disabled:not(.m-processing), .b-age_gate-button.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-age_gate-button.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-age_gate-button.m-width_s {
      width: var(--button-s); }
    .b-age_gate-button.m-width_m {
      width: var(--button-m); }
    .b-age_gate-button.m-width_l {
      width: var(--button-l); }
    .b-age_gate-button.m-processing {
      cursor: wait; }
  .b-age_gate-button_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    font-size: 14px;
    margin-bottom: 50px; }
    .b-age_gate-button_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-age_gate-button_link:hover {
      text-decoration: none; }
    .b-age_gate-button_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-age_gate-button_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-age_gate-button_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-age_gate-button_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-age_gate-button_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-age_gate-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    font-size: 14px;
    font-weight: 400; }
    .b-age_gate-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-age_gate-link:hover {
      text-decoration: none; }
    .b-age_gate-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-age_gate-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-age_gate-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-age_gate-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-age_gate-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-age_gate .b-footer-bottom_info {
    border: 0; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-age_gate .b-footer_legal-links {
      justify-content: center; } }

.age-gate-overlay {
  height: 100%;
  width: 100%; }

.age-gate-faded {
  position: fixed;
  height: 65%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.5);
  z-index: 10; }
  @media screen and (max-width: 767px) {
    .age-gate-faded {
      height: 55%; } }

.age-gate-popover {
  padding: 40px 56px;
  display: grid;
  grid-column-gap: 120px;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "cookies_asset cookies_buttons";
  position: fixed;
  background: #ffffff;
  height: 35%;
  overflow: auto;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .age-gate-popover {
      grid-column-gap: 20px; } }
  @media screen and (max-width: 767px) {
    .age-gate-popover {
      height: 45%;
      padding: 40px 19px;
      grid-template-columns: 1fr;
      grid-template-areas: "cookies_asset" "cookies_buttons"; } }

.cookies-and-privacy-policy-asset {
  grid-area: cookies_asset;
  display: flex;
  align-items: end; }
  @media screen and (max-width: 767px) {
    .cookies-and-privacy-policy-asset {
      text-align: center;
      align-items: center; } }

.accept-cookies-buttons {
  grid-area: cookies_buttons;
  display: flex;
  align-items: end; }
  @media screen and (max-width: 767px) {
    .accept-cookies-buttons {
      display: block; } }
  .accept-cookies-buttons button {
    height: 48px;
    width: 208px;
    margin: 0px 5px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .accept-cookies-buttons button {
        width: 100%;
        font-size: 12px; } }
    @media screen and (max-width: 767px) {
      .accept-cookies-buttons button {
        width: 100%; } }

.age-gate-overlay {
  height: 100%;
  width: 100%; }

.age-gate-faded {
  position: fixed;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.5);
  z-index: 10; }

.age-gate-popover {
  padding: 40px 56px;
  display: grid;
  grid-column-gap: 120px;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "cookies_asset cookies_buttons";
  align-items: end;
  position: fixed;
  background: #ffffff;
  height: auto;
  overflow: auto;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }
  @media screen and (max-width: 1199px) {
    .age-gate-popover {
      padding: 40px 19px;
      grid-template-columns: 1fr;
      grid-template-areas: "cookies_asset" "cookies_buttons"; } }

.cookies-and-privacy-policy-asset {
  grid-area: cookies_asset;
  align-items: end; }
  @media screen and (max-width: 1199px) {
    .cookies-and-privacy-policy-asset {
      text-align: center;
      align-items: center;
      margin-bottom: 12px; } }

.accept-cookies-buttons {
  grid-area: cookies_buttons;
  align-items: end;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px; }
  @media screen and (max-width: 1199px) {
    .accept-cookies-buttons {
      display: block; } }
  .accept-cookies-buttons button {
    height: 48px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    @media screen and (max-width: 1199px) {
      .accept-cookies-buttons button {
        margin: 12px 0; } }

/*md

# b-highlighter

Focus highlighter CSS part. It draw focus ring over focused element.

We have two types of focus highlighting. In case if custom highlighter is not initialized will be shown default outline highlighter.

### Default outline highlighter
```html_example
<br>
<div>
	<input class="b-input m-focused" type="text" placeholder="Default outline example" style="width:240px;">
</div>
<br>
```

### Custom highlighter
```html_example
<br>
<div style="position:relative;">
	<div class="b-highlighter_inited">
		<input class="b-input" type="text" placeholder="Custom highlighter example" style="width:240px;">
	</div>
	<div class="b-highlighter m-visible" style="top:-5px; left:-4px; width:241px; height:50px;"></div>
</div>
<br>
```
*/
.b-highlighter {
  border: 4px solid transparent;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 #3466e5;
  box-sizing: content-box;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1.2);
  transition-duration: 0.15s;
  transition-property: top, left, width, height, visibility, transform, opacity;
  transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
  visibility: hidden;
  z-index: 15; }
  .b-highlighter.m-visible {
    opacity: 1;
    transform: scale(1);
    visibility: visible; }
  .b-highlighter.m-hurry {
    transition-duration: 0.1s; }

*:focus,
.h-focused {
  outline: 3px solid #3466e5 !important; }

.b-highlighter_inited *:focus {
  outline: none !important; }

.b-continue_shopping {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font: 600 14px/1.5 "Proxima Nova", sans-serif;
  text-decoration: none; }
  .b-continue_shopping::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 14px;
    mask: url("./svg-icons/sprite.svg#arrow-left-thin");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 8px;
    margin-right: 10px; }
  .b-continue_shopping:hover {
    text-decoration: none; }

/*md

# b-link_phone

Phone link with **tel:[number]** scheme

```html_example
<a href="tel:1-888-222-3380" class="b-link_phone">1-888-222-3380</a>
```
*/
.b-link_phone {
  white-space: nowrap; }
  @media screen and (max-width: 767px) {
    .b-link_phone {
      text-decoration: underline; } }
  @media (hover: hover) and (pointer: fine) {
    .b-link_phone:hover {
      text-decoration: underline; } }
  .b-link_phone-icon {
    margin-right: 5px; }

.b-dialog {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: visibility cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  visibility: hidden;
  z-index: var(--dialog-z, 1);
  --dialog-z: 11; }
  .b-dialog::after {
    background-color: transparent;
    bottom: -200px;
    content: '';
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: -200px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
    transition-property: opacity, background-color;
    z-index: -1; }
  .b-dialog.m-opened {
    overflow-y: scroll;
    visibility: visible; }
    .b-dialog.m-opened::after {
      background-color: rgba(210, 210, 206, 0.5);
      opacity: 1; }
  .b-dialog-window {
    background-color: #fff;
    margin-bottom: auto;
    max-width: 960px;
    opacity: 0;
    padding: 60px 60px 70px;
    position: relative;
    transform: scale(0.9);
    transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
    visibility: hidden;
    width: auto; }
    @media screen and (min-width: 768px) {
      .b-dialog-window {
        margin-top: auto; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-dialog-window {
        padding: 50px 50px 60px; } }
    @media screen and (max-width: 767px) {
      .b-dialog-window {
        padding: 40px 40px 50px; } }
  .b-dialog.m-opened .b-dialog-window {
    opacity: 1;
    transform: scale(1);
    visibility: visible; }
  .b-dialog-title {
    font: 400 32px/48px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    margin-bottom: 30px; }
    @media screen and (max-width: 1199px) {
      .b-dialog-title {
        font-size: 32px;
        letter-spacing: 0.4px;
        line-height: 40px; } }
  .b-dialog-body {
    font-size: 18px;
    line-height: 26px; }
  .b-dialog-note {
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 26px;
    margin-bottom: 20px; }
  .b-dialog-note.m-bold {
    font-weight: 700; }
  .b-dialog .b-button_close {
    position: absolute;
    right: 15px;
    top: 15px; }
    @media screen and (max-width: 767px) {
      .b-dialog .b-button_close {
        right: 0;
        top: 0; } }
  .b-dialog-footer {
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      .b-dialog-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 40px; } }
  @media screen and (min-width: 768px) {
    .b-dialog-footer.m-center {
      justify-content: center; } }
  .b-dialog-btn {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .b-dialog-btn {
        flex: 1 1 100%;
        width: auto; } }
  .b-dialog-btn + .b-dialog-btn {
    margin-left: 20px; }
    @media screen and (max-width: 767px) {
      .b-dialog-btn + .b-dialog-btn {
        margin: 20px 0 0 0; } }
  .b-dialog-btn.m-main {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_main-background_color, #BA8D36);
    border: 2px solid var(--button_main-border_color, #BA8D36);
    color: var(--button_main-text_color, #202025);
    border-radius: 0; }
    .b-dialog-btn.m-main:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-dialog-btn.m-main:hover {
        background: var(--button_main__hover-background_color, #fff);
        border-color: var(--button_main__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_main__hover-text_color, #202025); } }
    .b-dialog-btn.m-main:disabled:not(.m-processing), .b-dialog-btn.m-main.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #D2D2CE;
      color: #fff;
      border: 2px solid #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-dialog-btn.m-main:disabled:not(.m-processing), .b-dialog-btn.m-main.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-dialog-btn.m-main.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-dialog-btn.m-main.m-width_s {
      width: var(--button-s); }
    .b-dialog-btn.m-main.m-width_m {
      width: var(--button-m); }
    .b-dialog-btn.m-main.m-width_l {
      width: var(--button-l); }
    .b-dialog-btn.m-main.m-processing {
      cursor: wait; }
  .b-dialog-btn.m-alt {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0; }
    .b-dialog-btn.m-alt:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-dialog-btn.m-alt:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-dialog-btn.m-alt:disabled:not(.m-processing), .b-dialog-btn.m-alt.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-dialog-btn.m-alt:disabled:not(.m-processing), .b-dialog-btn.m-alt.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-dialog-btn.m-alt.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-dialog-btn.m-alt.m-width_s {
      width: var(--button-s); }
    .b-dialog-btn.m-alt.m-width_m {
      width: var(--button-m); }
    .b-dialog-btn.m-alt.m-width_l {
      width: var(--button-l); }
    .b-dialog-btn.m-alt.m-processing {
      cursor: wait; }
  @media screen and (min-width: 768px) {
    .b-dialog-btn:first-child:last-child {
      flex: 0 0 auto; } }
  .b-dialog-reject {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0;
    width: 100%; }
    .b-dialog-reject:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-dialog-reject:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-dialog-reject:disabled:not(.m-processing), .b-dialog-reject.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-dialog-reject:disabled:not(.m-processing), .b-dialog-reject.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-dialog-reject.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-dialog-reject.m-width_s {
      width: var(--button-s); }
    .b-dialog-reject.m-width_m {
      width: var(--button-m); }
    .b-dialog-reject.m-width_l {
      width: var(--button-l); }
    .b-dialog-reject.m-processing {
      cursor: wait; }
  .b-dialog-confirm {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_main-background_color, #BA8D36);
    border: 2px solid var(--button_main-border_color, #BA8D36);
    color: var(--button_main-text_color, #202025);
    border-radius: 0;
    width: 100%; }
    .b-dialog-confirm:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-dialog-confirm:hover {
        background: var(--button_main__hover-background_color, #fff);
        border-color: var(--button_main__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_main__hover-text_color, #202025); } }
    .b-dialog-confirm:disabled:not(.m-processing), .b-dialog-confirm.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #D2D2CE;
      color: #fff;
      border: 2px solid #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-dialog-confirm:disabled:not(.m-processing), .b-dialog-confirm.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-dialog-confirm.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-dialog-confirm.m-width_s {
      width: var(--button-s); }
    .b-dialog-confirm.m-width_m {
      width: var(--button-m); }
    .b-dialog-confirm.m-width_l {
      width: var(--button-l); }
    .b-dialog-confirm.m-processing {
      cursor: wait; }
    @media screen and (max-width: 767px) {
      .b-dialog-confirm {
        margin-top: 20px; } }
    @media screen and (min-width: 768px) {
      .b-dialog-confirm {
        margin-left: 15px; } }
  @media screen and (min-width: 1200px) {
    .b-dialog.m-quick_view .b-dialog-window {
      max-width: 100%;
      padding: 0 110px 60px 35px;
      width: 1264px; } }
  .b-dialog.m-search_suggestions {
    --dialog-z: 8;
    align-items: flex-start;
    animation: fade-in cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    overflow-y: auto;
    padding: 0; }
    .b-dialog.m-search_suggestions::after {
      touch-action: none; }
    .b-dialog.m-search_suggestions:not(.m-opened) {
      display: none; }
  .b-dialog.m-reset_password .b-dialog-window,
  .b-dialog.m-promotion .b-dialog-window,
  .b-dialog.m-browsing_only .b-dialog-window,
  .b-dialog.m-remove_product .b-dialog-window {
    min-width: auto;
    width: 680px;
    margin-top: auto; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-dialog.m-reset_password .b-dialog-window,
      .b-dialog.m-promotion .b-dialog-window,
      .b-dialog.m-browsing_only .b-dialog-window,
      .b-dialog.m-remove_product .b-dialog-window {
        width: 630px; } }
  .b-dialog.m-browsing_only .b-dialog-footer {
    flex-wrap: wrap; }
    .b-dialog.m-browsing_only .b-dialog-footer .b-dialog-btn {
      flex: 1 1 48%; }
  .b-dialog.m-delete_address .b-dialog-window {
    max-width: 100%;
    min-width: auto;
    width: 524px; }

/*md

# b-user_content

This component is used to provide regular document styling in places where content managment team could
insert rich HTML markup.

## Headings

```html_example
<div class="b-user_content">
	<h1>General Information</h1>
	<h2>General Information</h2>
	<h3>General Information</h3>
	<h4>General Information</h4>
	<h5>General Information</h5>
</div>
```

## Paragraphs

```html_example
<div class="b-user_content">
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```

## An unordered list

```html_example
<div class="b-user_content">
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
</div>
```

## An ordered list

```html_example
<div class="b-user_content">
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
</div>
```

## Full page

```html_example
<div class="b-user_content">
	<h1>
		Privacy Policy
	</h1>
	<h2>General Information</h2>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```
*/
.b-user_content > h1 {
  font: 600 40px/56px "Proxima Nova", sans-serif;
  letter-spacing: 0.6px;
  margin-bottom: 40px; }
  @media screen and (max-width: 1199px) {
    .b-user_content > h1 {
      font-size: 40px;
      letter-spacing: 0.5px;
      line-height: 48px; } }

.b-user_content > h2 {
  font: 400 32px/48px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px; }
  @media screen and (max-width: 1199px) {
    .b-user_content > h2 {
      font-size: 32px;
      letter-spacing: 0.4px;
      line-height: 40px; } }

.b-user_content > h3 {
  font: 400 24px/40px "Proxima Nova", sans-serif;
  letter-spacing: 0.4px; }
  @media screen and (max-width: 1199px) {
    .b-user_content > h3 {
      font-size: 26px;
      letter-spacing: 0.325px;
      line-height: 32px; } }

.b-user_content > h4 {
  font: 400 28px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.35px; }
  @media screen and (max-width: 1199px) {
    .b-user_content > h4 {
      font-size: 24px;
      letter-spacing: 0.3px; } }

.b-user_content > h5 {
  font: 700 22px/36px "Proxima Nova", sans-serif;
  letter-spacing: 0.3px; }

.b-user_content > h2,
.b-user_content > h3,
.b-user_content > h4,
.b-user_content > h5 {
  margin-bottom: 20px;
  margin-top: 40px; }
  .b-user_content > h2:first-child,
  .b-user_content > h3:first-child,
  .b-user_content > h4:first-child,
  .b-user_content > h5:first-child {
    margin-top: 0; }

.b-user_content p {
  margin-bottom: 20px; }

.b-user_content ul,
.b-user_content ol {
  display: block;
  margin-bottom: 20px;
  padding-left: 25px; }

.b-user_content li {
  display: list-item;
  margin-bottom: 5px; }

.b-user_content ul {
  list-style: disc outside; }

.b-user_content ol {
  list-style: decimal outside; }

/*md
# Expandable block

## Default state

```html_example
	<div class="b-expandable m-collapsed">
		<div class="b-expandable-content">
			Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi,
			quasi voluptatum! Maxime magni totam ipsa unde enim maiores perferendis.
			Officiis temporibus impedit amet aliquam dolore corrupti mollitia,
			soluta beatae perspiciatis optio saepe, autem delectus a quos dolor
			quisquam porro! Enim inventore tempora facilis atque possimus deleniti
			quia vel omnis nisi quo, saepe hic suscipit voluptas ratione accusamus
		</div>
		<button class="b-expandable-link">
			<span class="b-expandable-link_text m-more">View more</span>
			<span class="b-expandable-link_text m-less">View less</span>
		</button>
	</div>
```
*/
.b-expandable-content {
  max-height: 1000px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s ease; }

.b-expandable-link {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: 600;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 7px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px; }
  .b-expandable-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-expandable-link:hover {
    text-decoration: none; }
  .b-expandable-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .b-expandable-link:visited {
    color: var(--color-link-visited, #584C44); }
  .b-expandable-link:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-expandable-link:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .b-expandable-link.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }

.b-expandable-link_text.m-more {
  display: none; }

.b-expandable.m-collapsed .b-expandable-link_text.m-more {
  display: block; }

.b-expandable.m-collapsed .b-expandable-link_text.m-less {
  display: none; }

.b-expandable.m-collapsed .b-expandable-content {
  max-height: var(--collapsed-height); }

.b-expandable.m-product_description .b-product_description-upc,
.b-expandable.m-product_description .b-product_description-id {
  max-height: 50px;
  transition: 500ms ease;
  transition-property: margin, max-height; }

.b-expandable.m-product_description.m-collapsed {
  --collapsed-height: 190px; }
  @media screen and (max-width: 767px) {
    .b-expandable.m-product_description.m-collapsed {
      --collapsed-height: 237px; } }
  .b-expandable.m-product_description.m-collapsed .b-product_description-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .b-expandable.m-product_description.m-collapsed .b-product_description-text {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        overflow: hidden; } }
  .b-expandable.m-product_description.m-collapsed .b-product_description-upc,
  .b-expandable.m-product_description.m-collapsed .b-product_description-id {
    margin: 0;
    max-height: 1px;
    visibility: hidden; }

.b-expandable.m-bundled-products.m-collapsed {
  --collapsed-height: 0; }

/*md

# Global messages

## Info message

```html_example
<div class="b-message">
	Your wish list is only available on this device and expire after 7 days.
	Sign in or Create account to keep your items always at hand.
</div>
```

## Promo message

```html_example
<div class="b-message m-promo">
	Today only! Buy 6 bottles and get 20% OFF
</div>
```

## Error message

```html_example
<div class="b-message m-error">
	Oops! It looks like some of the items below are no longer available.
	Please update your shopping cart to continue
</div>
```

*/
.b-message {
  align-items: center;
  background: #fff;
  border: 2px solid #D2D2CE;
  border-radius: 3px;
  color: #202025;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  line-height: 24px;
  padding: 15px 35px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .b-message {
      padding: 20px; } }
  .b-message.m-promo {
    background: rgba(246, 246, 245, 0.2);
    border-color: #F6F6F5;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase; }
  .b-message.m-error {
    background: #FAEDED;
    border: none;
    color: #CA1E36; }
  .b-message-accent_text {
    font-weight: 700; }
  .b-message-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: normal;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0; }
    .b-message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-message-link:hover {
      text-decoration: none; }
    .b-message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-message-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-message-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-message-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-message-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-separated_list {
  display: flex; }
  @media screen and (max-width: 767px) {
    .b-separated_list {
      flex-direction: column; } }
  .b-separated_list-devider {
    margin: 0 10px; }
    @media screen and (max-width: 767px) {
      .b-separated_list-devider {
        display: none; } }
    .b-separated_list-devider::before {
      content: '|'; }

.b-reserve_bar--cart > div {
  display: flex; }

.b-rb_cart {
  position: relative;
  z-index: 10; }
  .b-rb_cart--bg {
    inset: 0px;
    background: transparent;
    transition: all ease 0.5s; }
    .b-rb_cart--bg.show {
      position: fixed;
      background: rgba(0, 0, 0, 0.15); }
  .b-rb_cart--inner {
    inset: 0px;
    overflow: hidden; }
  .b-rb_cart--container {
    position: absolute;
    inset: 0px;
    overflow: hidden; }
  .b-rb_cart--column {
    pointer-events: none;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0;
    display: flex;
    max-width: 100%;
    padding-left: 2.5rem; }
  .b-rb_cart--drawer {
    pointer-events: none;
    width: 100vw;
    max-width: 28rem;
    transition: all ease-in-out 0.5s;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: scroll;
    background-color: #fff;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: 0 0 #202025, 0 20px 25px -5px RGB(0, 0, 0, 0.1), 0 8px 10px -6px RGB(0, 0, 0, 0.1), 0 0 #202025; }
    .b-rb_cart--drawer.translate-x-0 {
      pointer-events: all; }
    .b-rb_cart--drawer__header h2 {
      font-size: 2rem; }
    .b-rb_cart--drawer__header > div:first-child {
      padding-left: 1rem;
      padding-right: 1rem; }
      @media screen and (max-width: 767px) {
        .b-rb_cart--drawer__header > div:first-child {
          padding-left: 1.5rem;
          padding-right: 1.5rem; } }
      .b-rb_cart--drawer__header > div:first-child div:not(.b-rb_cart--drawer__close) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #202025; }
    .b-rb_cart--drawer__close {
      display: flex;
      align-items: center;
      margin-left: 0.75rem;
      height: 1.75rem; }
      .b-rb_cart--drawer__close button {
        position: relative;
        z-index: 50;
        cursor: pointer; }
      .b-rb_cart--drawer__close span {
        position: absolute;
        inset: -0.625rem; }
      .b-rb_cart--drawer__close svg {
        height: 1.5rem;
        width: 1.5rem; }
  .b-rb_cart--list {
    position: relative;
    flex: 1 1 0%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1.5rem; }
    @media screen and (max-width: 767px) {
      .b-rb_cart--list {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    .b-rb_cart--list__inner > div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem; }
    .b-rb_cart--list__item {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      border-bottom: 1px solid #202025;
      padding: 1rem 0;
      position: relative; }
      .b-rb_cart--list__item img {
        object-fit: contain;
        width: 100px;
        height: 100px; }
      .b-rb_cart--list__item button {
        position: absolute;
        left: 98%;
        top: 0;
        width: 15px;
        height: 15px;
        cursor: pointer; }
      .b-rb_cart--list__item .cartQty {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem; }
        .b-rb_cart--list__item .cartQty select {
          cursor: pointer; }
      .b-rb_cart--list__item .volume {
        font-style: italic; }
      .b-rb_cart--list__item p:not(.cartQty) {
        font-size: rem(12px); }
    .b-rb_cart--list button.g-button_alt, .b-rb_cart--list button.g-button_main {
      width: 100%; }
    .b-rb_cart--list button.g-button_alt {
      margin-top: 1.5rem; }
  .b-rb_cart--qty {
    color: #fff;
    background: #202025;
    padding: 1px 6px;
    border-radius: 50%;
    font-size: 10px;
    position: absolute;
    left: calc(100% - 16px);
    top: 5px;
    display: none; }
    .b-rb_cart--qty.show {
      display: block; }
  .b-rb_cart--subtotal {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem; }
  .b-rb_cart--disclaimer {
    margin-top: 1rem; }

.translate-x-0 {
  transform: translateX(0px); }

.translate-x-full {
  transform: translateX(100%); }

.relative {
  position: relative; }

.b-customer_service_info {
  font-size: 16px;
  line-height: 1.5;
  margin-left: 25px !important; }
  .b-customer_service_info a.b-customer_service_info-link {
    font-size: 11px !important; }
  .b-customer_service_info-title {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase; }
  .b-customer_service_info-title, .b-customer_service_info-msg {
    margin-bottom: 20px; }
  .b-customer_service_info-item {
    margin-bottom: 15px; }
    .b-customer_service_info-item:last-child {
      margin-bottom: 0; }
  .b-customer_service_info-icon {
    display: inline-block;
    margin-right: 15px;
    width: 30px; }
  .b-customer_service_info-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: normal;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    background-size: 0 0; }
    .b-customer_service_info-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-customer_service_info-link:hover {
      text-decoration: none; }
    .b-customer_service_info-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-customer_service_info-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-customer_service_info-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-customer_service_info-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-customer_service_info-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-customer_service_info.m-small .b-customer_service_info-title {
    letter-spacing: 0.2px;
    text-transform: none; }
  .b-customer_service_info.m-small .b-customer_service_info-icon {
    margin-right: 10px;
    width: 18px; }
  .b-customer_service_info.m-center {
    text-align: center; }

.m-promo--shipping .meter {
  box-sizing: content-box;
  height: 17px;
  position: relative;
  border-radius: 3px;
  border: 1.5px solid #BA8D36;
  margin: 11px auto 0; }
  .m-promo--shipping .meter span {
    display: block;
    height: 100%;
    border-radius: 0;
    background: linear-gradient(90deg, #FAEDED 0%, #BA8D36 100%);
    position: relative;
    overflow: hidden;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px; }
  .m-promo--shipping .meter.unlocked span {
    background: #BA8D36;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px; }

.m-promo--shipping .message {
  font: 16px/22px "Proxima Nova", sans-serif;
  letter-spacing: 2.34px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: uppercase; }
  .m-promo--shipping .message:before {
    content: '';
    width: 34px;
    height: 32px;
    -webkit-mask-image: url("svg-icons/free-shipping.svg");
    mask-image: url("svg-icons/free-shipping.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    display: block;
    flex-shrink: 0;
    margin-left: 13px;
    background-color: #202025; }
    @media screen and (max-width: 1199px) {
      .m-promo--shipping .message:before {
        margin-left: 15px; } }
  .m-promo--shipping .message--link {
    font: normal normal normal 16px/22px "Proxima Nova", sans-serif;
    letter-spacing: 0.32px; }
    .m-promo--shipping .message--link a {
      text-decoration: underline;
      font-weight: bold; }
    .m-promo--shipping .message--link.unlocked {
      display: none;
      margin: 0; }

.l-cart-inner .l-cart-promo {
  display: flex;
  align-items: flex-end; }
  .l-cart-inner .l-cart-promo.desktop {
    display: flex; }
    @media screen and (max-width: 1199px) {
      .l-cart-inner .l-cart-promo.desktop {
        display: none; } }
  .l-cart-inner .l-cart-promo.mobile {
    display: none; }
    @media screen and (max-width: 1199px) {
      .l-cart-inner .l-cart-promo.mobile {
        display: flex;
        justify-content: center;
        width: 100%;
        border-top: 1px solid rgba(210, 210, 206, 0.8);
        padding-top: 30px;
        border-bottom: 1px solid rgba(210, 210, 206, 0.8);
        padding-bottom: 30px;
        padding: 17px; }
        .l-cart-inner .l-cart-promo.mobile .message {
          text-align: left; } }

.l-cart-inner .m-promo--shipping {
  margin-left: 65px;
  width: 100%;
  margin-bottom: 28px; }
  @media screen and (max-width: 1199px) {
    .l-cart-inner .m-promo--shipping {
      margin-left: 0;
      margin-bottom: 0;
      max-width: 355px; } }
  @media screen and (max-width: 767px) {
    .l-cart-inner .m-promo--shipping {
      max-width: unset; } }

.l-checkout-promo {
  margin-bottom: 32px;
  text-align: center; }
  @media screen and (max-width: 1199px) {
    .l-checkout-promo.desktop {
      display: none; } }
  .l-checkout-promo.mobile {
    display: none; }
    @media screen and (max-width: 1199px) {
      .l-checkout-promo.mobile {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(210, 210, 206, 0.8);
        padding-bottom: 30px;
        padding-bottom: 17px; } }
  .l-checkout-promo .message--link {
    margin-top: 16px; }

@media screen and (max-width: 1199px) {
  .b-minicart_panel-container .m-promo--shipping {
    border-top: 1px solid rgba(210, 210, 206, 0.8);
    padding-top: 30px;
    border-bottom: 1px solid rgba(210, 210, 206, 0.8);
    padding-bottom: 30px;
    padding: 17px 0;
    margin-bottom: 16px; } }

.b-minicart {
  max-height: 100%;
  overflow-y: auto;
  padding: 30px 30px 0; }
  @media screen and (max-width: 1199px) {
    .b-minicart {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas: "header" "messages" "content" "summary" "actions";
      padding: 30px 15px 0; } }
  @media screen and (max-width: 1199px) {
    .b-minicart-header {
      grid-area: header; } }
  @media screen and (max-width: 1199px) {
    .b-minicart-messages {
      grid-area: messages; } }
  @media screen and (max-width: 1199px) {
    .b-minicart-summary {
      grid-area: summary;
      margin-bottom: 0; } }
  @media screen and (max-width: 1199px) {
    .b-minicart-content {
      grid-area: content;
      height: 220px;
      overflow: scroll; }
      .b-minicart-content .b-minicart-item:last-child {
        margin-bottom: 16px; } }
  .b-minicart-actions.mobile {
    display: none; }
    @media screen and (max-width: 1199px) {
      .b-minicart-actions.mobile {
        display: block;
        text-align: center; }
        .b-minicart-actions.mobile .b-minicart-checkout {
          width: auto;
          margin-bottom: 16px; }
        .b-minicart-actions.mobile .b-minicart-view_cart {
          text-decoration: underline;
          color: #202025;
          display: block;
          font-weight: bold; }
          .b-minicart-actions.mobile .b-minicart-view_cart:after {
            display: none; } }
  @media screen and (max-width: 1199px) {
    .b-minicart-actions {
      grid-area: actions; } }
  .b-minicart-title, .b-minicart-item, .b-minicart-summary,
  .b-minicart .b-message {
    margin-bottom: 30px; }
  @media screen and (max-width: 1199px) {
    .b-minicart-summary .b-minicart-actions {
      display: none; } }
  .b-minicart-title {
    font: 26px/40px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px; }
  .b-minicart .b-message {
    padding: 20px; }
  .b-minicart .b-payment_total {
    margin-bottom: 15px;
    width: 100%; }
    .b-minicart .b-payment_total-item {
      justify-content: space-between; }
    @media screen and (max-width: 1199px) {
      .b-minicart .b-payment_total {
        border-bottom: 1px solid rgba(210, 210, 206, 0.8);
        padding-bottom: 30px;
        padding-bottom: 16px; } }
  .b-minicart-item {
    border-bottom: 1px solid rgba(210, 210, 206, 0.8);
    padding-bottom: 30px; }
  .b-minicart-view_cart {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    font-size: 16px;
    letter-spacing: 2.4px;
    line-height: 24px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 16px;
    letter-spacing: 2.4px;
    margin-bottom: 20px; }
    .b-minicart-view_cart:visited {
      color: var(--color-link-visited, #584C44); }
    .b-minicart-view_cart::after {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 13px;
      mask: url("./svg-icons/sprite.svg#line-arrow");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 50px;
      display: inline-block; }
    .b-minicart-view_cart:hover {
      text-decoration: none; }
      .b-minicart-view_cart:hover::after {
        animation: link-arrow 0.4s ease; }
  .b-minicart-checkout {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_main-background_color, #BA8D36);
    border: 2px solid var(--button_main-border_color, #BA8D36);
    color: var(--button_main-text_color, #202025);
    border-radius: 0;
    width: 100%; }
    .b-minicart-checkout:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-minicart-checkout:hover {
        background: var(--button_main__hover-background_color, #fff);
        border-color: var(--button_main__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_main__hover-text_color, #202025); } }
    .b-minicart-checkout:disabled:not(.m-processing), .b-minicart-checkout.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #D2D2CE;
      color: #fff;
      border: 2px solid #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-minicart-checkout:disabled:not(.m-processing), .b-minicart-checkout.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-minicart-checkout.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-minicart-checkout.m-width_s {
      width: var(--button-s); }
    .b-minicart-checkout.m-width_m {
      width: var(--button-m); }
    .b-minicart-checkout.m-width_l {
      width: var(--button-l); }
    .b-minicart-checkout.m-processing {
      cursor: wait; }
  .b-minicart .b-button_close {
    position: absolute;
    right: 5px;
    top: 5px; }
    .b-minicart .b-button_close::before {
      height: 16px;
      width: 16px; }
  .b-minicart .m-promo--shipping {
    margin-bottom: 28px; }
  @media screen and (max-width: 1199px) {
    .b-minicart_panel-container {
      bottom: unset;
      top: 22px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 95%; } }
  .b-minicart-ship_to {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer; }

.b-minicart_product {
  font: 16px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  align-items: start;
  display: grid;
  grid-template-columns: [image-s] 100px [image-e] 15px [details-s] minmax(0, 1fr) [details-e] 20px [remove-s] 22px [remove-e]; }
  .b-minicart_product-image {
    grid-column: image-s/image-e; }
  .b-minicart_product-picture {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
    .b-minicart_product-picture img {
      bottom: 0;
      color: #252525;
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  .b-minicart_product-details {
    grid-column: details-s/details-e; }
  .b-minicart_product .b-cart_product_details-name {
    font-size: 14px;
    line-height: 1.5; }
  .b-minicart_product-qty {
    margin-top: 20px; }
    .b-minicart_product-qty .b-product_quantity {
      height: 40px;
      width: 135px; }
  .b-minicart_product-qty_value {
    font-weight: bold; }
  .b-minicart_product-subtotal {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px; }
  .b-minicart_product-subtotal_label {
    margin-right: 5px; }
  .b-minicart_product-remove {
    grid-column: remove-s/remove-e; }
  .b-minicart_product.m-no_edit {
    grid-template-columns: [image-s] 120px [image-e] 20px [details-s] minmax(0, 1fr) [details-e]; }
  .b-minicart_product.m-no_edit .b-minicart_product-qty_value {
    font-weight: normal; }
  .b-minicart_product.m-no_edit .b-minicart_product-qty_inline {
    text-transform: uppercase; }
  .b-minicart_product.m-no_edit .b-cart_product_details-attributes {
    font-size: 14px; }

.b-cart_product_details-name {
  font: 500 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
  text-transform: capitalize; }

.b-cart_product_details-sku {
  color: #202025;
  font: 14px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  margin-top: 5px; }

.b-cart_product_details-attributes {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 1.5; }

.b-cart_product_details-attribute {
  align-items: center;
  display: flex; }
  .b-cart_product_details-attribute::after {
    background: #D2D2CE;
    content: '';
    height: 12px;
    margin: 0 10px;
    width: 1px; }
  .b-cart_product_details-attribute:last-child::after {
    content: none; }
  .b-cart_product_details-attribute.m-qty {
    text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .b-cart_product_details-attribute.m-qty {
        display: none; } }

@media screen and (min-width: 1200px) {
  .b-cart_product_details-attributes.m-with_qty .b-cart_product_details-attribute:nth-last-child(2)::after {
    content: none; } }

.b-cart_product_details-qty {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 15px; }

.b-cart_product_details-qty_value {
  font-weight: bold; }

.b-cart_product_details-price, .b-cart_product_details-attributes,
.b-cart_product_details .b-product_promo,
.b-cart_product_details .b-product_availability {
  margin-top: 15px; }

.b-cart_product_details .b-product_promo {
  width: fit-content; }

.b-cart_product_details .b-product_availability {
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 1.5; }
  .b-cart_product_details .b-product_availability::before {
    margin-right: 10px; }

.b-cart_product_details .b-product_actions-description {
  font-size: 14px; }

.b-cart_product_details .b-product_actions-description_date {
  font-weight: 700; }

.b-cart_dialog-msg {
  font: 18px/26px "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  margin-bottom: 20px; }

.b-cart_dialog-product {
  font: 700 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 2.4px;
  text-transform: uppercase; }

/*md
# Wishlist CTA

## Default state

```html_example
	<button class="b-add_to_wishlist" aria-label="Add to wishlist"></button>
```

## Active state

```html_example
	<button class="b-add_to_wishlist m-added" aria-label="Add to wishlist"></button>
```

## Variation with label inside - m-with_label
```html_example
	<button class="b-add_to_wishlist m-with_label" aria-label="Add to wishlist">
		<span class="b-add_to_wishlist-label">
			<span class="b-add_to_wishlist-label_text">Favorites</span>
			<span class="b-add_to_wishlist-label_text_added">Added</span>
		</span>
	</button>
```

*/
.b-add_to_wishlist {
  --icon-color: #202025;
  cursor: pointer; }
  .b-add_to_wishlist::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 22px;
    mask: url("./svg-icons/sprite.svg#wishlist"), url("./svg-icons/sprite.svg#wishlist-active");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%, 0;
    width: 25px; }
    @media screen and (max-width: 767px) {
      .b-add_to_wishlist::before {
        height: 14px;
        width: 16px; } }
  @media not all and (pointer: coarse) {
    .b-add_to_wishlist:hover::before {
      mask-size: 0, 100%;
      --icon-color: #BA8D36; } }
  @media screen and (pointer: coarse) and (max-width: 767px) {
    .b-add_to_wishlist:hover::before {
      height: 14px;
      width: 16px; } }
  .b-add_to_wishlist-label {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 24px;
    margin-left: 10px;
    text-transform: uppercase; }
  .b-add_to_wishlist-label_text_added {
    display: none; }
  .b-add_to_wishlist.m-added::before {
    mask-size: 0, 100%;
    --icon-color: #BA8D36; }
    @media screen and (max-width: 767px) {
      .b-add_to_wishlist.m-added::before {
        height: 14px;
        width: 16px; } }
  .b-add_to_wishlist.m-added .b-add_to_wishlist-label_text {
    display: none; }
  .b-add_to_wishlist.m-added .b-add_to_wishlist-label_text_added {
    animation: repaint 0.1s ease;
    display: inline; }
  .b-add_to_wishlist.m-with_label {
    align-items: center;
    display: flex;
    grid-area: wishlist;
    justify-content: center;
    margin: 15px 0 15px 20px; }
    @media screen and (max-width: 1199px) {
      .b-add_to_wishlist.m-with_label {
        margin: 30px 0 0;
        width: 100%; } }
    @media screen and (min-width: 768px) {
      .b-add_to_wishlist.m-with_label::before {
        height: 27px;
        width: 24px; } }

.b-product_tile {
  display: grid;
  font-family: "Proxima Nova", sans-serif;
  grid-template: 'image' 'badge' 'brand' 'name' 'price' 'description' 'promo' 'restriction' 'availability' 'actions' 1fr;
  text-align: center; }
  .b-carousel .b-product_tile {
    padding-bottom: 1px; }
  .b-product_tile-image_wrapper {
    grid-area: image;
    margin-bottom: 20px;
    position: relative; }
    @media screen and (max-width: 767px) {
      .b-product_tile-image_wrapper {
        margin-bottom: 15px; } }
  .b-product_tile-image {
    background: #252525;
    display: block;
    overflow: hidden;
    padding-bottom: 133.33333%;
    position: relative;
    width: 100%;
    background: #fff; }
    .b-product_tile-image.m-not_available {
      opacity: 0.5; }
    .b-product_tile-image img {
      bottom: 0;
      color: #252525;
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  .b-product_tile-brand {
    font-size: 12px;
    font-weight: 700;
    grid-area: brand;
    height: calc(1.8 * 1em);
    letter-spacing: 1px;
    line-height: 1.8;
    margin-bottom: 15px;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap; }
    @media screen and (max-width: 767px) {
      .b-product_tile-brand {
        font-size: 11px;
        margin-bottom: 10px; } }
  .b-product_tile-name {
    cursor: pointer;
    font: 700 16px "Proxima Nova", sans-serif;
    grid-area: name;
    letter-spacing: 2.4px;
    margin-bottom: 15px;
    overflow: hidden;
    text-transform: uppercase; }
    @media screen and (max-width: 767px) {
      .b-product_tile-name {
        --product_tile-name-line_height: 1.4;
        font-size: 14px; } }
    .b-product_tile-name:hover {
      text-decoration: none; }
  .b-product_tile-link {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: calc(2 * var(--product_tile-name-line_height) * 1em);
    line-height: var(--product_tile-name-line_height);
    --product_tile-name-line_height: 1.3; }
    @media screen and (max-width: 767px) {
      .b-product_tile-link {
        --product_tile-name-line_height: 1.4; } }
  .b-product_tile-price {
    grid-area: price;
    margin-bottom: 15px; }
  .b-product_tile .b-product_price {
    font-weight: 600;
    justify-content: center; }
  .b-product_tile-description {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: var(--product_tile-description-lines);
    overflow: hidden;
    --product_tile-description-lines: 1;
    --product_tile-description-line-height: 1.4;
    color: #A7A8A8;
    font-size: 14px;
    font-weight: normal;
    grid-area: description;
    letter-spacing: 0.2px;
    line-height: var(--product_tile-description-line-height);
    margin-bottom: 15px;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .b-product_tile-description {
        --product_tile-description-lines: 2;
        --product_tile-description-line-height: 1.2;
        font-size: 13px; } }
  .b-product_tile-promotion {
    background: #F6F6F5;
    border-radius: 0;
    color: #202025;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 22px;
    padding: 5px 15px;
    text-align: center;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    grid-area: promo;
    margin-bottom: 25px; }
    @media screen and (max-width: 767px) {
      .b-product_tile-promotion {
        font-size: 13px;
        margin-bottom: 20px; } }
  .b-product_tile-restriction {
    color: #CA1E36;
    font-size: 16px;
    grid-area: restriction;
    letter-spacing: 0.2px;
    line-height: 24px;
    margin-bottom: 25px; }
    @media screen and (max-width: 767px) {
      .b-product_tile-restriction {
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-bottom: 15px; } }
  .b-product_tile-availability {
    align-self: end;
    grid-area: availability;
    margin-bottom: 15px; }
    .b-product_tile-availability .b-product_availability {
      font-size: 14px; }
  .b-product_tile-actions {
    align-self: end;
    display: flex;
    grid-area: actions;
    min-width: 0; }
    @media screen and (max-width: 767px) {
      .b-product_tile-actions {
        display: block; } }
    .b-product_tile-actions.m-restriction {
      min-height: 52px; }
      @media screen and (max-width: 767px) {
        .b-product_tile-actions.m-restriction {
          align-items: flex-end;
          display: flex;
          min-height: 104px; } }
    @media screen and (max-width: 767px) {
      .b-carousel .b-product_tile-actions {
        display: flex; } }
  .b-product_tile-quantity {
    width: 40%; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-product_tile-quantity {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .b-product_tile-quantity {
        width: 100%; } }
  .b-product_tile .b-product_quantity {
    height: 52px; }
  .b-product_tile-cta {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_alt-background_color, #fff);
    border: 2px solid var(--button_alt-border_color, #BA8D36);
    color: var(--button_alt-text_color, #202025);
    border-radius: 0;
    height: 52px;
    padding-left: 0;
    padding-right: 0;
    width: 60%; }
    .b-product_tile-cta:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-product_tile-cta:hover {
        background: var(--button_alt__hover-background_color, #BA8D36);
        border-color: var(--button_alt__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_alt__hover-text_color, #202025); } }
    .b-product_tile-cta:disabled:not(.m-processing), .b-product_tile-cta.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #fff;
      color: #D2D2CE;
      border-color: #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-product_tile-cta:disabled:not(.m-processing), .b-product_tile-cta.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-product_tile-cta.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-product_tile-cta.m-width_s {
      width: var(--button-s); }
    .b-product_tile-cta.m-width_m {
      width: var(--button-m); }
    .b-product_tile-cta.m-width_l {
      width: var(--button-l); }
    .b-product_tile-cta.m-processing {
      cursor: wait; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-product_tile-cta {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .b-product_tile-cta {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .b-product_tile-cta.m-short::before {
        background: var(--icon-color, #BA8D36);
        content: '';
        display: block;
        height: 16px;
        mask: url("./svg-icons/sprite.svg#bag");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 13px;
        flex-shrink: 0;
        margin-right: 10px; } }
    @media screen and (max-width: 767px) {
      .b-carousel .b-product_tile-cta.m-short::before {
        display: none; } }
    .b-product_tile-cta.m-short:disabled::before {
      display: none; }
    .b-product_tile-cta.m-wide {
      width: 100%; }
      .b-product_tile-cta.m-wide.h-disabled {
        pointer-events: none;
        opacity: 1;
        background-color: #fff;
        color: #D2D2CE;
        border-color: #D2D2CE;
        border-radius: 0; }
  .b-product_tile.m-list {
    align-items: start;
    border-bottom: 1px solid #D2D2CE;
    display: grid;
    gap: 0 65px;
    grid-template: 'image badge' 'image brand' 'image name' 'image price' 'image description' 'image promo' 'image details' 'image actions' 1fr / 330px auto;
    max-width: 900px;
    text-align: left; }
    @media screen and (max-width: 767px) {
      .b-product_tile.m-list {
        gap: 0 45px;
        grid-template-columns: 165px 1fr; } }
  .b-product_tile.m-list .b-product_tile-image_wrapper {
    margin-bottom: 0; }
  .b-product_tile.m-list .b-product_tile-price {
    justify-content: flex-start;
    margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .b-product_tile.m-list .b-product_tile-price {
        margin-bottom: 15px; } }
  .b-product_tile.m-list .b-product_tile-description,
  .b-product_tile.m-list .b-product_tile-details {
    margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .b-product_tile.m-list .b-product_tile-description,
      .b-product_tile.m-list .b-product_tile-details {
        margin-bottom: 15px; } }
  .b-product_tile.m-list .b-product_tile-promotion {
    margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .b-product_tile.m-list .b-product_tile-promotion {
        line-height: 16px;
        margin-bottom: 0; } }
  .b-product_tile.m-list .b-product_tile-actions {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .b-product_tile.m-list .b-product_tile-actions {
        display: none; } }
  .b-product_tile.m-list .b-product_tile-details {
    font: 14px/1.57 "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    grid-area: details; }
  .b-product_tile.m-list .b-product_tile-cta {
    width: 60%; }
    @media screen and (max-width: 767px) {
      .b-product_tile.m-list .b-product_tile-cta {
        width: 100%; } }
  .b-product_tile .b-product_badge.m-promo {
    left: 20px;
    position: absolute;
    top: 20px; }
    @media screen and (max-width: 767px) {
      .b-product_tile .b-product_badge.m-promo {
        left: 10px;
        top: 10px; } }
  .b-product_tile .b-product_badge.m-rating_wide {
    grid-area: badge;
    margin-bottom: 15px; }
    @media screen and (max-width: 767px) {
      .b-product_tile .b-product_badge.m-rating_wide {
        margin-bottom: 10px; } }
  .b-product_tile.m-list .b-product_badge.m-rating_wide {
    margin: 0; }
  .b-product_tile .b-add_to_wishlist {
    padding: 25px;
    position: absolute;
    right: 0;
    top: 0; }
    @media screen and (max-width: 767px) {
      .b-product_tile .b-add_to_wishlist {
        padding: 15px; } }
  @media screen and (min-width: 768px) {
    .b-product_tile.m-grid .b-product_quantity {
      border-right: 0; } }
  .b-product_tile.m-grid .b-product_tile-details {
    display: none; }
  .b-product_tile.m-page_designer {
    width: 100%; }
  .b-product_tile.m-static {
    max-width: var(--pd_product-maxwidth-xl, 100%); }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-product_tile.m-static {
        max-width: var(--pd_product-maxwidth-l, 100%); } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-product_tile.m-static {
        max-width: var(--pd_product-maxwidth-m, 100%); } }
    @media screen and (max-width: 767px) {
      .b-product_tile.m-static {
        max-width: var(--pd_product-maxwidth-s, 100%); } }

/*md

# Product badges

## Simple promo badge

```html_example
<div class="b-product_badge m-promo">New</div>
```

## Badge with rating

## Wide Badge with rating

```html_example
	<div class="b-product_badge m-rating_wide">
		<div class="b-product_badge-label">92 Wine Spectator</div>
	</div>
```
*/
.b-product_badge-label {
  font-size: 14px;
  font-weight: bold;
  grid-area: label;
  letter-spacing: 0.16px;
  line-height: 24px; }
  @media screen and (max-width: 1199px) {
    .b-product_badge-label {
      font-size: 11px; } }

.b-product_badge-points {
  font-size: 50px;
  line-height: 0.8; }
  @media screen and (max-width: 767px) {
    .b-product_badge-points {
      font-size: 32px; } }

.b-product_badge-text {
  font-size: 16px;
  line-height: 1; }
  @media screen and (max-width: 767px) {
    .b-product_badge-text {
      font-size: 11px; } }

.b-product_badge.m-rating_points {
  color: var(--color-badge_2-color, #bfb9a1);
  font-family: "Proxima Nova", sans-serif;
  text-align: center; }

.b-product_badge.m-rating_wide {
  color: var(--color-badge_2-color, #bfb9a1); }

.b-product_badge.m-rating_wide .b-product_badge-label {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px; }

.b-product_badge.m-promo {
  background: var(--color-badge_1-background, #d8d8d8);
  border-radius: 0;
  color: var(--color-badge_1-color, #fff);
  font: 700 12px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.15px;
  padding: 3px 15px;
  text-align: center;
  width: auto; }
  @media screen and (max-width: 1199px) {
    .b-product_badge.m-promo {
      font-size: 9px;
      padding: 0 10px; } }

.b-product_badge.m-promo .b-product_badge-label {
  font: 700 12px/24px "Proxima Nova", sans-serif; }
  @media screen and (max-width: 1199px) {
    .b-product_badge.m-promo .b-product_badge-label {
      font-size: 9px; } }

.b-product_badge.m-rating_short {
  text-align: center; }

.b-product_badge.m-rating_short .b-product_badge-label {
  color: var(--color-badge_2-color, #bfb9a1);
  font: normal 14px "Proxima Nova", sans-serif; }

.b-product_badge.m-rating_short .b-product_badge-value {
  color: var(--color-badge_2-color, #bfb9a1);
  font: normal 40px "Proxima Nova", sans-serif; }

/*md

# b-product_price*

Some example of product price structure.

```html_example
	<div class="b-product_price">
		<div class="b-product_price-value m-old">
			$14,99
		</div>
		<div class="b-product_price-value">
			$10,99
		</div>
	</div>
```

*/
.b-product_price {
  display: flex;
  flex-wrap: wrap;
  font: 700 16px/20px "Proxima Nova", sans-serif;
  letter-spacing: 0.3px; }
  .b-product_price-value {
    word-break: keep-all;
    position: relative; }
    .b-product_price-value.m-old {
      color: var(--color-product_price-old, #A7A8A8);
      margin-right: 10px;
      text-decoration: line-through;
      position: relative; }
    .b-product_price-value.m-sales {
      position: relative; }

.sr-only-element {
  opacity: 0;
  position: absolute;
  height: 1px; }

/*md

# b-product_quantity*

Some example of product quantity structure.

```html_example
	<div lass="b-product_quantity">
		<button class="b-product_quantity-label m-minus">-</button>
		<input class="b-product_quantity-input" type="number" value="1">
		<button class="b-product_quantity-label m-plus">+</button>
	</div>
```

*/
.b-product_quantity {
  align-items: stretch;
  border: 2px solid #A7A8A8;
  display: flex;
  height: 60px; }
  .b-product_quantity.m-disabled {
    border-color: rgba(167, 168, 168, 0.4); }
  .b-product_quantity-input {
    -moz-appearance: textfield;
    /* stylelint-disable-line property-no-vendor-prefix */
    background: #fff;
    border: none;
    flex-grow: 1;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    min-width: 0;
    text-align: center; }
    .b-product_quantity-input:disabled {
      background: #fff;
      opacity: 0.4; }
  .b-product_quantity-label {
    cursor: pointer;
    flex-basis: 33%;
    flex-shrink: 0;
    font-size: 20px;
    padding: 0 5px; }
    .b-product_quantity-label.m-minus::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 12px;
      mask: url("./svg-icons/sprite.svg#minus");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 12px;
      margin: 0 auto; }
    .b-product_quantity-label.m-plus::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 12px;
      mask: url("./svg-icons/sprite.svg#plus");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 12px;
      margin: 0 auto; }
    .b-product_quantity-label:disabled.m-minus, .b-product_quantity-label:disabled.m-plus {
      opacity: 0.4; }

/*md
# Product Promo

## Default state

```html_example
	<div class="b-product_promo">Today only! Buy 6 bottles and get 20% OFF!<a class="b-product_promo-link" href="#">Details</a></div>
```
*/
.b-product_promo {
  background: #F6F6F5;
  border-radius: 0;
  color: #202025;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 22px;
  padding: 5px 15px;
  text-align: center;
  padding: 10px 20px;
  text-align: left; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-product_promo {
      font-size: 12px; } }
  .b-product_promo-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px; }
    .b-product_promo-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-product_promo-link:hover {
      text-decoration: none; }
    .b-product_promo-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-product_promo-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-product_promo-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-product_promo-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-product_promo-link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-product_promo.m-small {
    font-size: 13px;
    padding: 5px 10px; }

/*md
# Product Availability

## Default state

```html_example
	<div class="b-product_availability m-instock">In Stock</div>
```
*/
.b-product_availability {
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 26px; }
  .b-product_availability::before {
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 14px;
    margin-right: 5px;
    width: 14px; }
  .b-product_availability.m-instock::before {
    background: #37a10a; }
  .b-product_availability.m-lowinstock::before {
    background: #37a10a; }
  .b-product_availability.m-outofstock::before {
    background: #CA1E36; }

/*md

# b-product_quantity_static*

Some example of product quantity structure.

```html_example
	<div lass="b-product_quantity_static">
		Quantity: <span class="b-product_quantity_static-value">1</span>
	</div>
```

*/
.b-product_quantity_static {
  font-size: 18px; }
  .b-product_quantity_static-value {
    font-weight: bold; }

.b-payment_total {
  font: 18px/26px "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  font-weight: 700; }
  .b-payment_total-item {
    display: flex; }
    @media screen and (max-width: 767px) {
      .b-payment_total-item {
        justify-content: space-between; } }
  .b-payment_total-value {
    margin-left: 20px; }
  .b-payment_total-tax {
    color: #202025;
    font: 600 13px/1.5 "Proxima Nova", sans-serif;
    margin-top: 5px; }

.b-sitemap {
  --columns: 4;
  display: grid; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-sitemap {
      --columns: 3; } }
  @media screen and (max-width: 767px) {
    .b-sitemap {
      --columns: 2; } }
  .b-sitemap-section {
    align-items: start;
    display: grid;
    gap: 30px;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(var(--columns), 1fr);
    margin-bottom: 25px; }
  .b-sitemap-section_title {
    font: 400 32px/48px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    grid-column: 1 / -1; }
    @media screen and (max-width: 1199px) {
      .b-sitemap-section_title {
        font-size: 32px;
        letter-spacing: 0.4px;
        line-height: 40px; } }
  .b-sitemap-subsection {
    margin-bottom: 20px; }
    .b-sitemap-subsection.m-wide {
      grid-column: 1 / -1; }
  .b-sitemap-subsection.m-wide .b-sitemap-subsection_list {
    display: grid;
    gap: 0 30px;
    grid-column: 1 / -1;
    grid-template-columns: repeat(var(--columns), 1fr); }
  .b-sitemap-subsection_title {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    grid-column: 1 / -1;
    margin-bottom: 20px; }
  .b-sitemap-subsection_item {
    margin-bottom: 10px; }
  .b-sitemap-subsection_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px; }
    .b-sitemap-subsection_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-sitemap-subsection_link:hover {
      text-decoration: none; }
    .b-sitemap-subsection_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-sitemap-subsection_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-sitemap-subsection_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-sitemap-subsection_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-sitemap-subsection_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }

.b-contact_us {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 60px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-contact_us {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-contact_us {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-contact_us {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-contact_us-inner {
    margin: 0 auto;
    max-width: 450px; }
  .b-contact_us-form {
    margin-top: 30px; }
    .b-contact_us-form > .f-input_text,
    .b-contact_us-form .f-input_textarea,
    .b-contact_us-form .f-input_checkbox,
    .b-contact_us-form .f-input_field {
      margin-bottom: 20px; }
    .b-contact_us-form #genderWrapper {
      display: none !important; }
  .b-contact_us-form_title {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-top: 30px; }
  .b-contact_us-form_label {
    color: #6A6A67;
    cursor: pointer;
    display: flex;
    font: 600 12px/16px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    transform-origin: left top;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 5px; }
  .b-contact_us-form_link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    font-weight: normal; }
    .b-contact_us-form_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-contact_us-form_link:hover {
      text-decoration: none; }
    .b-contact_us-form_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
    .b-contact_us-form_link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-contact_us-form_link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-contact_us-form_link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-contact_us-form_link.m-disabled {
      color: var(--color-link-disabled, #D2D2CE);
      pointer-events: none; }
  .b-contact_us-form_paragraph {
    font: 16px/1.5 "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    margin-bottom: 20px; }
  .b-contact_us-caption_image {
    margin-top: 5px; }
  .b-contact_us-birth_group {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr); }
  .b-contact_us-phone_group {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 2fr 1fr; }
    @media screen and (max-width: 767px) {
      .b-contact_us-phone_group {
        grid-template-columns: 1fr; } }
  .b-contact_us-contact_group {
    display: flex; }
    .b-contact_us-contact_group .f-input_radio {
      margin-right: 15px;
      width: auto; }
  .b-contact_us .f-input_select::before {
    top: 25px; }
    @media screen and (max-width: 767px) {
      .b-contact_us .f-input_select::before {
        top: 30px; } }
  .b-contact_us .f-input_checkbox-label {
    display: block; }
  @media screen and (max-width: 767px) {
    .b-contact_us-purchase_date_label {
      transform: translateY(-20%); } }
  .b-contact_us .f-input_select-field.parsley-error, .b-contact_us .f-input_select-field.parsley-success,
  .b-contact_us .f-input_text-field.parsley-error,
  .b-contact_us .f-input_text-field.parsley-success,
  .b-contact_us .f-input_textarea-field.parsley-error,
  .b-contact_us .f-input_textarea-field.parsley-success {
    background-color: #fff;
    border-color: #A7A8A8;
    color: inherit; }
  .b-contact_us .parsley-errors-list {
    color: #CA1E36;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 8px; }
  .b-contact_us .f-input_text-field[style*='display'] + .parsley-errors-list,
  .b-contact_us .f-input_text-field[style*='display'] ~ .f-input_text-label {
    display: none; }

/* stylelint-disable */
#thankyou-overlay {
  position: fixed;
  top: 0; }

#thankyou-overlayContent {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 50px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%); }
  @media screen and (max-width: 767px) {
    #thankyou-overlayContent {
      height: auto !important;
      width: 100%; } }
  #thankyou-overlayContent::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: block;
    height: 21px;
    mask: url("./svg-icons/sprite.svg#close");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 21px;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px; }
    @media screen and (max-width: 767px) {
      #thankyou-overlayContent::before {
        height: 16px;
        right: 15px;
        top: 15px;
        width: 16px; } }
  #thankyou-overlayContent #msg {
    font: 400 24px/40px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px;
    color: inherit;
    padding: 0; }
    @media screen and (max-width: 1199px) {
      #thankyou-overlayContent #msg {
        font-size: 26px;
        letter-spacing: 0.325px;
        line-height: 32px; } }
    #thankyou-overlayContent #msg .txt {
      color: inherit; }
    #thankyou-overlayContent #msg #errorTxt.txt {
      font-size: 16px;
      line-height: 28px; }

/* stylelint-enable */
/*md

# Full Width Container

Full Width Container supports 1 layout cell covering the full width of the viewports

## Configuration options

Full Width Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%, 50%, 30%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

## Examples:

### Full Width Container. Width = grid-width; Top margin = M

```html_example
<div class="b-1_up m-grid_width" style="margin-top: var(--m);">
	<div class="b-1_up-cell">
		<img src="https://placehold.it/1400x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Full Width Container. Width = full-width; Top margin = zero

```html_example
<div class="b-1_up m-full_width" style="margin-top: var(--zero);">
	<div class="b-1_up-cell">
		<img src="https://placehold.it/1400x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Full Width Container. Width = 60%; Top margin = XXL

```html_example
<div class="b-1_up" style="--pd-layout_width: 60%; margin-top: var(--xxl);">
	<div class="b-1_up-cell">
		<img src="https://placehold.it/1400x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

*/
.b-1_up {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%); }
  @media screen and (max-width: 767px) {
    .b-1_up {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-1_up.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-1_up.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-1_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-1_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-1_up.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-1_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-1_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-1_up:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-1_up-cell_inner {
    display: grid; }

/*md

# 2 Column Container

2 Column Container supports 2 layout cells:

	* half and half of the viewport width on M/L/XL viewports
	* stacked on S viewports

## Configuration options

2 Column Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%, 50%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell order (small viewport only)

	Layout cells order can be changed from A-B to B-A on small viewport

	How to use in code:
		by CSS class "m-swap"

5. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-2_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; default gutter; cells order on mobile = A-B

```html_example
<div class="b-2_up m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = XL; custom gutter = S; cells order on mobile = B-A

```html_example
<div class="b-2_up m-full_width m-swap" style="margin-top: var(--xl); --pd-grid_gap-sm: var(--s);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 60%; Top margin = XXL; default custom gutter; cells order on mobile = B-A; column 1 vertical alignment = center

```html_example
<div class="b-2_up m-swap" style="--pd-layout_width: 60%; margin-top: var(--xxl);">
	<div class="b-2_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Cell+A" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

*/
.b-2_up {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 767px) {
    .b-2_up {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-2_up.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-2_up.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-2_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-2_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-2_up.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-2_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-2_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-2_up:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-2_up {
      grid-template-columns: 1fr; } }
  @media screen and (max-width: 767px) {
    .b-2_up.m-swap .b-2_up-cell:first-child {
      grid-row: 2; }
    .b-2_up.m-swap .b-2_up-cell:last-child {
      grid-row: 1; } }
  .b-2_up-cell_inner {
    display: grid; }

/*md

# 3 Column Container

3 Column Container supports:

	* 3 layout cells (equal width) on M/L/XL viewports
	* stacked on S viewport

## Configuration options

3 Column Container supports the following configuration options:

1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-3_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; No Custom gutter

Cell 1 - top; Cell 2 - center; Cell 3 - bottom

```html_example
<div class="b-3_up m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-3_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-3_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-3_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = 60%; Top margin = M; Custom gutter = S

Cell 1 - top; Cell 2 - center; Cell 3 - bottom

```html_example
<div class="b-3_up" style="margin-top: var(--m); --pd-grid_gap-sm: var(--s); --pd-layout_width: 60%;">
	<div class="b-3_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-3_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-3_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
</div>
```

*/
.b-3_up {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: repeat(3, 1fr); }
  @media screen and (max-width: 767px) {
    .b-3_up {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-3_up.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-3_up.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-3_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-3_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-3_up.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-3_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-3_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-3_up:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-3_up {
      grid-template-columns: 1fr; } }
  .b-3_up-cell_inner {
    display: grid; }

/*md

# 4 Column Container

4 Column Container supports:

	* 4 layout cells 25% width each, on M/L/XL viewports
	* 2 columns stacked on S viewports

## Configuration options

4 Column Container supports the following configuration options:

1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-4_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = full-width; Top margin = XXL;

Cell 1 - top; Cell 2 - center; Cell 3 - center; Cell 4 - top

```html_example
<div class="b-4_up m-full_width" style="margin-top: var(--xxl);">
	<div class="b-4_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-4_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-4_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-4_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = 80%; Top margin = M;

Cell 1 - top; Cell 2 - bottom; Cell 3 - bottom; Cell 4 - top

```html_example
<div class="b-4_up m-full_width" style="margin-top: var(--m); --pd-layout_width: 80%;">
	<div class="b-4_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-4_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-4_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-4_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
</div>
```

*/
.b-4_up {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: repeat(4, 1fr); }
  @media screen and (max-width: 767px) {
    .b-4_up {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-4_up.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-4_up.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-4_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-4_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-4_up.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-4_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-4_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-4_up:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-4_up {
      grid-template-columns: 1fr; } }
  .b-4_up-cell_inner {
    display: grid; }

/*md

# 5 Column Container

5 Column Container supports:

	* 5 layout cells 25% width each, on M/L/XL viewports
	* 2 columns stacked on S viewports

## Configuration options

5 Column Container supports the following configuration options:

1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-5_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = full-width; Top margin = XXL;

Cell 1 - top; Cell 2 - center; Cell 3 - center; Cell 4 - top

```html_example
<div class="b-5_up m-full_width" style="margin-top: var(--xxl);">
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = 80%; Top margin = M;

Cell 1 - top; Cell 2 - bottom; Cell 3 - bottom; Cell 4 - top

```html_example
<div class="b-5_up m-full_width" style="margin-top: var(--m); --pd-layout_width: 80%;">
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
</div>
```

*/
.b-5_up {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: repeat(5, 1fr); }
  @media screen and (max-width: 767px) {
    .b-5_up {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-5_up.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-5_up.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-5_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-5_up.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-5_up.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-5_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-5_up:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-5_up:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-5_up {
      grid-template-columns: 1fr;
      width: min-content; } }
  .b-5_up-cell_inner {
    display: grid; }

/*md

# 25/75 Container

25/75 Container supports 2 layout cells:

	* First cell covers 25% of the viewport width on M/L/XL viewports
	* Second cell covers 75% of the viewport width on M/L/XL viewports.
	* Stacked on S viewports
	* Cell order B-A on S viewports

## Configuration options

25/75 Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-25_75-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; default gutter;

```html_example
<div class="b-25_75 m-swap m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-25_75-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-25_75-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = S; custom gutter = S;

```html_example
<div class="b-25_75 m-swap m-full_width" style="margin-top: var(--s); --pd-grid_gap-sm: var(--s);">
	<div class="b-25_75-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-25_75-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 60%; Top margin = zero; default custom gutter;

Column 1 vertical alignment = center

```html_example
<div class="b-25_75 m-swap" style="--pd-layout_width: 60%; margin-top: var(--zero);">
	<div class="b-25_75-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Cell+A" alt="Image" />
	</div>
	<div class="b-25_75-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

*/
.b-25_75 {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: 1fr 3fr; }
  @media screen and (max-width: 767px) {
    .b-25_75 {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-25_75.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-25_75.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-25_75.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-25_75.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-25_75.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-25_75:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-25_75:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-25_75:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-25_75 {
      grid-template-columns: 1fr; } }
  @media screen and (max-width: 767px) {
    .b-25_75-cell:first-child {
      grid-row: 2; }
    .b-25_75-cell:last-child {
      grid-row: 1; } }
  .b-25_75-cell_inner {
    display: grid; }

/*md

# 75/25 Container

75/25 Container supports 2 layout cells:

	* First cell covers 75% of the viewport width on M/L/XL viewports
	* Second cell covers 25% of the viewport width on M/L/XL viewports.
	* Stacked on S viewports

## Configuration options

75/25 Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-75_25-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; default gutter;

```html_example
<div class="b-75_25 m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = S; custom gutter = S;

```html_example
<div class="b-75_25 m-full_width" style="margin-top: var(--s); --pd-grid_gap-sm: var(--s);">
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 60%; Top margin = zero; default custom gutter;

Column 1 vertical alignment = center

```html_example
<div class="b-75_25" style="--pd-layout_width: 60%; margin-top: var(--zero);">
	<div class="b-75_25-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Cell+A" alt="Image" />
	</div>
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

*/
.b-75_25 {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: 3fr 1fr; }
  @media screen and (max-width: 767px) {
    .b-75_25 {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-75_25.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-75_25.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-75_25.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-75_25.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-75_25.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-75_25:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-75_25:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-75_25:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-75_25 {
      grid-template-columns: 1fr; } }
  .b-75_25-cell_inner {
    display: grid; }

/*md

# 30/70 Container

30/70 Container supports 2 layout cells:

	* First cell covers 30% of the viewport width on M/L/XL viewports
	* Second cell covers 70% of the viewport width on M/L/XL viewports.
	* Stacked on S viewports
	* Cell order B-A on S viewports

## Configuration options

30/70 Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-30_70-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; default gutter;

```html_example
<div class="b-30_70 m-swap m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-30_70-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-30_70-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = S; custom gutter = S;

```html_example
<div class="b-30_70 m-swap m-full_width" style="margin-top: var(--s); --pd-grid_gap-sm: var(--s);">
	<div class="b-30_70-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-30_70-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 60%; Top margin = zero; default custom gutter;

Column 1 vertical alignment = center

```html_example
<div class="b-30_70 m-swap" style="--pd-layout_width: 60%; margin-top: var(--zero);">
	<div class="b-30_70-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Cell+A" alt="Image" />
	</div>
	<div class="b-30_70-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

*/
.b-30_70 {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: 3fr 7fr; }
  @media screen and (max-width: 767px) {
    .b-30_70 {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-30_70.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-30_70.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-30_70.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-30_70.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-30_70.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-30_70:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-30_70:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-30_70:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-30_70 {
      grid-template-columns: 1fr; } }
  @media screen and (max-width: 767px) {
    .b-30_70-cell:first-child {
      grid-row: 2; }
    .b-30_70-cell:last-child {
      grid-row: 1; } }
  .b-30_70-cell_inner {
    display: grid; }

/*md

# 70/30 Container

70/30 Container supports 2 layout cells:

	* First cell covers 70% of the viewport width on M/L/XL viewports
	* Second cell covers 30% of the viewport width on M/L/XL viewports.
	* Stacked on S viewports

## Configuration options

70/30 Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-70_30-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; default gutter;

```html_example
<div class="b-70_30 m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-70_30-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-70_30-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = S; custom gutter = S;

```html_example
<div class="b-70_30 m-full_width" style="margin-top: var(--s); --pd-grid_gap-sm: var(--s);">
	<div class="b-70_30-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-70_30-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 60%; Top margin = zero; default custom gutter;

Column 1 vertical alignment = center

```html_example
<div class="b-70_30" style="--pd-layout_width: 60%; margin-top: var(--zero);">
	<div class="b-70_30-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Cell+A" alt="Image" />
	</div>
	<div class="b-70_30-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

*/
.b-70_30 {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: 7fr 3fr; }
  @media screen and (max-width: 767px) {
    .b-70_30 {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-70_30.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-70_30.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-70_30.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-70_30.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-70_30.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-70_30:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-70_30:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-70_30:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-70_30 {
      grid-template-columns: 1fr; } }
  .b-70_30-cell_inner {
    display: grid; }

/*md

# 50/50 Container

50/50 Container supports 2 layout cells (equal width)

## Configuration options

50/50 Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%, 50%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-2_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL;

```html_example
<div class="b-2_up m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = M;

```html_example
<div class="b-2_up m-full_width" style="margin-top: var(--m);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 80%; Top margin = XXL; column 1 vertical alignment = center

```html_example
<div class="b-50_50" style="--pd-layout_width: 60%; margin-top: var(--xxl);">
	<div class="b-50_50-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Layout+content" alt="Image" />
	</div>
	<div class="b-50_50-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

*/
.b-50_50 {
  --pd-grid_gap: var(--grid-gutter, 0);
  --pd-grid_gap-sm: var(--pd-grid_gap, 0);
  display: grid;
  gap: var(--pd-grid_gap);
  margin: 0 auto;
  width: calc(var(--pd-layout_width, 100) * 1%);
  grid-template-columns: 1fr 1fr; }
  @media screen and (max-width: 767px) {
    .b-50_50 {
      gap: var(--pd-grid_gap-sm);
      width: 100%; } }
  .b-50_50.m-grid_width {
    margin: 0 auto;
    max-width: 1720px;
    padding-left: 80px;
    padding-right: 80px; }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      .b-50_50.m-grid_width {
        max-width: 1400px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-50_50.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .b-50_50.m-grid_width {
        padding-left: 15px;
        padding-right: 15px; } }
  .b-50_50.m-full_width {
    width: 100%; }
  @media screen and (min-width: 1441px) {
    .b-50_50:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1720px); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-50_50:not(.m-full_width) {
      max-width: calc(var(--pd-layout_width, 100) / 100 * 1400px); } }
  @media screen and (max-width: 767px) {
    .b-50_50:not(.m-full_width) {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-50_50-cell_inner {
    display: grid; }

/*md

# Image Tile Component

## Configuration options

Image Tile Component supports the following configuration options:

1. Component width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%.

	How to use in code:
		inline CSS [style="--component-width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Aspect Ratio Crop

	Could be one of values: 1:1, 3:4, 16:9, 4:3.

	How to use in code:
		CSS class "m-{aspect_ratio}"
		ex: "m-1_1", "m-16_9", "m-3_4" etc.

## Examples:

### Example 1

Component width = 30%, top margin = zero

Aspect ratio = 1:1

```html_example
<div class="b-pd_picture"
	style="
		margin-top: var(--zero);
		--pd-component_width: 30%;"
>
	<picture class="b-pd_picture-inner m-ratio-1_1">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
		<img class="b-pd_picture-image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
</div>
```

### Example 2

Component width = 50%, top margin = l

Aspect ratio = 4:3

```html_example
<div class="b-pd_picture"
	style="
		margin-top: var(--l);
		--pd-component_width: 50%;"
>
	<picture class="b-pd_picture-inner m-ratio-4_3">
	<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
	<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
	<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
	<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
		<img class="b-pd_picture-image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
</div>
```

### Example 1

Component width = 80%, top margin = s, horizontal positioning = left.

Aspect ratio = 16:9

```html_example
<div class="b-pd_picture"
	style="
		justify-self: start;
		margin-top: var(--s);
		--pd-component_width: 80%;"
>
	<picture class="b-pd_picture-inner m-ratio-16_9">
	<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
	<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
	<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
	<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
		<img class="b-pd_picture-image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
</div>
```
*/
.b-pd_picture {
  display: block;
  width: var(--pd-component_width, 100%); }
  @media screen and (max-width: 767px) {
    .b-pd_picture {
      width: 100%; } }
  .b-pd_picture-inner {
    display: block;
    padding-bottom: calc(var(--aspect-height, 3) / var(--aspect-width, 4) * 100%);
    position: relative;
    width: 100%; }
    .b-pd_picture-inner.m-ratio-1_1 {
      --aspect-width: 1;
      --aspect-height: 1; }
    .b-pd_picture-inner.m-ratio-3_4 {
      --aspect-width: 3;
      --aspect-height: 4; }
    .b-pd_picture-inner.m-ratio-4_3 {
      --aspect-width: 4;
      --aspect-height: 3; }
    .b-pd_picture-inner.m-ratio-1_2 {
      --aspect-width: 1;
      --aspect-height: 2; }
    .b-pd_picture-inner.m-ratio-1_3 {
      --aspect-width: 1;
      --aspect-height: 3; }
    .b-pd_picture-inner.m-ratio-2_5 {
      --aspect-width: 2;
      --aspect-height: 5; }
    .b-pd_picture-inner.m-ratio-16_9 {
      --aspect-width: 16;
      --aspect-height: 9; }
    @media screen and (max-width: 767px) {
      .b-pd_picture-inner.sm-ratio-1_1 {
        --aspect-width: 1;
        --aspect-height: 1; }
      .b-pd_picture-inner.sm-ratio-3_4 {
        --aspect-width: 3;
        --aspect-height: 4; }
      .b-pd_picture-inner.sm-ratio-4_3 {
        --aspect-width: 4;
        --aspect-height: 3; }
      .b-pd_picture-inner.sm-ratio-1_2 {
        --aspect-width: 1;
        --aspect-height: 2; }
      .b-pd_picture-inner.sm-ratio-1_3 {
        --aspect-width: 1;
        --aspect-height: 3; }
      .b-pd_picture-inner.sm-ratio-2_5 {
        --aspect-width: 2;
        --aspect-height: 5; }
      .b-pd_picture-inner.sm-ratio-16_9 {
        --aspect-width: 16;
        --aspect-height: 9; } }
  .b-pd_picture-image {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: var(--pd-picture-focal_x, 50%) var(--pd-picture-focal_y, 50%);
    position: absolute;
    width: 100%; }
  @media screen and (max-width: 767px) {
    .b-pd_picture.has-mobile .b-pd_picture-inner {
      display: none; } }
  .b-pd_picture.has-mobile .b-pd_picture-inner.mobileImage {
    display: none; }
    @media screen and (max-width: 767px) {
      .b-pd_picture.has-mobile .b-pd_picture-inner.mobileImage {
        display: block; } }

/*md

# Textbox Component

## Configuration options

Textbox Component supports the following configuration options:

1. Component width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--component-width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Component text alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [style="text-align: left/center/right;"]

4. Content

	Textbox Component support HTML tags to format text.

	Any of typography elements can be used as Textbox Component content.

	You can see typography elements in 05-blocks/common/t-typography section.

## Examples:

### Example 1

Width = 100%, Margin top = zero, text align = center

```html_example
<div class="b-text_box"
	style="
        --component-width: 100%;
        margin-top: var(--zero);
        text-align: center;
">
	<h2 class="t-heading_2">Lorem ipsum dolor sit amet</h2>
	<p class="t-paragraph_2">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
	<p class="t-paragraph_3">Duis aute irure dolor in reprehenderit in voluptate <a href="#" class="t-link_1">Link 3</a> velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
</div>
```

### Example 2

Width = 80%, Margin top = XL, text align = right

```html_example
<div class="b-text_box"
	style="
        --component-width: 80%;
        margin-top: var(--xl);
        text-align: right;
">
	<h2 class="t-heading_4">Lorem ipsum dolor sit amet</h2>
	<p class="t-paragraph_1">Consectetur adipiscing elit, <a href="#" class="t-link_2">Link 2</a> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
	<p class="t-paragraph_2">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
</div>
```

### Example 3

Width = 50%, Margin top = S, text align = left

```html_example
<div class="b-text_box"
	style="
        --component-width: 50%;
        margin-top: var(--s);
        text-align: left;
">
	<ul class="t-unordered_list">
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
	</ul>
</div>
```

*/
.b-text_box {
  color: var(--mobile-color);
  padding: var(--mobile-padding-vertical) 0;
  margin-top: var(--mobileMarginTop); }
  .b-text_box h1 {
    font: 600 40px/56px "Proxima Nova", sans-serif;
    letter-spacing: 0.6px; }
    @media screen and (max-width: 1199px) {
      .b-text_box h1 {
        font-size: 40px;
        letter-spacing: 0.5px;
        line-height: 48px; } }
  .b-text_box h2 {
    font: 400 32px/48px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px; }
    @media screen and (max-width: 1199px) {
      .b-text_box h2 {
        font-size: 32px;
        letter-spacing: 0.4px;
        line-height: 40px; } }
  .b-text_box h3 {
    font: 400 24px/40px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px; }
    @media screen and (max-width: 1199px) {
      .b-text_box h3 {
        font-size: 26px;
        letter-spacing: 0.325px;
        line-height: 32px; } }
  .b-text_box h4 {
    font: 400 28px/36px "Proxima Nova", sans-serif;
    letter-spacing: 0.35px; }
    @media screen and (max-width: 1199px) {
      .b-text_box h4 {
        font-size: 24px;
        letter-spacing: 0.3px; } }
  .b-text_box p {
    font: 18px/26px "Proxima Nova", sans-serif;
    letter-spacing: 0.2px; }
  @media screen and (min-width: 768px) {
    .b-text_box {
      padding: 0;
      color: var(--color);
      width: var(--component-width, 100%);
      margin-top: var(--marginTop); } }
  @media screen and (max-width: 1199px) {
    .b-text_box.mobile_font--override * {
      font-size: var(--mobile-size); } }

/*md

# Banner Type 1

## Configuration options

Banner Type 1 supports the following configuration options:

1. Banner width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="width: NN%;"]

2. Banner Minimum Height.

	Could be one of values: auto, 100%, 80%, 50%, 30%.

	How to use in code:
		inline CSS [style="--pd_banner-min_height: {VALUE};"]

3. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

4. Banner Horizontal paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-horizontal: var(--VALUE);"]

5. Banner Vertical paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-vertical: var(--VALUE);"]

6. Banner Background Color

	How to use in code:
		inline CSS [style="background-color: var(--VALUE);"]

7. Region Width

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--pd_banner-region_width: NN%;"]

8. Region Horizontal Alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="align-self: start/center/end;"></div>]

9. Region Vertical Alignment

	Could be one of values: top, middle, bottom

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="justify-self: start/center/end;"></div>]

## Examples:

### Example 1

Banner with background image.

Banner width = 80%, min height = 80% of screen, margin top = L, horizontal padding = XL, vertical padding = L

Region width = 50%, horizontal alignment = center, vertical alignment = middle

Region Content: Textbox + Button Alternative (small, inversed)

```html_example
<div class="b-banner_1" style="
        width: 80%;
        margin-top: var(--l, 0);
        --pd_banner-min_height: 80vh;
        --pd_banner-padding-horizontal: var(--xl);
        --pd_banner-padding-vertical: var(--l);
">
    <picture class="b-banner_1-background">
        <source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
        <source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
        <source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
        <source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
        <img class="b-banner_1-background_image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
    </picture>
    <div style="--pd_banner-region_width:50%; align-self:center; justify-self:center;" class="b-banner_1-region">
        <div class="b-banner_1-region_inner">
            <div class="b-text_box" style="
                    --component-width: 100%;
                    margin-top: var(--zero, 0);
                    justify-self: center;
                    text-align: center;
            ">
                <h4 class="t-heading_4">Lorem ipsum dolor sit amet</h4>
                <p class="t-paragraph_2">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div class="b-banner_1-region_inner">
            <a href="#" target="_blank"
                class=" g-button_alt m-inversed m-small "
                style=" margin-top: var(--m, 0); justify-self: center;
            ">
                Link Copy
            </a>
        </div>
    </div>
</div>
```

### Example 2

Banner with background color

Banner width = 80%, min height = 30% of screen, margin top = L, horizontal padding = XL, vertical padding = L

Region width = 50%, horizontal alignment = left, vertical alignment = top

Region Content: Textbox + Button Alternative (small, inversed)

```html_example
<div class="b-banner_1" style="
        width: 80%;
        justify-self: center;
        margin-top: var(--l, 0);
        background-color: lightblue;
        --pd_banner-min_height: 30vh;
        --pd_banner-padding-horizontal: var(--xl);
        --pd_banner-padding-vertical: var(--l);
">
	<div style="--pd_banner-region_width:50%; align-self:start; justify-self:start;"
		class="b-banner_1-region
	">
        <div class="b-banner_1-region_inner">
            <div class="b-text_box" style="
                    --component-width: 100%;
                    margin-top: var(--zero, 0);
                    justify-self: center;
                    text-align: center;
            ">
                <h4 class="t-heading_4">Lorem ipsum dolor sit amet</h4>
                <p class="t-paragraph_2">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div class="b-banner_1-region_inner">
            <a href="#" target="_blank"
                class=" g-button_alt m-inversed m-small "
                style=" margin-top: var(--m, 0); justify-self: center;
            ">
                Link Copy
            </a>
        </div>
    </div>
</div>
```

*/
.b-banner_1 {
  display: grid;
  min-height: var(--pd_banner-min_height);
  padding: var(--pd_banner-padding-vertical) var(--pd_banner-padding-horizontal);
  position: relative;
  width: var(--pd-component_width, 100%); }
  @media screen and (max-width: 767px) {
    .b-banner_1 {
      padding-left: var(--s);
      padding-right: var(--s);
      width: 100%; } }
  .b-banner_1-background {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
  .b-banner_1-background_image {
    height: 100%;
    object-fit: cover;
    object-position: var(--pd_banner-focal_x, 50%) var(--pd_banner-focal_y, 50%);
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%; }
  .b-banner_1-region {
    color: #fff;
    z-index: 1;
    align-self: var(--mobileAlign);
    justify-self: var(--mobileJustify); }
    @media screen and (min-width: 768px) {
      .b-banner_1-region {
        width: var(--pd_banner-region_width);
        align-self: var(--desktopAlign);
        justify-self: var(--desktopJustify); } }
    .b-banner_1-region.has-mobile-region {
      display: none; }
      @media screen and (min-width: 768px) {
        .b-banner_1-region.has-mobile-region {
          display: block; } }
    .b-banner_1-region.mobile-show {
      display: block; }
      @media screen and (min-width: 768px) {
        .b-banner_1-region.mobile-show {
          display: none; } }
  .b-banner_1-region_inner {
    display: grid; }
  .b-banner_1.has-mobile .b-banner_1-backgroundmobile {
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .b-banner_1.has-mobile .b-banner_1-backgroundmobile {
        display: block; } }
  @media screen and (max-width: 767px) {
    .b-banner_1.has-mobile .b-banner_1-background {
      display: none; } }

/*md

# Banner Type 1

## Configuration options

Banner Type 1 supports the following configuration options:

1. Banner width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Banner Horizontal paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-horizontal: var(--VALUE);"]

4. Banner Vertical paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-vertical: var(--VALUE);"]

5. Banner Aspect Ratio

	Could be one of values: auto, 1:1, 3:4, 4:3, 16:9

	How to use in code:
		CSS class [<div class="b-banner_2-inner m-1_1"] / or m-auto, m-3_4, m-4_3, m-16_9

5. Banner Background Color

	How to use in code:
		inline CSS [style="background-color: var(--VALUE);"]

6. Region Width

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--pd_banner-region_width: NN%;"]

7. Region Horizontal Alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="align-self: start/center/end;"></div>]

7. Region Vertical Alignment

	Could be one of values: top, middle, bottom

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="justify-self: start/center/end;"></div>]

## Examples:

### Example 1

Banner with background image.

Banner width = 50%, margin top = zero, horizontal padding = M, vertical padding = M, aspect ratio = 16:9

Region width = 80%, horizontal alignment = center, vertical alignment = middle

Region Content: Textbox

```html_example
<div class="b-banner_2" style="
		justify-self: center;
		margin-top: var(--zero, 0);
		--pd_banner-width: 50%;
		--pd_banner-padding-horizontal: var(--m);
		--pd_banner-padding-vertical: var(--m);
">
	<div class="b-banner_2-inner m-ratio m-16_9" style="
			background-color: lightblue;
	">
		<picture class="b-banner_2-background">
			<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
			<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
			<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
			<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
			<img class="b-banner_2-background_image"
				src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
				alt="Alternative text"
				title="Image title"
			/>
		</picture>
		<div style="--pd_banner-region_width:80%;" class="b-banner_2-region m-x_center m-y_middle">
			<div class="b-banner_2-region_inner">
				<div class="b-text_box" style="
						--component-width: 100%;
						margin-top: var(--zero, 0);
						justify-self: center;
						text-align: left;
				">
					<h2 class="t-heading_2">Lorem ipsum dolor sit amet</h2>
					<p class="t-paragraph_3">Duis aute irure dolor in reprehenderit in voluptate <a href="#" class="t-link_1">Link 3</a> velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
				</div>
			</div>
		</div>
	</div>
</div>
```

### Example 2

Banner with background color

Banner width = 50%, margin top = L, horizontal padding = XL, vertical padding = L, aspect ratio = 16:9

Region width = 80%, horizontal alignment = center, vertical alignment = bottom

Region Content: Textbox

```html_example
<div class="b-banner_2" style="
		justify-self: center;
		margin-top: var(--zero, 0);
		--pd_banner-width: 50%;
		--pd_banner-padding-horizontal: var(--m);
		--pd_banner-padding-vertical: var(--m);
">
	<div class="b-banner_2-inner m-ratio m-16_9" style="
			background-color: lightblue;
	">
		<div style="--pd_banner-region_width:80%;" class="b-banner_2-region m-x_center m-y_bottom">
			<div class="b-banner_2-region_inner">
				<div class="b-text_box" style="
						--component-width: 100%;
						margin-top: var(--zero, 0);
						justify-self: center;
						text-align: left;
				">
					<h2 class="t-heading_2">Lorem ipsum dolor sit amet</h2>
					<p class="t-paragraph_3">Duis aute irure dolor in reprehenderit in voluptate <a href="#" class="t-link_1">Link 3</a> velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
				</div>
			</div>
		</div>
	</div>
</div>
```

*/
.b-banner_2 {
  width: var(--pd_banner-width); }
  @media screen and (max-width: 767px) {
    .b-banner_2 {
      width: 100%; } }
  .b-banner_2-inner.m-1_1 {
    --aspect-height: 1;
    --aspect-width: 1; }
  .b-banner_2-inner.m-3_4 {
    --aspect-width: 3;
    --aspect-height: 4; }
    @media screen and (max-width: 767px) {
      .b-banner_2-inner.m-3_4 {
        --aspect-width: 1;
        --aspect-height: 1; } }
  .b-banner_2-inner.m-4_3 {
    --aspect-width: 4;
    --aspect-height: 3; }
  .b-banner_2-inner.m-16_9 {
    --aspect-width: 16;
    --aspect-height: 9; }
    @media screen and (max-width: 767px) {
      .b-banner_2-inner.m-16_9 {
        --aspect-width: 1;
        --aspect-height: 1; } }
  .b-banner_2-inner.m-ratio {
    padding-bottom: calc(var(--aspect-height, 3) / var(--aspect-width, 4) * 100%);
    position: relative; }
  .b-banner_2-inner.m-auto {
    display: grid; }
  .b-banner_2-inner.m-ratio .b-banner_2-background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .b-banner_2-background_image {
    vertical-align: top;
    width: 100%; }
  .b-banner_2-inner.m-ratio .b-banner_2-background_image {
    height: 100%;
    object-fit: cover;
    object-position: var(--pd_banner-focal_x, 50%) var(--pd_banner-focal_y, 50%);
    position: absolute;
    top: 0; }
  .b-banner_2-inner.m-auto .b-banner_2-background {
    grid-area: 1 / 1 / -1 / -1; }
  .b-banner_2-region {
    color: #202025; }
    @media screen and (min-width: 768px) {
      .b-banner_2-region {
        width: var(--pd_banner-region_width); } }
  .b-banner_2-inner.m-auto .b-banner_2-region {
    box-sizing: initial;
    grid-area: 1 / 1 / -1/ -1;
    padding: var(--pd_banner-padding-vertical) var(--pd_banner-padding-horizontal); }
    .b-banner_2-inner.m-auto .b-banner_2-region.m-x_left {
      justify-self: start; }
    .b-banner_2-inner.m-auto .b-banner_2-region.m-x_center {
      justify-self: center; }
    .b-banner_2-inner.m-auto .b-banner_2-region.m-x_right {
      justify-self: end; }
    .b-banner_2-inner.m-auto .b-banner_2-region.m-y_top {
      align-self: start; }
    .b-banner_2-inner.m-auto .b-banner_2-region.m-y_middle {
      align-self: center; }
    .b-banner_2-inner.m-auto .b-banner_2-region.m-y_bottom {
      align-self: end; }
  .b-banner_2-inner.m-ratio .b-banner_2-region {
    position: absolute;
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .b-banner_2-inner.m-ratio .b-banner_2-region {
        left: var(--pd_banner-padding-horizontal);
        right: var(--pd_banner-padding-horizontal); } }
    .b-banner_2-inner.m-ratio .b-banner_2-region.m-x_left {
      left: var(--pd_banner-padding-horizontal); }
    @media screen and (min-width: 768px) {
      .b-banner_2-inner.m-ratio .b-banner_2-region.m-x_center {
        left: 50%;
        transform: translateX(-50%); } }
    .b-banner_2-inner.m-ratio .b-banner_2-region.m-x_right {
      right: var(--pd_banner-padding-horizontal); }
    .b-banner_2-inner.m-ratio .b-banner_2-region.m-y_top {
      top: var(--pd_banner-padding-vertical); }
    .b-banner_2-inner.m-ratio .b-banner_2-region.m-y_middle {
      top: 50%;
      transform: translateY(-50%); }
    @media screen and (min-width: 768px) {
      .b-banner_2-inner.m-ratio .b-banner_2-region.m-y_middle.m-x_center {
        transform: translateX(-50%) translateY(-50%); } }
    .b-banner_2-inner.m-ratio .b-banner_2-region.m-y_bottom {
      bottom: var(--pd_banner-padding-vertical); }
  .b-banner_2-region_inner {
    display: grid; }
  .b-banner_2.has-mobile .b-banner_2-backgroundmobile {
    display: none; }
    @media screen and (max-width: 767px) {
      .b-banner_2.has-mobile .b-banner_2-backgroundmobile {
        display: block; } }
  @media screen and (max-width: 767px) {
    .b-banner_2.has-mobile .b-banner_2-background {
      display: none; } }

/*md

# Category Tile

## Configuration options

Category Tile component supports the following configuration options:

1. Component width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--pd_category_tile-width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Aspect Ratio Crop

	Could be one of values: auto, 1:1, 3:4.

	How to use in code:
		CSS class "m-{aspect_ratio}"
		ex: "m-1_1", "m-auto", "m-3_4"

4. Title Top Margin

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="margin-top: var(--VALUE);"></div>]

5. Title Text Alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="text-align: left/center/right;"></div>]

6. Button/Link inherits all the properties from Button/Link component and can be found under 05-blocks/page_designer/link_button section.

## Examples:

### Example 1

Tile width = 30%, Margin top = zero

Image Aspect Ratio 3:4 (default),

Title alignment- left, title top margin = M,

Button main alignment- left, button main top margin = M

```html_example
<a href="#"
    class="b-category_tile"
    style="
        --pd_category_tile-width: 30%;
        margin-top: var(--zero);
">
	<picture class="b-category_tile-picture m-ratio m-3_4">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<img class="b-category_tile-picture_image"
            src="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
	<div class="b-category_tile-title"
        style="
            margin-top: var(--m);
            text-align: left;
    ">
        <h3 class="t-heading_3">Lorem ipsum dolor sit amet</h3>
	</div>
	<span
		class="g-button_main"
		style="
			margin-top: var(--m);
			justify-self: start;
	">
		Link Copy
	</span>
</a>
```

### Example 2

Tile width = 30%, Margin top = zero

Image Aspect Ratio 1:1,

Title alignment- left, title top margin = M,

Link type 3 alignment- left, button main top margin = M

```html_example
<a href="#"
    class="b-category_tile"
    style="
        --pd_category_tile-width: 30%;
        margin-top: var(--zero);
">
	<picture class="b-category_tile-picture m-ratio m-1_1">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<img class="b-category_tile-picture_image"
            src="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
	<div class="b-category_tile-title"
        style="
            margin-top: var(--m);
            text-align: center;
    ">
        <h3 class="t-heading_3">Lorem ipsum dolor sit amet</h3>
	</div>
	<span
		class="t-link_3"
		style="
			margin-top: var(--m);
			justify-self: center;
	">
		Link Copy
	</span>
</a>
```

### Example 3

Tile width = 30%, Margin top = zero

Image Aspect Ratio- auto,

Title alignment- left, title top margin = M,

Button alt alignment- left, button main top margin = M

```html_example
<a href="#"
	class="b-category_tile"
	style="
		--pd_category_tile-width: 30%;
		margin-top: var(--zero);
">
	<picture class="b-category_tile-picture m-auto">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<img class="b-category_tile-picture_image"
			src="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x"
			alt="Alternative text"
			title="Image title"
		/>
	</picture>
	<div class="b-category_tile-title"
		style="
			margin-top: var(--m);
			text-align: right;
	">
		<h3 class="t-heading_3">Lorem ipsum dolor sit amet</h3>
	</div>
	<span
		class="g-button_alt"
		style="
			margin-top: var(--m);
			justify-self: end;
	">
		Link Copy
	</span>
</a>
```

*/
.b-category_tile {
  display: grid;
  width: var(--pd_category_tile-width); }
  @media screen and (min-width: 768px) {
    .b-category_tile {
      padding: 50px 30px; } }
  @media screen and (max-width: 767px) {
    .b-category_tile {
      width: 100%; } }
  .b-category_tile:hover {
    text-decoration: none; }
  .b-category_tile-picture.m-ratio {
    padding-bottom: calc(var(--aspect-height, 3) / var(--aspect-width, 4) * 100%);
    position: relative; }
  .b-category_tile-picture.m-1_1 {
    --aspect-height: 1;
    --aspect-width: 1; }
  .b-category_tile-picture.m-3_4 {
    --aspect-width: 3;
    --aspect-height: 4; }
  .b-category_tile-picture.m-auto .b-category_tile-picture_image {
    width: 100%; }
  .b-category_tile-picture.m-ratio .b-category_tile-picture_image {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: var(--pd_banner-focal_x, 50%) var(--pd_banner-focal_y, 50%);
    position: absolute;
    width: 100%; }
  .b-category_tile-title {
    word-break: break-word; }

.b-social_share {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  width: var(--component-width); }
  .b-social_share #st-2 .st-btn, .b-social_share #st-2 .st-btn[data-network] {
    background-color: var(--colorOverride, #BA8D36) !important;
    display: inline-block !important; }

.b-create_account .login-hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
  pointer-events: none; }

.flip-card {
  color: var(--textColor);
  perspective: 100rem;
  height: 100%; }
  .flip-card:hover .flip-card-inner, .flip-card:focus .flip-card-inner {
    transform: rotateY(-180deg);
    transition: transform 0.5s; }
  .flip-card .flip-card-inner {
    height: 100%;
    display: flex;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 1s;
    transform-style: preserve-3d; }
    .flip-card .flip-card-inner .flip-card-front, .flip-card .flip-card-inner .flip-card-back {
      min-width: 100%;
      text-align: center;
      backface-visibility: hidden;
      background-color: var(--bgColor); }
    .flip-card .flip-card-inner .flip-card-back {
      transform: rotateY(-180deg) translate(100%, 0); }
      .flip-card .flip-card-inner .flip-card-back--inner {
        padding: 100px 20px 20px; }
  .flip-card hr {
    border-style: solid; }

.b-reserve_bar--search {
  width: var(--component-width, 100%); }
  @media screen and (max-width: 767px) {
    .b-reserve_bar--search {
      width: 100%; } }
  .b-reserve_bar--search input, .b-reserve_bar--search label {
    width: 100%;
    color: inherit; }
  .b-reserve_bar--search input {
    font: 700 16px/22px "Proxima Nova", sans-serif;
    height: 46px;
    letter-spacing: 2.1px;
    line-height: 22px;
    transition: height linear 0.5s;
    appearance: none;
    background: var(--searchBackgroundColor, transparent);
    color: var(--searchColor, inherit);
    border-radius: 0;
    min-width: 0;
    vertical-align: initial;
    border: 2px solid var(--searchBorderColor, #A7A8A8);
    padding: 14px 40px 14px 15px;
    text-align: center; }
    .b-reserve_bar--search input:hover {
      border: 2px solid var(--searchBorderColor, #A7A8A8); }
    @media screen and (max-width: 767px) {
      .b-reserve_bar--search input {
        height: 56px; } }
  .b-reserve_bar--search label {
    font: 700 16px/22px "Proxima Nova", sans-serif;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: block; }
  .b-reserve_bar--search ul {
    opacity: 0; }
    .b-reserve_bar--search ul.results {
      opacity: 1;
      border: 1px solid #A7A8A8;
      border-top: none;
      padding: 1rem; }
    .b-reserve_bar--search ul li {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 18px; }
      .b-reserve_bar--search ul li::before {
        background: var(--icon-color, #BA8D36);
        content: '';
        display: block;
        height: 17px;
        mask: url("./svg-icons/sprite.svg#location");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 13px; }
      .b-reserve_bar--search ul li:before {
        background: var(--icon-color, #fafafa); }

.b-reserve_bar--sizeSelect {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  text-align: center;
  justify-content: center; }
  .b-reserve_bar--sizeSelect > p {
    width: 100%;
    font-weight: bold;
    color: #202025; }

.b-reserve_bar--productGrid {
  margin-top: 1.5rem; }
  .b-reserve_bar--productGrid > div {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem 1rem; }
  .b-reserve_bar--productGrid .productAvailability {
    width: 100%;
    color: #202025; }
  .b-reserve_bar--productGrid .productRetailer {
    padding: 1rem;
    border: 2px solid #202025;
    width: calc(33.333333% - 1rem);
    background-color: #fff;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
    color: #202025; }
    @media screen and (max-width: 767px) {
      .b-reserve_bar--productGrid .productRetailer {
        width: 100%; } }
    .b-reserve_bar--productGrid .productRetailer:hover {
      background-color: #BA8D36;
      border-color: #BA8D36;
      color: #202025; }
    .b-reserve_bar--productGrid .productRetailer.disabled {
      pointer-events: none;
      background: #a1a1aa60; }

.b-reserve_bar--productDetails {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; }
  .b-reserve_bar--productDetails > div {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      .b-reserve_bar--productDetails > div {
        width: 100%; } }
    .b-reserve_bar--productDetails > div *:not(div) {
      width: 100%; }
    .b-reserve_bar--productDetails > div p {
      line-height: 1; }
    .b-reserve_bar--productDetails > div div {
      display: flex;
      align-items: center;
      width: auto; }
  .b-reserve_bar--productDetails button {
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    cursor: pointer; }
  .b-reserve_bar--productDetails #errorMessage, .b-reserve_bar--productDetails span {
    width: 100%; }
  .b-reserve_bar--productDetails .f-input_text-label {
    position: relative;
    top: unset;
    left: unset; }
  .b-reserve_bar--productDetails .f-input_select-field {
    min-width: 40px;
    width: auto;
    padding: 4px;
    height: auto;
    text-align: center;
    cursor: pointer;
    margin-left: 4px; }

.b-filters_wrap {
  display: flex;
  gap: 1rem; }
  .b-filters_wrap.b-filters_wrap--left, .b-filters_wrap.b-filters_wrap--right {
    flex-wrap: wrap; }
    @media screen and (min-width: 768px) {
      .b-filters_wrap.b-filters_wrap--left, .b-filters_wrap.b-filters_wrap--right {
        flex-wrap: unset; } }
    .b-filters_wrap.b-filters_wrap--left .b-filters_filters, .b-filters_wrap.b-filters_wrap--right .b-filters_filters {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .b-filters_wrap.b-filters_wrap--left .b-filters_filters, .b-filters_wrap.b-filters_wrap--right .b-filters_filters {
          width: auto; } }
    .b-filters_wrap.b-filters_wrap--left .experience-filters, .b-filters_wrap.b-filters_wrap--right .experience-filters {
      display: flex; }
      @media screen and (min-width: 768px) {
        .b-filters_wrap.b-filters_wrap--left .experience-filters, .b-filters_wrap.b-filters_wrap--right .experience-filters {
          flex-direction: column;
          justify-content: center;
          align-items: center; } }
    .b-filters_wrap.b-filters_wrap--left .b-filters_cards, .b-filters_wrap.b-filters_wrap--right .b-filters_cards {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .b-filters_wrap.b-filters_wrap--left .b-filters_cards, .b-filters_wrap.b-filters_wrap--right .b-filters_cards {
          width: 90%; } }
  .b-filters_wrap.b-filters_wrap--top, .b-filters_wrap.b-filters_wrap--bottom {
    flex-wrap: wrap; }
    .b-filters_wrap.b-filters_wrap--top .b-filters_filters, .b-filters_wrap.b-filters_wrap--top .b-filters_cards, .b-filters_wrap.b-filters_wrap--bottom .b-filters_filters, .b-filters_wrap.b-filters_wrap--bottom .b-filters_cards {
      width: 100%; }
    .b-filters_wrap.b-filters_wrap--top .b-filters_cards, .b-filters_wrap.b-filters_wrap--bottom .b-filters_cards {
      width: 100%;
      margin-left: 15px; }
  .b-filters_wrap.b-filters_wrap--bottom {
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .b-filters_wrap.b-filters_wrap--bottom {
        flex-direction: column-reverse; } }
  .b-filters_wrap.b-filters_wrap--right {
    flex-direction: row-reverse; }

.b-filters_filters {
  margin-top: var(--marginTop); }

.experience-commerce_assets-filterCard {
  width: 100%;
  margin-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    .experience-commerce_assets-filterCard {
      width: calc(100% / var(--cardsRow, 3) - 1rem); } }

.experience-region.experience-filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem; }

.b-store_locator {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-store_locator {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-store_locator {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-store_locator {
      padding-left: 15px;
      padding-right: 15px; } }
  .b-store_locator-title {
    font: 600 40px/56px "Proxima Nova", sans-serif;
    letter-spacing: 0.6px;
    margin: 60px 0 30px;
    text-align: center; }
    @media screen and (max-width: 1199px) {
      .b-store_locator-title {
        font-size: 40px;
        letter-spacing: 0.5px;
        line-height: 48px; } }
  .b-store_locator-description, .b-store_locator-text {
    max-width: 680px;
    text-align: center; }
  .b-store_locator-description {
    font: 16px/1.5 "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    margin: 0 auto 60px; }
  .b-store_locator-text {
    font: 14px/1.57 "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    margin: 0 auto 120px; }
  .b-store_locator-submit {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: top;
    word-break: break-word;
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 60px;
    letter-spacing: 3px;
    line-height: 1.2;
    padding: 5px 25px;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: background-color, color, border;
    background: var(--button_main-background_color, #BA8D36);
    border: 2px solid var(--button_main-border_color, #BA8D36);
    color: var(--button_main-text_color, #202025);
    border-radius: 0;
    width: 100%;
    margin-top: 40px; }
    .b-store_locator-submit:hover {
      text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .b-store_locator-submit:hover {
        background: var(--button_main__hover-background_color, #fff);
        border-color: var(--button_main__hover-border_color, #BA8D36);
        border-radius: 0;
        color: var(--button_main__hover-text_color, #202025); } }
    .b-store_locator-submit:disabled:not(.m-processing), .b-store_locator-submit.m-disabled:not(.m-processing) {
      pointer-events: none;
      opacity: 1;
      background-color: #D2D2CE;
      color: #fff;
      border: 2px solid #D2D2CE;
      border-radius: 0; }
      @media screen and (max-width: 1199px) {
        .b-store_locator-submit:disabled:not(.m-processing), .b-store_locator-submit.m-disabled:not(.m-processing) {
          padding: 5px 0; } }
    .b-store_locator-submit.m-small {
      font-weight: 700;
      height: 40px;
      padding: 8px 15px; }
    .b-store_locator-submit.m-width_s {
      width: var(--button-s); }
    .b-store_locator-submit.m-width_m {
      width: var(--button-m); }
    .b-store_locator-submit.m-width_l {
      width: var(--button-l); }
    .b-store_locator-submit.m-processing {
      cursor: wait; }
  .b-store_locator-wrapper {
    column-gap: var(--grid-gutter);
    display: grid;
    grid-template: [start form-s separator-s content-s] 1fr [end form-e separator-e content-e]/[start] 1fr [form-s] 1fr [] 1fr [] 1fr [] 1fr [form-e separator-s] 1fr [separator-e] 1fr [content-s] 1fr [] 1fr [] 1fr [] 1fr [content-e] 1fr [end];
    margin-bottom: 60px;
    position: relative; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-store_locator-wrapper {
        grid-template: [start form-s separator-s content-s] 1fr [end form-e separator-e content-e]/[start form-s] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [form-e separator-s] 1fr [separator-e] 1fr [content-s] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [end content-e]; } }
    @media screen and (max-width: 767px) {
      .b-store_locator-wrapper {
        grid-template: [start form-s] auto [form-e separator-s] 70px [separator-e] auto [content-s] auto [end content-e]/[start form-s separator-s content-s] 1fr [] 1fr [] 1fr [] 1fr [end form-s separator-s content-s];
        margin-bottom: 35px; } }
    .b-store_locator-wrapper::before {
      background: #D2D2CE;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      width: 1px; }
      @media screen and (max-width: 767px) {
        .b-store_locator-wrapper::before {
          grid-area: separator-s / separator-s / separator-e / separator-e;
          height: 1px;
          left: auto;
          top: 50%;
          width: 100%; } }
  .b-store_locator-form {
    grid-area: form-s / form-s / form-e / form-e; }
  .b-store_locator-content {
    grid-area: content-s / content-s / content-e / content-e; }
  .b-store_locator-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    font-size: 16px;
    letter-spacing: 2.4px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 16px; }
    .b-store_locator-link:visited {
      color: var(--color-link-visited, #584C44); }
    .b-store_locator-link::after {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 13px;
      mask: url("./svg-icons/sprite.svg#line-arrow");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 50px;
      display: inline-block; }
    .b-store_locator-link:hover {
      text-decoration: none; }
      .b-store_locator-link:hover::after {
        animation: link-arrow 0.4s ease; }
  .b-store_locator-section {
    border: 1px solid #C4C2C2;
    display: grid;
    gap: 10px 20px;
    grid-template: 'image content' 'image link' / 60px 1fr;
    margin-bottom: 20px;
    padding: 20px; }
  .b-store_locator-section_image {
    grid-area: image; }
  .b-store_locator-section_description {
    font: 14px/1.57 "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    grid-area: content; }
  .b-store_locator-section_link {
    grid-area: link; }
  .b-store_locator .f-input_text {
    margin: 25px 0; }
  .b-store_locator .f-input_select::before {
    top: 25px; }
  .b-store_locator input.parsley-error,
  .b-store_locator select.parsley-error,
  .b-store_locator textarea.parsley-error {
    background-color: #fff;
    border-color: #A7A8A8;
    color: inherit; }
  .b-store_locator input.parsley-success,
  .b-store_locator select.parsley-success,
  .b-store_locator textarea.parsley-success {
    background-color: #fff;
    border-color: #A7A8A8;
    color: inherit; }
  .b-store_locator .parsley-errors-list {
    color: #CA1E36;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 8px; }
  .b-store_locator #acctGroup {
    margin: 25px 0; }
    .b-store_locator #acctGroup fieldset {
      border: none;
      display: flex;
      gap: 25px; }
    .b-store_locator #acctGroup .acctGrpWrapper {
      display: flex;
      align-items: center; }

#brandSelection {
  margin-bottom: 25px;
  max-width: 32.6%;
  margin-left: 8.3%; }
  #brandSelection .flavorSelect {
    padding: 5px 20px; }

@media screen and (max-width: 767px) {
  #brandSelection {
    margin-left: 0 !important;
    max-width: none; } }

@media (min-width: 767px) and (max-width: 1200px) {
  #brandSelection {
    margin-left: 0 !important;
    max-width: 40.8%; } }

/* stylelint-disable */
.l-page #wtb-overlay {
  background: #fff; }
  .l-page #wtb-overlay #closeBtn {
    font: 700 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    align-items: center;
    background: #F6F6F5;
    border: 0;
    color: #202025;
    display: flex;
    height: 60px;
    justify-content: center;
    text-shadow: none; }
    .l-page #wtb-overlay #closeBtn::after {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: block;
      height: 14px;
      mask: url("./svg-icons/sprite.svg#close");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 14px;
      margin-left: 10px; }
  .l-page #wtb-overlay #overlayContent #resultListings {
    background: #fff;
    box-shadow: none;
    width: 495px; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .l-page #wtb-overlay #overlayContent #resultListings {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .l-page #wtb-overlay #overlayContent #resultListings {
        width: 100%; } }
    .l-page #wtb-overlay #overlayContent #resultListings #resultItemsTab,
    .l-page #wtb-overlay #overlayContent #resultListings #wtbLogo {
      display: none; }
    .l-page #wtb-overlay #overlayContent #resultListings #listingsWrapper #listings {
      border-radius: 0;
      box-shadow: none;
      height: 100%;
      margin: 0;
      padding: 0 15px 0 30px;
      width: 100%; }
      @media screen and (max-width: 767px) {
        .l-page #wtb-overlay #overlayContent #resultListings #listingsWrapper #listings {
          padding: 0 15px; } }
      .l-page #wtb-overlay #overlayContent #resultListings #listingsWrapper #listings .listingItem {
        align-items: center;
        border-color: #C4C2C2;
        display: flex;
        padding: 20px 0 15px 20px; }
        .l-page #wtb-overlay #overlayContent #resultListings #listingsWrapper #listings .listingItem:hover {
          background: #C4C2C2; }
        .l-page #wtb-overlay #overlayContent #resultListings #listingsWrapper #listings .listingItem:hover .acctInfo {
          font-weight: bold; }
        .l-page #wtb-overlay #overlayContent #resultListings #listingsWrapper #listings .listingItem .acctInfo {
          font: 16px/1.5 "Proxima Nova", sans-serif;
          letter-spacing: 0.2px;
          width: 100%; }
        .l-page #wtb-overlay #overlayContent #resultListings #listingsWrapper #listings .listingItem .marker {
          margin-right: 20px; }
  .l-page #wtb-overlay #mobileNavWrapper {
    background: #fff;
    border-bottom: 1px solid #C4C2C2;
    margin: 0 15px;
    text-align: center;
    width: calc(100% - 30px); }
    .l-page #wtb-overlay #mobileNavWrapper #mapNav,
    .l-page #wtb-overlay #mobileNavWrapper #acctNav {
      background: #fff;
      border: none;
      color: #202025;
      font-size: 16px;
      font-weight: bold;
      height: 70px;
      letter-spacing: 2.4px;
      line-height: 24px;
      padding: 0 15px;
      text-transform: uppercase;
      width: auto; }
      .l-page #wtb-overlay #mobileNavWrapper #mapNav[data-acct-nav-clicked="true"],
      .l-page #wtb-overlay #mobileNavWrapper #acctNav[data-acct-nav-clicked="true"] {
        color: #202025; }
    .l-page #wtb-overlay #mobileNavWrapper #mapNav {
      text-align: right; }
    .l-page #wtb-overlay #mobileNavWrapper #acctNav {
      text-align: left; }
  .l-page #wtb-overlay #noData {
    font: 18px/26px "Proxima Nova", sans-serif;
    letter-spacing: 0.2px;
    color: #CA1E36;
    margin: 0 auto;
    max-width: 680px;
    margin-top: 50px; }
    @media screen and (max-width: 767px) {
      .l-page #wtb-overlay #noData {
        padding: 0 var(--grid-gutter); } }

.l-page .gm-style .gm-style-iw-c {
  font: 16px/1.5 "Proxima Nova", sans-serif;
  letter-spacing: 0.2px;
  border-radius: 0;
  border: 1px solid #C4C2C2;
  padding: 20px !important; }
  .l-page .gm-style .gm-style-iw-c .infoWinHeading {
    font-weight: 400; }

.l-page .gm-style .gm-style-iw-t::after {
  display: none; }

.l-page .gm-style .gm-style-iw-d {
  max-height: none !important;
  min-width: 180px;
  overflow: auto !important; }

.l-page .gm-ui-hover-effect {
  height: auto !important;
  right: 10px !important;
  top: 10px !important;
  width: auto !important; }
  .l-page .gm-ui-hover-effect img {
    margin: 0 !important; }

/* stylelint-enable */
body {
  --page_opacity: 1;
  --page_visibility: visible; }

.b-header-account,
.b-header-navigation_bottom,
.b-header-navigation_link {
  display: block; }

.video-container {
  overflow: hidden;
  padding-top: 52%;
  position: relative;
  width: 100%; }
  .video-container iframe {
    border: none;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }

.b-pd_picture-image-fit-contain {
  object-fit: contain; }

.age-gate-enter-form {
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    .age-gate-enter-form {
      flex-wrap: wrap; } }
  .age-gate-enter-form .age-gate-form-field {
    width: 120px; }
    @media screen and (max-width: 767px) {
      .age-gate-enter-form .age-gate-form-field {
        width: 25%; } }
  .age-gate-enter-form .age-gate-input {
    color: #424546;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    padding-left: 10px; }
    @media screen and (max-width: 767px) {
      .age-gate-enter-form .age-gate-input {
        margin-bottom: 8px; } }
    .age-gate-enter-form .age-gate-input:not(:last-child) {
      margin-right: 10px;
      width: 90%; }
  .age-gate-enter-form input[type=number]::-webkit-outer-spin-button,
  .age-gate-enter-form input[type=number]::-webkit-inner-spin-button {
    display: unset; }
  .age-gate-enter-form .b-form-message.m-error {
    margin: 8px 8px 32px 0;
    padding: 8px;
    width: 90%; }
    @media screen and (max-width: 767px) {
      .age-gate-enter-form .b-form-message.m-error {
        font-size: 12px;
        line-height: 20px;
        padding: 4px; } }
  .age-gate-enter-form .cta_btn--age-gate {
    background: #fff;
    border: 1px solid #000;
    color: #424546;
    display: inline-block;
    font-size: 0.88889em;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.01em;
    min-width: 154px;
    padding: 11px 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s linear;
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .age-gate-enter-form .cta_btn--age-gate {
        margin-top: 8px;
        width: 100%; } }

.price-override-tool {
  display: none; }

.b-product_badge.m-promo {
  width: fit-content; }

.b-product_badge-image {
  border: none; }
  .b-product_badge-image img {
    height: 84px;
    width: 84px; }
    @media screen and (max-width: 767px) {
      .b-product_badge-image img {
        height: 50px;
        width: 50px; } }

.b-product_badge.m-rating_points {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 84px;
  justify-content: center;
  margin-bottom: 16px;
  right: 0 !important;
  width: 84px; }
  @media screen and (max-width: 767px) {
    .b-product_badge.m-rating_points {
      height: 50px;
      width: 50px; } }

.badges {
  bottom: 30px;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: 'myArea1' 'myArea2' 'myArea3';
  position: absolute;
  right: 1px;
  z-index: 1; }
  .badges .myArea1 {
    grid-area: myArea1;
    margin-bottom: 0; }
  .badges .myArea2 {
    grid-area: myArea2;
    margin-bottom: 0; }
  .badges .myArea3 {
    grid-area: myArea3;
    margin-bottom: 16px; }

.b-product_price-generic {
  display: none; }

@media screen and (max-width: 767px) {
  #b-product_price-allbundleProductsPrice {
    display: none; } }

@media screen and (max-width: 767px) {
  #b-product_price-bundlePrice {
    display: block; } }

@media screen and (min-width: 768px) {
  #b-product_price-bundlePrice {
    display: none; } }

@media screen and (min-width: 768px) {
  .b-footer_social {
    padding-top: 64px; } }

.b-footer-email_subscription + .b-footer-social .b-footer_social {
  padding-top: 0; }

.strike-through-oldprice {
  text-decoration: line-through; }

.b-product_quantity.quantity-color {
  background: #eeeeec;
  border: 0 !important; }

.b-product_quantity .b-product_quantity-input.quantity-color {
  background: #eeeeec; }

.b-product_tile.m-grid .b-product_quantity.quantity-color {
  background: #eeeeec;
  border: 0 !important; }

.b-product_tile.m-grid .b-product_quantity .b-product_quantity-input.quantity-color {
  background: #eeeeec; }

.b-store_locator-submit_wrapper .b-store_locator-submit {
  max-width: 50%; }
  @media screen and (max-width: 767px) {
    .b-store_locator-submit_wrapper .b-store_locator-submit {
      display: flex;
      margin: 40px auto 0; } }

.b-store-wrapper {
  max-height: 200px;
  overflow-y: auto; }
  .b-store-wrapper .b-store_details {
    margin-bottom: 15px;
    font-size: 22px;
    padding-bottom: 10px;
    border-bottom: 1px solid; }

.sfcc-store-locator-error {
  margin-top: 8px; }

.Customizeshipment-heading {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.31px;
  line-height: 40px;
  margin: 5px 0 23px; }

.customizeShipment-title {
  border-bottom: 1px solid;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 32px;
  padding-bottom: 9px; }

.shipment-details {
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  padding-top: 40px; }
  @media screen and (min-width: 768px) {
    .shipment-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between; } }
  .shipment-details .wineshipment-priceinfo {
    display: flex;
    flex-direction: column;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    justify-content: space-between;
    letter-spacing: 0.15px;
    line-height: 24px; }
    @media screen and (min-width: 768px) {
      .shipment-details .wineshipment-priceinfo {
        justify-content: flex-start;
        width: 80%; } }
    .shipment-details .wineshipment-priceinfo span {
      margin-right: 32px;
      padding-left: 29px; }
    .shipment-details .wineshipment-priceinfo .wineshipment-error,
    .shipment-details .wineshipment-priceinfo .shipcompliant-error {
      padding: 0; }
      .shipment-details .wineshipment-priceinfo .wineshipment-error .errorMesg,
      .shipment-details .wineshipment-priceinfo .shipcompliant-error .errorMesg {
        color: #CA1E36;
        font-size: 16px;
        line-height: 20px;
        padding: 8px 0; }
    .shipment-details .wineshipment-priceinfo .customization-info {
      margin-top: 8px; }
  .shipment-details .minshipment-priceinfo {
    font-family: "Proxima Nova", sans-serif;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 16px; }
  .shipment-details .saveShipment-cta {
    background-color: #584C44;
    color: #fff;
    cursor: pointer;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.15px;
    line-height: 21px;
    margin: 16px auto 25px;
    padding: 16px 20px;
    text-align: center;
    text-transform: uppercase;
    width: 248px; }
    @media screen and (min-width: 768px) {
      .shipment-details .saveShipment-cta {
        margin: 0;
        width: 320px; } }

.shipment-products {
  display: flex;
  flex-wrap: wrap; }
  .shipment-products .wcproductQuantity-tile {
    flex: 100%;
    max-width: 320px;
    min-height: 353px; }
    @media screen and (min-width: 768px) {
      .shipment-products .wcproductQuantity-tile {
        flex: 0 0 25%;
        min-width: 274px; } }
    .shipment-products .wcproductQuantity-tile img {
      height: 144px; }
    .shipment-products .wcproductQuantity-tile .wcproductprice {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.15px;
      line-height: 24px;
      margin: 8px; }
    .shipment-products .wcproductQuantity-tile .b-product_tile-cta {
      border: 0;
      border-top: 1px solid #fff;
      color: #fff;
      margin-top: auto;
      width: 100%; }

.shipment-newproducts .wcproductQuantity-tile {
  background-color: #fff;
  border: 1px solid #202025;
  color: #202025; }

.customizeShipment-msg {
  display: flex;
  flex-direction: column;
  margin: 25px 0;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .customizeShipment-msg {
      flex-direction: row;
      justify-content: center; }
      .customizeShipment-msg span {
        padding-top: 0; } }
  .customizeShipment-msg span {
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    padding-left: 8px;
    padding-top: 16px;
    text-decoration: underline;
    text-transform: uppercase; }

.mainContentPD .b-carousel-title_container {
  margin-top: 56px; }
  .mainContentPD .b-carousel-title_container .b-carousel-title {
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: bolder; }

.mainContentPD .b-carousel-control {
  border: 0; }
  .mainContentPD .b-carousel-control:hover {
    box-shadow: none; }

.mainContentPD .b-product_tile-image {
  background: transparent; }

.mainContentPD .b-product_tile-actions {
  flex-direction: column; }

.mainContentPD .b-add_to_wishlist {
  display: none; }

.mainContentPD .b-product_tile-quantity {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .mainContentPD .b-product_tile-quantity {
      width: 100%; } }

.mainContentPD .b-product_tile-cta {
  background-color: #fff;
  color: #202025;
  width: 100%; }

.mainContentPD .b-product_quantity {
  border: 2px solid #202025;
  margin: 8px 0; }
  @media screen and (min-width: 768px) {
    .mainContentPD .b-product_quantity {
      border: 2px solid #202025;
      margin: 8px 0; } }

.mainContentPD .b-product_tile.m-grid .b-product_quantity {
  border: 2px solid #202025; }
  @media screen and (min-width: 768px) {
    .mainContentPD .b-product_tile.m-grid .b-product_quantity {
      border: 2px solid #202025; } }

.wineshipmentSwap-error,
.override-batch-sc-error {
  text-align: center; }
  .wineshipmentSwap-error .errorMesg,
  .override-batch-sc-error .errorMesg {
    color: #CA1E36; }

.wineshipment-override-error {
  text-align: center; }
  .wineshipment-override-error .errorMesg {
    color: #CA1E36; }

.override-batch_dialog-header {
  align-items: baseline;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }
  .override-batch_dialog-header .b-search_dialog-form_wrap {
    margin-bottom: 0; }
  .override-batch_dialog-header .b-search_dialog-inner {
    padding: 32px;
    width: 80%; }
    .override-batch_dialog-header .b-search_dialog-inner .m-no_results {
      padding: 32px 0; }

.override-batch-dialog-body {
  background-color: #fff;
  padding-bottom: 110px;
  padding-top: 150px; }

.override-batch-dialog-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }
  .override-batch-dialog-inner .override-batch_product {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 16px;
    width: 82%; }
    @media screen and (max-width: 767px) {
      .override-batch-dialog-inner .override-batch_product .override-batch_product_details-name {
        width: 60%; } }
    .override-batch-dialog-inner .override-batch_product:not(:last-of-type) {
      border-bottom: 1px solid #D2D2CE; }
    .override-batch-dialog-inner .override-batch_product .override-batch_product-image {
      margin-right: 48px;
      max-width: 87px; }
      @media screen and (max-width: 767px) {
        .override-batch-dialog-inner .override-batch_product .override-batch_product-image {
          margin-right: 16px; } }
    .override-batch-dialog-inner .override-batch_product .override-batch_product-quantity {
      margin-left: auto;
      padding: 8px; }
      @media screen and (max-width: 767px) {
        .override-batch-dialog-inner .override-batch_product .override-batch_product-quantity {
          margin-left: 32%; } }
      .override-batch-dialog-inner .override-batch_product .override-batch_product-quantity .override-product_quantity-label {
        font-size: 14px;
        margin-right: 8px; }
      .override-batch-dialog-inner .override-batch_product .override-batch_product-quantity .override-product_quantity-input {
        border: 1px solid #D2D2CE;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        text-align: center; }

.override-batch-modal_footer.b-dialog-footer {
  background-color: #fff;
  border-top: 1px solid #D2D2CE;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  margin-top: 60px;
  position: fixed;
  width: 100%; }
  .override-batch-modal_footer.b-dialog-footer .b-form-btn_main,
  .override-batch-modal_footer.b-dialog-footer .b-form-btn_alt {
    margin: 25px 0;
    width: 20%; }
    @media screen and (max-width: 767px) {
      .override-batch-modal_footer.b-dialog-footer .b-form-btn_main,
      .override-batch-modal_footer.b-dialog-footer .b-form-btn_alt {
        margin: 16px 0;
        width: 35%; } }
  .override-batch-modal_footer.b-dialog-footer .b-form-btn_alt {
    border: 1px solid #BA8D36; }

.your-shipment .heading {
  border-bottom: 1px solid inherit;
  font-size: 24px;
  letter-spacing: 0.23;
  line-height: 32px;
  margin-bottom: 30px;
  padding-bottom: 8px; }

.your-shipment .shipment-card {
  border: 1px solid inherit;
  margin-bottom: 30px;
  padding: 25px;
  padding-top: 0;
  position: relative; }
  .your-shipment .shipment-card .shipment-error {
    color: #CA1E36;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px; }
  .your-shipment .shipment-card .process-order-error {
    color: #CA1E36;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px; }
  .your-shipment .shipment-card .skip-modal {
    text-align: center; }
    @media screen and (max-width: 1199px) {
      .your-shipment .shipment-card .skip-modal {
        margin-top: 80px; } }
    .your-shipment .shipment-card .skip-modal .skip {
      font-size: 16px;
      letter-spacing: 0.15px;
      line-height: 24px;
      text-transform: uppercase; }
  .your-shipment .shipment-card .title-block {
    display: flex;
    font-family: "Proxima Nova", sans-serif;
    justify-content: space-between;
    margin-bottom: 38px;
    margin-top: 61px; }
    .your-shipment .shipment-card .title-block .title {
      font-size: 24px;
      letter-spacing: 0.23;
      line-height: 32px; }
    .your-shipment .shipment-card .title-block .skip {
      font-size: 16px;
      letter-spacing: 0.15;
      line-height: 24px;
      text-transform: uppercase; }
  .your-shipment .shipment-card .shipment-type {
    background: #202025;
    color: #fff;
    padding: 7px 0 7px 16px;
    position: absolute;
    right: 0;
    width: 100%; }
    .your-shipment .shipment-card .shipment-type .title {
      font-family: "Proxima Nova", sans-serif;
      font-size: 14px;
      letter-spacing: 0.13px;
      line-height: 16px;
      text-transform: capitalize;
      width: 100%; }
      .your-shipment .shipment-card .shipment-type .title::after {
        content: ' |';
        margin: 0 3px; }
    .your-shipment .shipment-card .shipment-type.confirm {
      background-color: #A7A8A8;
      color: #202025; }
      .your-shipment .shipment-card .shipment-type.confirm .title::after {
        content: ''; }
    .your-shipment .shipment-card .shipment-type .customize {
      font-size: 14px;
      letter-spacing: 0.13px;
      line-height: 16px; }
  .your-shipment .shipment-card .shipment-info {
    display: block; }
    @media screen and (min-width: 768px) {
      .your-shipment .shipment-card .shipment-info {
        border-bottom: 1px solid inherit;
        display: flex; } }
    @media screen and (min-width: 768px) {
      .your-shipment .shipment-card .shipment-info .shipment-info-col {
        display: inline-block;
        padding-right: 50px;
        width: 50%; } }
    .your-shipment .shipment-card .shipment-info .shipment-info-col .reschedule-modal-link {
      margin-bottom: 30px;
      text-align: left; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .reschedule-modal-link .b-login_helpers-forgot_link {
        font-size: 16px;
        letter-spacing: 0.15px;
        line-height: 24px; }
    .your-shipment .shipment-card .shipment-info .shipment-info-col .info-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .info-list:nth-child(n-1) {
        margin-bottom: 16px; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .info-list .info-title {
        font-family: "Proxima Nova", sans-serif;
        font-size: 16px;
        letter-spacing: 0.15;
        line-height: 24px; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .info-list .info-amt {
        font-family: "Proxima Nova", sans-serif;
        font-size: 16px;
        letter-spacing: 0.15;
        line-height: 24px; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .info-list.total {
        border-top: 1px solid inherit;
        font-family: "Proxima Nova", sans-serif;
        font-weight: bold;
        padding-top: 16px; }
    .your-shipment .shipment-card .shipment-info .shipment-info-col .discount {
      color: inherit; }
    .your-shipment .shipment-card .shipment-info .shipment-info-col .info-shipmentDate {
      font-family: "Proxima Nova", sans-serif;
      font-size: 20px;
      letter-spacing: 0.19px;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 25px; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .info-shipmentDate .info-amt.shipment-date-info {
        font-weight: bold; }
    .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info {
      display: block;
      flex-direction: column;
      margin-bottom: 40px; }
      @media screen and (min-width: 768px) {
        .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info {
          display: inline-flex;
          flex-direction: column;
          margin-bottom: 40px;
          margin-right: 20px;
          width: 42%; } }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info .title {
        display: block;
        font-family: "Proxima Nova", sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.15;
        line-height: 24px;
        margin-bottom: 16px; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info .description {
        display: block;
        font-family: "Proxima Nova", sans-serif;
        font-size: 16px;
        letter-spacing: 0.15px;
        line-height: 24px;
        word-wrap: break-word; }
        @media screen and (max-width: 767px) {
          .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info .description {
            padding-bottom: 8px; } }
        .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info .description p {
          margin-bottom: 0; }
        .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info .description.info {
          color: inherit;
          font-size: 14px;
          letter-spacing: 0.13;
          line-height: 16px; }
      .your-shipment .shipment-card .shipment-info .shipment-info-col .shipping-info a {
        color: #584C44;
        margin-top: 16px;
        padding-bottom: 3px;
        text-transform: uppercase;
        width: fit-content; }
  .your-shipment .shipment-card .shipping-products .shipping-header {
    justify-content: space-between;
    margin: 24px 0; }
    @media screen and (min-width: 768px) {
      .your-shipment .shipment-card .shipping-products .shipping-header {
        display: flex; } }
    .your-shipment .shipment-card .shipping-products .shipping-header .title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.15;
      line-height: 24px;
      text-transform: capitalize; }
      @media screen and (min-width: 768px) {
        .your-shipment .shipment-card .shipping-products .shipping-header .title {
          display: flex; } }
    @media screen and (max-width: 767px) {
      .your-shipment .shipment-card .shipping-products .shipping-header .b-account_card-button.customizecard {
        bottom: 80px;
        left: 18px;
        padding: 10px 0px;
        position: absolute;
        width: 90%; } }
  .your-shipment .shipment-card .shipping-products .shipping-items .item {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 24px; }
    @media screen and (min-width: 768px) {
      .your-shipment .shipment-card .shipping-products .shipping-items .item {
        width: 49%; } }
    .your-shipment .shipment-card .shipping-products .shipping-items .item .item-container {
      display: flex;
      justify-content: start; }
      .your-shipment .shipment-card .shipping-products .shipping-items .item .item-container .product-image {
        height: 104px;
        margin-right: 24px;
        max-width: 56px; }
        .your-shipment .shipment-card .shipping-products .shipping-items .item .item-container .product-image img {
          height: auto;
          min-height: 100%;
          min-width: 100%;
          object-fit: cover;
          width: 100%; }
      .your-shipment .shipment-card .shipping-products .shipping-items .item .item-container .product-desc .title {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.15;
        line-height: 24px;
        margin-bottom: 16px;
        text-transform: capitalize; }
      .your-shipment .shipment-card .shipping-products .shipping-items .item .item-container .product-desc .price {
        font-family: "Proxima Nova", sans-serif;
        font-size: 16px;
        letter-spacing: 0.15;
        line-height: 21px;
        margin-bottom: 12px; }
        .your-shipment .shipment-card .shipping-products .shipping-items .item .item-container .product-desc .price .m-old {
          margin-right: 15px;
          text-decoration: line-through; }
      .your-shipment .shipment-card .shipping-products .shipping-items .item .item-container .product-desc .qty {
        font-size: 16px;
        letter-spacing: 0.15;
        line-height: 21px;
        text-transform: uppercase; }

.skip-shipment {
  border: 1px solid #202025;
  margin-bottom: 20px; }
  .skip-shipment .header {
    background-color: #584C44;
    color: #fff;
    font: 12px/18px "Proxima Nova", sans-serif;
    letter-spacing: 0.4px;
    padding: 4px 18px 4px 16px; }
  .skip-shipment .content {
    padding: 32px 16px 24px; }
    .skip-shipment .content .title {
      font: 24px/40px "Proxima Nova", sans-serif;
      letter-spacing: 0.6px;
      margin-bottom: 24px; }
    .skip-shipment .content .undo {
      color: var(--color-link, inherit);
      cursor: pointer;
      display: inline;
      font-weight: 600;
      letter-spacing: 0.16px;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 90%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      padding-bottom: 7px;
      background-position: 0 50%;
      color: #584C44;
      font: 16px/24px "Proxima Nova", sans-serif;
      letter-spacing: 0.5px; }
      .skip-shipment .content .undo:visited {
        color: var(--color-link-visited, #584C44); }
      .skip-shipment .content .undo:hover {
        text-decoration: none; }
      .skip-shipment .content .undo.m-disabled {
        color: var(--color-link-disabled, #D2D2CE);
        pointer-events: none; }
      .skip-shipment .content .undo:visited {
        color: var(--color-link-visited, #584C44); }
      .skip-shipment .content .undo:hover {
        text-decoration: none; }
        @media (hover: hover) and (pointer: fine) {
          .skip-shipment .content .undo:hover {
            animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
      .skip-shipment .content .undo.m-disabled {
        color: var(--color-link-disabled, #D2D2CE);
        pointer-events: none; }
      @media screen and (max-width: 767px) {
        .skip-shipment .content .undo {
          background-position: 0 90%; } }
    @media screen and (min-width: 1200px) {
      .skip-shipment .content {
        display: flex;
        justify-content: space-between;
        padding: 40px 48px 44px; } }

.b-dialog-window .b-dialog-header .b-dialog-title {
  color: #202025;
  font-family: "Proxima Nova", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 40px;
  text-align: center; }

@media screen and (max-width: 1199px) {
  .wineclub-modal {
    margin: 65px 20px 0 18px;
    overflow-y: auto !important; } }

.wineclub-modal.m-quick_view.small-modal .b-dialog-window {
  max-width: 656px; }

.wineclub-modal.m-quick_view .b-dialog-window {
  padding: 32px 30px; }
  @media screen and (min-width: 1200px) {
    .wineclub-modal.m-quick_view .b-dialog-window {
      padding: 40px; } }
  .wineclub-modal.m-quick_view .b-dialog-window .b-dialog-header {
    text-align: right; }
  .wineclub-modal.m-quick_view .b-dialog-window .b-dialog-body {
    margin: 0 auto;
    text-align: center;
    width: 80%; }
    .wineclub-modal.m-quick_view .b-dialog-window .b-dialog-body .skip-count {
      font-size: 16px;
      letter-spacing: 0.15px;
      line-height: 24px;
      margin-bottom: 8px; }
    .wineclub-modal.m-quick_view .b-dialog-window .b-dialog-body .skip-text {
      font-size: 24px;
      letter-spacing: 0.23px;
      line-height: 32px;
      margin-bottom: 16px; }
    .wineclub-modal.m-quick_view .b-dialog-window .b-dialog-body .skip-confirm {
      font-size: 16px;
      letter-spacing: 0.15px;
      line-height: 24px;
      margin-bottom: 40px; }

.manage-equinox img {
  margin-bottom: 64px; }

.manage-equinox p {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center; }
  .manage-equinox p.title {
    font-weight: bold;
    margin-bottom: 8px; }

.manage-equinox .skipReturn {
  margin: 64px 0 32px;
  max-width: 432px; }

.manage-equinox .skip {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-transform: uppercase; }

.edit-milkRun .title {
  font-family: "Proxima Nova", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 32px;
  margin-bottom: 32px; }

.edit-milkRun .prefName {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: 16px; }

.edit-milkRun .qty-available {
  display: flex;
  justify-content: center;
  margin: 16px 0; }
  .edit-milkRun .qty-available .quantity-options {
    margin: 0 5px; }
    .edit-milkRun .qty-available .quantity-options .radio__label {
      background-color: #fff;
      border: 1px solid #202025;
      border-radius: 30px;
      color: #202025;
      cursor: pointer;
      padding: 8px; }
      .edit-milkRun .qty-available .quantity-options .radio__label.selected {
        background-color: #202025;
        color: #fff; }

.edit-milkRun .price {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px; }

.edit-milkRun .disc-price {
  color: #D2D2CE;
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  letter-spacing: 0.12px;
  line-height: 24px;
  text-align: center; }

.edit-milkRun .UpdateMilkRunPrefQty {
  background-color: #202025;
  border-color: #BA8D36;
  color: #fff;
  margin: 16px 0 32px; }
  .edit-milkRun .UpdateMilkRunPrefQty:hover {
    background-color: #fff;
    border-color: #BA8D36;
    color: #202025; }

.edit-milkRun .cancel {
  color: #584C44;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase; }

.edit-milkRun .wcproduct-tileimage {
  width: 100%; }

.edit-milkRun .radio__label svg {
  color: #BA8D36; }

.edit-milkRun .radio__label.selected svg {
  color: #fff; }

.cancel-membership-alert .heading {
  font-family: "Proxima Nova", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 32px;
  margin-bottom: 16px;
  text-align: left; }
  .cancel-membership-alert .heading.success {
    margin-bottom: 40px; }

.cancel-membership-alert .desc {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 40px;
  text-align: left; }
  .cancel-membership-alert .desc.alt {
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 16px;
    margin: 56px 0 0; }

.cancel-membership-alert .b-form-btn_alt {
  margin-bottom: 16px; }
  .cancel-membership-alert .b-form-btn_alt.block {
    background-color: #fff;
    border-color: #BA8D36;
    color: #202025; }
    .cancel-membership-alert .b-form-btn_alt.block:hover {
      background-color: #BA8D36;
      border-color: #BA8D36;
      color: #202025; }
    .cancel-membership-alert .b-form-btn_alt.block.m0 {
      margin: 0; }

.membership-cancel-reason,
.select-shipment-method {
  border: 2px solid #202025;
  color: #0c0c0c;
  display: block;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  height: 56px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin: 24px auto;
  padding: 8px 12px;
  width: 50%; }
  @media screen and (max-width: 767px) {
    .membership-cancel-reason,
    .select-shipment-method {
      width: 100%; } }

.wcmembership-details {
  margin-top: 10px; }

.membership-title {
  border-bottom: 1px solid #202025;
  color: #202025;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 32px;
  margin-bottom: 24px;
  padding: 24px 0 9px; }

.wcmembership-info {
  border: 1px solid #A7A8A8;
  color: #202025;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4px 0 8px;
  padding: 10px; }

@media screen and (max-width: 767px) {
  .wcmembership-manage.wc-hide-mobile {
    display: none; } }

.wcmembership-title-container {
  display: flex;
  justify-content: space-between; }

.b-account_info_tile-footer .paymentcardlength-1 {
  display: none; }

.non-wine-club-member .payment-method .b-account_info_tile-link.m-set_default.wineclub-default,
.non-wine-club-member .payment-method .b-account_info_tile-title.wineclub-default {
  display: none; }

@media screen and (min-width: 768px) {
  .wc-hide-mobile {
    display: block; }
  .wc-visible-mobile {
    display: none; }
  .wcmembership-info {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 19px 16px 25px; } }

.wcmembership-product-container {
  display: flex;
  font: 16px/24px "Proxima Nova", sans-serif;
  justify-content: space-between;
  letter-spacing: 0.5px; }
  @media screen and (min-width: 768px) {
    .wcmembership-product-container {
      width: 60%; } }
  .wcmembership-product-container .wcmembership-manage {
    font: 16px/24px "Proxima Nova", sans-serif;
    letter-spacing: 0.5px;
    text-transform: capitalize; }

.wcmembership-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding-bottom: 5px; }

.wcmembership-productName {
  min-width: 153px; }

.wcmembership-manage {
  color: var(--color-link, inherit);
  cursor: pointer;
  display: inline;
  font-weight: 600;
  letter-spacing: 0.16px;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 90%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  padding-bottom: 7px;
  color: #584C44;
  cursor: pointer;
  font: 16px/24px "Proxima Nova", sans-serif;
  padding-left: 10px;
  text-transform: uppercase; }
  .wcmembership-manage:visited {
    color: var(--color-link-visited, #584C44); }
  .wcmembership-manage:hover {
    text-decoration: none; }
  .wcmembership-manage.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  .wcmembership-manage:visited {
    color: var(--color-link-visited, #584C44); }
  .wcmembership-manage:hover {
    text-decoration: none; }
    @media (hover: hover) and (pointer: fine) {
      .wcmembership-manage:hover {
        animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
  .wcmembership-manage.m-disabled {
    color: var(--color-link-disabled, #D2D2CE);
    pointer-events: none; }
  @media screen and (min-width: 768px) {
    .wcmembership-manage {
      padding: 0; } }

.addwcmembership-cta {
  background: #fff;
  border: 1px solid #584C44;
  color: #584C44;
  cursor: pointer;
  font: 16px/24px "Proxima Nova", sans-serif;
  height: 56px;
  letter-spacing: 0.5px;
  margin-bottom: 32px;
  padding: 11px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }

.addwcmembership-cta:hover {
  background-color: #584C44;
  color: #fff; }

.addwcmembership-cta > a {
  display: block;
  padding-top: 5px; }

.addwcmembership-cta > a::before {
  content: '+';
  padding-right: 3px; }

.addwcmembership-cta > a:hover {
  text-decoration: none; }

.wineclub-title {
  color: #202025;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.31px;
  line-height: 40px; }

.nonmember-msg {
  color: #202025;
  display: flex;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-top: 16px; }

.b-user_greeting-signout {
  color: #584C44; }

.l-account-navigation_cta_text {
  color: #584C44;
  text-transform: capitalize; }

.addwcmember-cta {
  background-color: #584C44;
  border: 2px solid #fff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 21px;
  margin-top: 57px;
  padding: 16px 27px;
  text-align: center;
  width: 100%; }

@media screen and (min-width: 480px) {
  .addwcmember-cta {
    width: 432px; } }

.addwcmember-cta a:hover {
  text-decoration: none; }

.addwcmember-cta a {
  display: block;
  text-transform: uppercase; }

.modalDialog {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  z-index: 99999;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in; }

.modalDialog:target {
  opacity: 1;
  pointer-events: auto; }

.close {
  background: #606061;
  border-radius: 12px;
  box-shadow: 1px 1px 3px #000;
  color: #ffffff;
  font-weight: bold;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  text-decoration: none;
  top: -10px;
  width: 24px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000; }

.close:hover {
  background: #00d9ff; }

.btn-main:disabled,
.btn-main[disabled] {
  background-color: #cccccc;
  border: 1px solid #b8a9a9;
  color: #666666; }

.form-modal-container {
  background-color: #ffffff;
  height: 748px;
  margin: 5% auto;
  padding: 5px 20px 13px;
  position: relative;
  width: 1104px; }

.prefName {
  font: 700 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px;
  padding: 3px; }

.shipment-newproducts {
  display: contents; }
  .shipment-newproducts .saveShipment-cta {
    cursor: pointer; }

.swap-product-tile,
.added-product-tile {
  border: 1px solid #202025;
  min-height: 350px;
  position: relative;
  text-align: center;
  width: 32%; }
  @media screen and (max-width: 767px) {
    .swap-product-tile,
    .added-product-tile {
      margin: 15px 0;
      min-height: 330px;
      width: 100%; } }

.shipment-products {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    .shipment-products {
      display: block;
      width: 100%; } }
  .shipment-products .radio__input {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%; }
  .shipment-products .radio__label {
    background-color: #fff;
    border: 1px solid #202025;
    border-radius: 20px;
    color: #202025;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 5px;
    padding: 3px 5px;
    text-align: center;
    transition: 0.25s;
    width: 84px; }
  .shipment-products .radio__label span {
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    width: 25px; }
  .shipment-products .radio__input:checked + .radio__label {
    background-color: #584C44;
    color: #fff; }
    .shipment-products .radio__input:checked + .radio__label svg {
      color: #fff; }

.wcproductQuantity-bottom {
  bottom: 0;
  margin-top: 65px;
  position: absolute;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .wcproductQuantity-bottom {
      margin-top: 20px; } }
  .wcproductQuantity-bottom .b-product_tile-cta {
    border: 0;
    border-top: 1px solid #D2D2CE;
    color: #202025;
    display: block;
    font-family: "Proxima Nova", sans-serif;
    width: 100%; }
    .wcproductQuantity-bottom .b-product_tile-cta::before {
      background: var(--icon-color, #BA8D36);
      content: '';
      display: inline-block;
      height: 12px;
      margin-right: 10px;
      mask: url("svg-icons/Swap.svg");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 12px; }
    .wcproductQuantity-bottom .b-product_tile-cta.wcproduct-remove::before {
      mask: url("svg-icons/close.svg"); }
    .wcproductQuantity-bottom .b-product_tile-cta:hover::before {
      background: var(--icon-color, #fff); }

.shipment-products .wcproduct-name.prefName {
  display: -webkit-box;
  margin-top: 20px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.shipment-newproducts .b-product_tile,
.shipment-newproducts .added-product-tile,
.shipment-products .b-product_tile,
.shipment-products .added-product-tile {
  border: solid 1px;
  position: relative;
  width: 32%; }
  @media screen and (max-width: 767px) {
    .shipment-newproducts .b-product_tile,
    .shipment-newproducts .added-product-tile,
    .shipment-products .b-product_tile,
    .shipment-products .added-product-tile {
      margin: 15px 0;
      min-height: 380px;
      width: 100%; } }

.shipment-newproducts .b-product_badge-image,
.shipment-products .b-product_badge-image {
  display: none; }

.shipment-newproducts .b-product_tile-image_wrapper,
.shipment-products .b-product_tile-image_wrapper {
  height: 144px;
  margin-bottom: 0;
  margin-top: 8px; }

.shipment-newproducts .b-product_tile-image,
.shipment-products .b-product_tile-image {
  height: 144px;
  padding-bottom: 0; }
  .shipment-newproducts .b-product_tile-image img,
  .shipment-products .b-product_tile-image img {
    height: 100%;
    object-fit: contain;
    position: relative; }

.shipment-newproducts .b-product_tile-name,
.shipment-products .b-product_tile-name {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  margin-top: 20px;
  overflow: hidden;
  padding: 3px;
  pointer-events: none;
  text-align: center;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: calc(2 * var(--product_tile-name-line_height) * 1em);
  line-height: var(--product_tile-name-line_height);
  --product_tile-name-line_height: 24px; }

.shipment-newproducts .b-product_tile-brand,
.shipment-products .b-product_tile-brand {
  display: none; }

.shipment-newproducts .b-product_tile-actions,
.shipment-products .b-product_tile-actions {
  flex-wrap: wrap;
  justify-content: center; }

.shipment-newproducts .b-product_tile-quantity,
.shipment-products .b-product_tile-quantity {
  margin-bottom: 16px;
  width: 40%; }
  @media screen and (max-width: 767px) {
    .shipment-newproducts .b-product_tile-quantity,
    .shipment-products .b-product_tile-quantity {
      margin: 0 auto 16px; } }
  .shipment-newproducts .b-product_tile-quantity .b-product_quantity,
  .shipment-products .b-product_tile-quantity .b-product_quantity {
    height: 52px; }

.shipment-newproducts .b-product_tile-cta,
.shipment-products .b-product_tile-cta {
  background-color: #fff;
  border: 0;
  border-top: 1px solid #D2D2CE;
  color: #202025;
  display: block;
  font-family: "Proxima Nova", sans-serif;
  width: 100%; }
  .shipment-newproducts .b-product_tile-cta.removeProdFromSession::before,
  .shipment-products .b-product_tile-cta.removeProdFromSession::before {
    background: var(--icon-color, #BA8D36);
    content: '';
    display: inline-block;
    height: 10px;
    mask: url("svg-icons/close.svg");
    mask-position: 50%;
    mask-repeat: no-repeat;
    margin-right: 10px;
    mask-size: 100%;
    width: 11px; }
  .shipment-newproducts .b-product_tile-cta:hover,
  .shipment-products .b-product_tile-cta:hover {
    background-color: #BA8D36;
    color: #202025; }
    .shipment-newproducts .b-product_tile-cta:hover:before,
    .shipment-products .b-product_tile-cta:hover:before {
      background: var(--icon-color, #fff); }

.shipment-newproducts .b-product_tile-description,
.shipment-products .b-product_tile-description {
  display: none; }

.shipment-newproducts .b-product_tile .b-add_to_wishlist,
.shipment-products .b-product_tile .b-add_to_wishlist {
  display: none; }

.shipment-newproducts .b-product_tile-promotion,
.shipment-products .b-product_tile-promotion {
  display: none; }

.shipment-newproducts .b-product_tile-availability,
.shipment-products .b-product_tile-availability {
  display: none; }

.shipment-newproducts .b-product_badge.m-rating_points,
.shipment-newproducts .b-product_badge.m-promo,
.shipment-newproducts .b-product_badge-image,
.shipment-products .b-product_badge.m-rating_points,
.shipment-products .b-product_badge.m-promo,
.shipment-products .b-product_badge-image {
  display: none !important; }

.address-book .b-account_info_tile-footer,
.payment-method .b-account_info_tile-footer {
  display: block; }

@media screen and (max-width: 767px) {
  .address-book .b-account_info_tile-content,
  .payment-method .b-account_info_tile-content {
    text-align: right; } }

.address-book .b-account_info_tile-title,
.payment-method .b-account_info_tile-title {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-right: auto; }

.address-book .b-account_info_tile-link.m-edit,
.payment-method .b-account_info_tile-link.m-edit {
  float: right;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .address-book .b-account_info_tile-link.m-edit,
    .payment-method .b-account_info_tile-link.m-edit {
      float: none; } }

.address-book .b-account_info_tile-link.m-delete,
.payment-method .b-account_info_tile-link.m-delete {
  float: right;
  margin-right: 10px;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    .address-book .b-account_info_tile-link.m-delete,
    .payment-method .b-account_info_tile-link.m-delete {
      float: none; } }

.address-book .b-account_info_tile-link.m-set_default,
.payment-method .b-account_info_tile-link.m-set_default {
  display: table; }

.address-book .b-account_info_tile .b-payment_icon,
.payment-method .b-account_info_tile .b-payment_icon {
  display: none; }

.payment-method .b-account_info_tile-link.m-set_default {
  display: inline-block; }

@media screen and (max-width: 767px) {
  .payment-method .b-account_info_tile-link.m-delete {
    float: right !important; } }

.payment-method .b-account_info_tile-footer > a:first-child {
  display: table !important; }

.payment-details .b-account_card-main {
  display: flex; }
  @media screen and (max-width: 767px) {
    .payment-details .b-account_card-main {
      display: block; } }

.payment-details .b-account_card-padding-right {
  padding-right: 15%; }

a.b-account_card-button,
.override-batch-button {
  border: 1px solid;
  height: 48px;
  padding: 5px;
  width: 296px; }

.b-account_card-button.override-batch-button {
  font-weight: 400; }

.b-account_card-link {
  color: var(--color-link, #584C44) !important;
  text-transform: uppercase; }

.address-book .pickupatwinery-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 15px; }

.address-book .b-account_info_tile-link.m-set_default {
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase; }

.address-book .b-account_info_tile-title {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px; }

.custom-shipment .qty-available {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 16px 0 24px; }

.custom-shipment .quantity-options .radio__label {
  width: 75px; }

.ground-description {
  padding-bottom: 8px; }

.f-input_text-label {
  font-size: 16px;
  font-weight: 200; }

fieldset.b-address_selector.b-form-set {
  margin-top: 50px; }

.l-account .b-address_selector-back {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  margin-bottom: 32px;
  text-transform: uppercase; }

.b-account_card-default_wineclub {
  display: flex; }
  @media screen and (max-width: 1199px) {
    .b-account_card-default_wineclub {
      display: block;
      padding-bottom: 30px; } }

.b-account_card-spaceaddress {
  padding-right: 10px; }

.b-account_card-defaultaddress {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  padding-top: 13px; }
  @media screen and (max-width: 1199px) {
    .b-account_card-defaultaddress {
      padding-top: 8px;
      width: max-content; } }
  .b-account_card-defaultaddress svg {
    height: 12px;
    width: 17px; }

.b-account_card-pickwinery {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  padding-top: 10px; }
  @media screen and (max-width: 1199px) {
    .b-account_card-pickwinery {
      padding-bottom: 8px;
      padding-top: 0; } }

.b-account_card-fullName {
  padding-top: 10px; }
  @media screen and (max-width: 1199px) {
    .b-account_card-fullName {
      padding-top: 0; } }

.b-account_card-button {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px; }

.b-account_card-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.23px; }

.b-account_card-bottomspaceaddress {
  font-size: 16px;
  margin: 10px 0 30px; }

.b-account_card-wine-body {
  font-size: 16px;
  padding-bottom: 20px; }
  @media screen and (max-width: 1199px) {
    .b-account_card-wine-body {
      margin: 10px 0 30px;
      padding-bottom: 0; } }

.b-account_card-billing-body {
  font-size: 16px;
  margin: 0; }

.b-account_info_tile .b-account_info_tile-content {
  position: relative; }
  .b-account_info_tile .b-account_info_tile-content .nonCompliantState_state_mesg {
    font-size: 12px;
    line-height: 16px;
    max-width: 190px;
    position: absolute;
    text-align: left; }
    @media screen and (max-width: 767px) {
      .b-account_info_tile .b-account_info_tile-content .nonCompliantState_state_mesg {
        display: flex;
        max-width: 54%; } }

.add-bottles-to-Shipment-container .b-carousel-title {
  font-family: "Proxima Nova", sans-serif !important; }

.add-bottles-to-Shipment-container .b-product_tile-name {
  color: #584C44;
  font: 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px; }

.add-bottles-to-Shipment-container .b-product_badge.m-rating_points,
.add-bottles-to-Shipment-container .b-product_badge.m-promo,
.add-bottles-to-Shipment-container .b-product_badge-image {
  display: none !important; }

.swap-bottle-dialog-body {
  background-color: #fff; }
  .swap-bottle-dialog-body .swap-dialog-inner {
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center; }
    .swap-bottle-dialog-body .swap-dialog-inner .mainContentPD .b-carousel-track {
      margin: 0 60px; }
    .swap-bottle-dialog-body .swap-dialog-inner .mainContentPD .b-product_tile-actions {
      margin-top: auto;
      width: 100%; }
    .swap-bottle-dialog-body .swap-dialog-inner .mainContentPD .b-carousel-control.m-previous {
      left: 25px;
      position: absolute;
      top: 35%; }
    .swap-bottle-dialog-body .swap-dialog-inner .mainContentPD .b-carousel-control.m-next {
      position: absolute;
      right: 25px;
      top: 35%; }
    .swap-bottle-dialog-body .swap-dialog-inner .mainContentPD .b-product_tile-actions.m-restriction {
      display: none; }
    .swap-bottle-dialog-body .swap-dialog-inner .b-product_tile.m-grid.m-page_designer.m-static {
      display: flex;
      flex-direction: column; }

.swap-dialog-inner {
  padding-bottom: 30px; }
  .swap-dialog-inner .b-carousel-title {
    font-family: "Proxima Nova", sans-serif !important; }
  .swap-dialog-inner .b-product_badge.m-rating_points,
  .swap-dialog-inner .b-product_badge.m-promo,
  .swap-dialog-inner .b-product_badge-image {
    display: none !important; }

.swap-product-tile.has-remove-button {
  min-height: 400px; }
  @media screen and (max-width: 767px) {
    .swap-product-tile.has-remove-button {
      min-height: 380px; } }
  .swap-product-tile.has-remove-button .wcproductQuantity-bottom .wcproduct-remove::before {
    display: inline-block;
    height: 10px;
    mask: url("svg-icons/close.svg");
    width: 11px; }

.edit-shipment-method-modal {
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 50%; }
  @media screen and (max-width: 1199px) {
    .edit-shipment-method-modal {
      margin: auto; } }
  @media screen and (max-width: 767px) {
    .edit-shipment-method-modal {
      margin: 65px 20px 18px;
      width: initial; } }

.select-shipment-method {
  color: #0c0c0c;
  width: 81%; }

.b-track_notification-title {
  font: 400 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px;
  text-transform: capitalize; }

.b-track_notification-inner {
  background-color: rgba(196, 194, 194, 0.1);
  border: 2px solid #C4C2C2;
  font: 400 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px; }

.b-track_notification-link {
  color: #584C44;
  font: 400 16px/24px "Proxima Nova", sans-serif;
  letter-spacing: 0.5px;
  text-transform: capitalize; }

.process-order-section {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  width: 100%; }
  .process-order-section .process-order-button {
    font-weight: 400; }

.your-shipment .shipment-card .shipment-type {
  background: #C9B794;
  font-family: "Proxima Nova", sans-serif;
  color: #202025; }
  .your-shipment .shipment-card .shipment-type.confirm {
    background-color: #f9f8f8;
    color: #737171; }

.b-tab_list-tab.m-active {
  border-bottom: 3px solid #584C44; }

.shipment-details .saveShipment-cta {
  background-color: #BA8D36;
  color: #202025; }

.addwcmembership-cta {
  letter-spacing: 0.9px; }

@media screen and (min-width: 768px) {
  .b-account_card-spaceaddress {
    width: 33%; } }

.b-user_greeting-message {
  font-size: 36px;
  letter-spacing: 0.8px;
  line-height: 48px; }

.b-user_greeting-signout {
  color: #584C44;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px; }

.b-account_nav-item {
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0.6px !important;
  line-height: 24px !important;
  color: #584C44 !important;
  text-transform: capitalize; }

.b-customer_service_info.m-small .b-customer_service_info-title {
  color: #584C44;
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 32px; }
